import DatabasesList from 'components/FetchData/DatabasesList'
import FilterModal from 'components/FetchData/Modals/FilterModal/FilterModal'
import QueryModal from 'components/FetchData/Modals/QueryModal'
import Button from 'components/Portions/Button'
import Loading from 'components/Portions/Loading'
import { addDataToMap, removeDataset, resetMapConfig, toggleSplitMap, wrapTo } from 'kepler.gl/actions'
import { toggleMapControl } from 'kepler.gl/dist/actions/ui-state-actions'
import { setMapInfo } from 'kepler.gl/dist/actions/vis-state-actions'
import { processCsvData, processGeojson, processRowObject } from 'kepler.gl/processors'
import KeplerGlSchema from 'kepler.gl/schemas'
import { Component } from 'react'
import { connect } from 'react-redux'
import { databaseList } from 'services/database'
import { datasetDownload, datasetDynamic, datasetFetch, datasetFunction, datasetQuery } from 'services/dataset'
import { sessionRetrive } from 'services/session'
import { setErrorHandler } from 'store/actions/errorsActions'
import {
  addDataset,
  addFunction,
  clearDataset,
  clearSession,
  controlLoading,
  controlSideBar,
  deleteAllFunction,
  setDatabses,
  setResetMap,
  // eslint-disable-next-line prettier/prettier
  setSession,
} from 'store/actions/viewActions'
// eslint-disable-next-line react/prefer-stateless-function
class FetchData extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDatabase: null,
      selectedTable: null,
      queryModal: false,
      filterModal: false,
      loading: false,
      // modalLoading: false,
      items: [],
      code: null,
      filters: [],
      filterConjunction: 'AND',
      jsonTree: {},

      currentInstance: this,
    }
  }

  componentDidMount() {
    if (!this.props.code) {
      this.props.controlLoading(false)
    }
    this.props.setResetMap(this.resetMap)
    this.getItems()
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.databases) !== JSON.stringify(state.items)) {
      return { items: props.databases }
    }
    if (JSON.stringify(props.code) !== JSON.stringify(state.code)) {
      state.currentInstance.getSession(props.code)
      return { code: props.code }
    }
    return false
  }

  getSession = (code) => {
    sessionRetrive(code)
      .then((respond) => {
        Object.keys(respond.data?.config?.mapControls).forEach((x) => {
          if (respond.data?.config?.mapControls[x].active) {
            this.props.toggleMapControl(x)
          }
        })

        if (respond.meta.sameOrganization) {
          this.getItems()
          this.props.controlSideBar(true)
        }

        this.props.setSession(respond.data)
        this.props.setMapInfo({ title: respond.data.title, description: respond.data.description })
        respond.data.datasets.forEach((dataset) => {
          if (dataset.type === 'dynamic') {
            this.setDynamic({ ...dataset.request, uuid: dataset.uuid, id: dataset.id }, false, true)
          } else if (dataset.type === 'query') {
            this.runQuery({ ...dataset.request, uuid: dataset.uuid, id: dataset.id }, false, true)
          } else if (dataset.type === 'function') {
            this.runFunction(
              { ...dataset.request, query: dataset.query, uuid: dataset.uuid, id: dataset.id },
              dataset.respond,
              false,
              true,
            )
          } else {
            this.getFile({ ...dataset.file, uuid: dataset.uuid, id: dataset.id }, false, true)
          }
        })
      })
      .catch((error) => {
        this.props.setErrorHandler(error.response)
      })
  }

  // eslint-disable-next-line consistent-return
  getItems = (loading = true) => {
    if (this.props.publicUser) {
      return false
    }
    this.setState({ loading }, () => {
      databaseList()
        .then((respond) => {
          this.setState(
            {
              loading: false,
              filters: [],
              filterConjunction: 'AND',
              jsonTree: {},
            },
            () => {
              this.props.setDatabses(respond.data)
            },
          )
        })
        .catch((error) => {
          this.props.setErrorHandler(error.response)
        })
    })
  }

  selectTable = (database, table) => {
    this.setState({
      selectedDatabase: database,
      selectedTable: table,
      filters: [],
      filterConjunction: 'AND',
      jsonTree: {},
    })
  }

  toggleQueryModal = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      queryModal: !this.state.queryModal,
    })
  }

  toggleFilterModal = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      filterModal: !this.state.filterModal,
    })
  }

  // for static files
  getFile = (request, draw, init) => {
    datasetDownload(request.url)
      .then(async (data) => {
        const dataset = {
          file: request,
          request: {},
          type: 'static',
          save: 'static',
          respond: { queryType: 'static', pages: 1, page: 1, uuid: request.uuid, id: request.id, dataType: 'ROW' },
          raw: data,
          info: {
            sid: request.id,
            id: request.uuid,
            label: request.label,
          },
          loading: false,
        }
        await this.props.addDataset({ [request.uuid]: dataset })
        this.drawData(request, dataset.respond, draw, init)
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        this.props.setErrorHandler(error.response)
      })
  }

  // for dynamic and user query
  fetchData = (request, respond, draw, init) => {
    if (respond.page <= respond.pages) {
      datasetFetch(respond)
        .then(async (data) => {
          // eslint-disable-next-line no-nested-ternary
          let raw = respond.page > 1 ? this.props.datasets[respond.uuid].raw : respond.dataType === 'CSV' ? '' : []

          // eslint-disable-next-line no-nested-ternary
          raw = respond.dataType === 'CSV' ? (raw ? [raw, data].join(' /n ') : data) : raw.concat(data)

          const dataset = {
            request,
            type: respond.queryType,
            save: respond.queryType === 'static' ? 'static' : 'dynamic',
            respond: { ...respond, page: respond.page + 1 },
            raw,
            info: {
              sid: respond.id,
              id: respond.uuid,
              label: respond.label,
            },
            loading: respond.page !== respond.pages,
          }
          await this.props.addDataset({ [respond.uuid]: dataset })

          this.fetchData(request, this.props.datasets[respond.uuid].respond, draw, init)
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          this.props.setErrorHandler(error.response)
        })
    } else {
      this.drawData(request, respond, draw, init)
    }
  }

  processData = (data, type) => {
    switch (type) {
      case 'GEOJSON':
        return processGeojson({
          type: 'FeatureCollection',
          features: data,
        })
      case 'ROW':
        return processRowObject(data)
      case 'CSV':
        return processCsvData(data)
      default:
        break
    }
    return data
  }

  fixLngValue = (value) => {
    if (value > 180) {
      return 180
    }
    if (value < -180) {
      return -180
    }
    return value
  }

  getBbox = () => {
    const mapGetBounds = this.props.mapRef.getMap().getBounds()

    const mapBounds = [
      {
        min_lat: parseFloat(mapGetBounds.getSouthWest().lat.toFixed(6)),
        max_lat: parseFloat(mapGetBounds.getNorthEast().lat.toFixed(6)),
        min_lon: this.fixLngValue(parseFloat(mapGetBounds.getSouthWest().lng.toFixed(6))),
        max_lon: this.fixLngValue(parseFloat(mapGetBounds.getNorthEast().lng.toFixed(6))),
      },
    ]
    return mapBounds
  }

  createDatasetObject = (savedDataset, dataType, multi = false) => {
    const processData = this.processData(savedDataset.raw, dataType)
    return {
      version: 'v1',
      info: savedDataset.info,
      data: multi
        ? {
            allData: processData.rows,
            fields: processData.fields,
            id: savedDataset.info.id,
            label: savedDataset.info.label,
          }
        : { id: savedDataset.info.id, label: savedDataset.info.label, ...processData },
    }
  }

  drawData = async (_request, respond, draw, init) => {
    const { datasets } = this.props

    if (draw) {
      const dataObject = {
        datasets: [this.createDatasetObject(datasets[respond.uuid], respond.dataType)],
        options: {
          centerMap: false,
          keepExistingConfig: !init,
        },
      }
      this.props.sendDataToMap(dataObject)
    } else if (Object.keys(this.props.datasets).length === this.props.session?.datasets.length) {
      const dataObjects = Object.keys(this.props.datasets).map((key) => {
        return this.createDatasetObject(this.props.datasets[key], this.props.datasets[key].respond.dataType, true)
      })

      const schemaData = KeplerGlSchema.load(dataObjects, this.props.session.config)

      this.props.sendDataToMap({
        ...schemaData,
        options: {
          centerMap: false,
          keepExistingConfig: false,
        },
      })
      if (init) {
        this.props.controlLoading(false)
      }
    }

    this.setState({ modalLoading: false })
  }

  setDynamic = (request, draw, init) => {
    this.runDynamic(request, draw, init)
  }

  getDynamic = () => {
    this.setState({ modalLoading: true }, () => {
      const request = {
        database: this.state.selectedDatabase.uuid,
        table: this.state.selectedTable.uuid,
        bboxs: this.getBbox(),
        filters: this.state.filters,
        filterConjunction: this.state.filterConjunction,
      }
      this.runDynamic(request, true, false)
    })
  }

  runDynamic = (request, draw, init) => {
    datasetDynamic(request)
      .then((respond) => {
        if (!respond.data.fullData) {
          this.props.setErrorHandler({
            status: 400,
            data: {
              errors: {
                messageType: 'WARN',
                message: 'The request you made is very large  , the system returned sample of the data',
              },
            },
          })
        }
        if (respond.data.files) {
          this.fetchFilesData(request, respond.data, draw, init)
        } else {
          this.fetchData(request, respond.data, draw, init)
        }
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        this.props.setErrorHandler(error.response)
      })
  }

  // for dynamic and user query
  fetchFilesData = (request, respond, draw, init) => {
    console.log({ request, respond })
    if (respond.page <= respond.pages) {
      datasetDownload(respond.files[respond.page - 1])
        .then(async (data) => {
          // eslint-disable-next-line no-nested-ternary
          let raw = respond.page > 1 ? this.props.datasets[respond.uuid].raw : respond.dataType === 'CSV' ? '' : []

          // eslint-disable-next-line no-nested-ternary
          raw = respond.dataType === 'CSV' ? (raw ? [raw, data].join(' /n ') : data) : raw.concat(data)

          const dataset = {
            request,
            type: respond.queryType,
            save: respond.queryType === 'static' ? 'static' : 'dynamic',
            respond: { ...respond, page: respond.page + 1 },
            raw,
            info: {
              sid: respond.id,
              id: respond.uuid,
              label: respond.label,
            },
            loading: respond.page !== respond.pages,
          }
          await this.props.addDataset({ [respond.uuid]: dataset })

          this.fetchFilesData(request, this.props.datasets[respond.uuid].respond, draw, init)
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          this.props.setErrorHandler(error.response)
        })
    } else {
      this.drawData(request, respond, draw, init)
    }
  }

  setQuery = (request, draw, init) => {
    this.runQrunQuery(request, draw, init)
  }

  runQuery = (request, draw, init) => {
    datasetQuery(request)
      .then((respond) => {
        this.setState({ queryModal: false }, () => {
          if (!respond.data.fullData) {
            this.props.setErrorHandler({
              status: 400,
              data: {
                errors: {
                  messageType: 'WARN',
                  message: 'The request you made is very large  , the system returned sample of the data',
                },
              },
            })
          }

          if (respond.data.files) {
            this.fetchFilesData(request, respond.data, draw, init)
          } else {
            this.fetchData(request, respond.data, draw, init)
          }
        })
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        this.props.setErrorHandler(error.response)
      })
  }

  runFunction = (request, respond, draw, init) => {
    this.props.addFunction({
      [request.uuid]: {
        id: request.id,
        uuid: request.uuid,
        respond,
        request,
        selectedDatasetError: null,
        selectedFunctionError: null,
        selectedColumnError: null,
      },
    })

    datasetFunction(request.function.action, { id: request.id, uuid: request.uuid, request: { ...request } })
      .then(async (data) => {
        const dataset = {
          request,
          type: 'function',
          save: 'dynamic',
          raw: data,
          respond: { dataType: 'CSV', ...respond },
          info: {
            sid: request.id || null,
            id: request.uuid,
            label: request.dataset
              ? `${request.dataset.label}-${request.function.action}-${this.stringGen()}`
              : `${request.function.action}-${this.stringGen()}`,
          },
          loading: false,
        }
        await this.props.addDataset({ [request.uuid]: dataset })
        this.drawData(request, { dataType: 'CSV', ...respond }, draw, init)
      })
      .catch((respondDrror) => {
        console.log(respondDrror)
        this.props.setErrorHandler(respondDrror.response)
      })
  }

  stringGen = (length = 6) => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
    return text
  }

  getQuery = (query) => {
    this.toggleQueryModal()
    if (query) {
      this.setState({ modalLoading: true }, () => {
        const request = {
          query,
          bboxs: this.getBbox(),
          database: this.state.selectedDatabase.uuid,
          table: this.state.selectedTable.uuid,
        }
        this.runQuery(request, true, false)
      })
    }
  }

  saveFilters = (jsonTree, filters, filterConjunction) => {
    this.setState({ jsonTree, filters, filterConjunction, filterModal: false })
  }

  resetMap = () => {
    this.props.resetMapConfig()
    this.props.toggleSplitMap(0)
    this.props.toggleSplitMap(1)
    this.props.deleteAllFunction()
    // this.props.togglePerspective(0)
    const datasets = KeplerGlSchema.getDatasetToSave(this.props.keplerInstance)
    datasets.forEach((dataset) => {
      this.props.removeDataset(dataset.data.id)
    })
    this.props.setMapInfo({ title: '', description: '' })
    this.props.clearDataset()
    this.props.clearSession()
    Object.keys(this.props.mapControls).forEach((x) => {
      if (this.props.mapControls[x].active) {
        this.props.toggleMapControl(x)
      }
    })
  }

  render() {
    return (
      <div className={` ${this.props.activeSidePanel !== 'layer' && 'hidden'} pl-4 pr-6 absolute bottom-0 w-full`}>
        {this.state.queryModal && (
          <QueryModal getQuery={this.getQuery} cancel={{ text: 'Cancel', handler: this.toggleQueryModal }} />
        )}
        {this.state.filterModal && (
          <FilterModal
            filters={this.state.filters}
            jsonTree={this.state.jsonTree}
            columns={this.state.selectedTable.columns}
            action={{
              handler: this.saveFilters,
              text: 'Fetch Data',
              className: 'rounded-sm databaseButton',
            }}
            cancel={{ text: 'Cancel', handler: this.toggleFilterModal }}
          />
        )}
        <Loading value={this.state.loading} className='text-white'>
          <DatabasesList
            selectTable={this.selectTable}
            selectedDatabase={this.state.selectedDatabase}
            selectedTable={this.state.selectedTable}
            items={this.state.items}
          />
          <div className='flex justify-start items-center w-full  max-w-full mb-3'>
            <Button
              disable={this.state.selectedTable === null}
              loading={this.state.modalLoading}
              loadingText='Fetching...'
              action={this.getDynamic}
              color='ssd'
              single
              className='gap-2 rounded-sm  bg-primarycolor hover:bg-hovercolor  '
            >
              <svg
                width='16'
                height='16'
                viewBox='0 0 24 24'
                strokeWidth='2'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4' />
                <line x1='12' y1='13' x2='12' y2='22' />
                <polyline points='9 19 12 22 15 19' />
              </svg>
              <p>Fetch Data</p>
            </Button>
            <div className='flex items-center gap-1 ml-auto'>
              {' '}
              <Button
                disable={this.state.selectedTable === null}
                single
                color='gray'
                width='w-12'
                className='databaseButton'
                action={this.toggleFilterModal}
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5' />
                </svg>
              </Button>
              <Button
                disable={this.state.selectedTable === null}
                single
                color='gray'
                width='w-12'
                className='databaseButton'
                action={this.toggleQueryModal}
              >
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <rect x='3' y='4' width='18' height='8' rx='3' />
                  <rect x='3' y='12' width='18' height='8' rx='3' />
                  <line x1='7' y1='8' x2='7' y2='8.01' />
                  <line x1='7' y1='16' x2='7' y2='16.01' />
                </svg>
              </Button>
            </div>
          </div>
        </Loading>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  mapRef: state.app.view.mapRef,
  keplerInstance: state.keplerGl?.MapLab,
  code: state.app.view.code,
  session: state.app.view.session,
  databases: state.app.view.databases,
  datasets: state.app.view.datasets,
  mapControls: state.keplerGl?.MapLab.uiState.mapControls,
  activeSidePanel: state.keplerGl?.MapLab.uiState.activeSidePanel,
  publicUser: state.app.user.publicUser,
})

const mapDispatchToProps = (dispatch) => ({
  toggleSplitMap: (data) => dispatch(wrapTo('MapLab', toggleSplitMap(data))),
  resetMapConfig: () => dispatch(wrapTo('MapLab', resetMapConfig())),
  removeDataset: (data) => dispatch(wrapTo('MapLab', removeDataset(data))),
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  sendDataToMap: (data) => dispatch(wrapTo('MapLab', addDataToMap(data))),
  addDataset: (data) => {
    dispatch(addDataset(data))
    return Promise.resolve()
  },
  addFunction: (data) => dispatch(addFunction(data)),
  deleteAllFunction: () => dispatch(deleteAllFunction()),
  toggleMapControl: (data) => dispatch(toggleMapControl(data)),
  setResetMap: (data) => dispatch(setResetMap(data)),
  setDatabses: (data) => dispatch(setDatabses(data)),
  setSession: (data) => dispatch(setSession(data)),
  setMapInfo: (data) => dispatch(setMapInfo(data)),
  controlSideBar: (data) => dispatch(controlSideBar(data)),
  controlLoading: (data) => dispatch(controlLoading(data)),
  clearDataset: () => dispatch(clearDataset()),
  clearSession: () => dispatch(clearSession()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FetchData)
