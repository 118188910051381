export function setErrorHandler(error) {
  return {
    type: 'ERROR_HANDLER',
    payload: error,
  }
}

export function showMessage(message) {
  return {
    type: 'MESSAGE_HANDLER',
    payload: message,
  }
}
