export const SETTINGS = {
  DROPBOX_CLIENT_ID: '',
  DROPBOX_CLIENT_NAME: '',
  REPORT_BUG: 'https://form.jotform.com/213223240549549',

  APP_URL: process.env.REACT_APP_APP_URL,
  BASE_URL: process.env.REACT_APP_BASE_URL,

  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  PUBLIC_USER_TOKEN: process.env.REACT_APP_PUBLIC_USER_TOKEN,
}
