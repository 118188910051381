/* eslint-disable no-useless-escape */
const passwordValidation = (value) => {
  const hasUpperCase = /[A-Z]/.test(value)
  const hasLowerCase = /[a-z]/.test(value)
  const hasNumber = /[0-9]/.test(value)
  const hasSymbole = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)
  const length = value?.length > 7
  const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole, length]
  const validConditions = conditions.reduce((acc, condition) => (condition ? ++acc : acc), 0)
  return {
    pass: validConditions === conditions.length,
    lowerCase: hasLowerCase,
    upperCase: hasUpperCase,
    number: hasNumber,
    symbole: hasSymbole,
    length,
  }
}

export default passwordValidation
