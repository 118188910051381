/* eslint-disable react/jsx-one-expression-per-line */
import DeleteSessionModal from 'components/Management/Sessions/Modals/DeleteSessionModal'
import ShareUrlModal from 'components/Management/Sessions/Modals/shareUrlModal'
import SessionItem from 'components/Management/Sessions/SessionItem'
import Button from 'components/Portions/Button'
import CheckBox from 'components/Portions/CheckBox'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import Label from 'components/Portions/Label'
import Loading from 'components/Portions/Loading'
import Pagination from 'components/Portions/Pagination'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import ReactTooltip from 'react-tooltip'
import { sessionDelete, sessionImage, sessionRecords } from 'services/session'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'

// eslint-disable-next-line react/prefer-stateless-function
class ManageSessions extends Component {
  constructor() {
    super()
    this.state = {
      items: [],
      item: {},
      errors: {},
      selected: null,
      loading: false,
      deleteSessionModal: false,
      shareUrlModal: false,
      refreshSessionModal: false,
      modalLoading: true,
      mySearch: false,
      search: '',
      page: 1,
      pages: 1,
      total: 0,
      count: 30,
      orderQuery: { type: 'created_at', order: 'desc' },
    }
  }

  componentDidMount() {
    this.getItems()
  }

  getImage = (uuid) => {
    sessionImage(uuid)
      .then((respond) => {
        const { items } = this.state
        items.map((x) => {
          if (x.uuid === uuid) {
            x.image = respond.data.image
            x.imageLoading = false
          }
          return x
        })
        this.setState({ items })
      })
      .catch((error) => {
        const { items } = this.state
        items.map((x) => {
          if (x.uuid === uuid) {
            x.image = null
            x.imageLoading = false
          }
          return x
        })
        this.setState({ items })
      })
  }

  deleteSession = () => {
    this.setState({ modalLoading: true }, () => {
      sessionDelete(this.state.selected.uuid)
        .then((respond) => {
          this.setState({ deleteSessionModal: false })
          this.getItems()
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: `Map deleted successfully!`,
          })
        })
        .catch((error) => {
          this.props.setErrorHandler(error.response)
        })
    })
  }

  addOrder = (type) => {
    let { orderQuery } = this.state
    if (orderQuery.type === type) {
      orderQuery.order = orderQuery.order === 'desc' ? 'asc' : 'desc'
    } else {
      orderQuery = { type, order: 'desc' }
    }
    // save order to state
    this.setState(
      {
        orderQuery,
      },
      // update the table data
      this.getItems,
    )
  }

  // create order icon next to the selected col
  renderOrder = (type) => {
    if (type !== this.state.orderQuery.type) {
      return (
        <svg
          alt='order'
          onClick={() => this.addOrder(type)}
          className='w-3 h-3 orderLocation ml-1 cursor-pointer float-right order'
          viewBox='0 0 1792 1792'
        >
          <path
            fill='#a0aec0'
            d='M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z'
          />
        </svg>
      )
    }
    switch (this.state.orderQuery.order) {
      case 'desc':
        return (
          <svg
            alt='order'
            onClick={() => this.addOrder(type)}
            className='w-3 h-3 orderLocation ml-1 cursor-pointer float-right order'
            viewBox='0 0 1792 1792'
          >
            <path d='M1408 704q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z' />
          </svg>
        )
      case 'asc':
        return (
          <svg
            alt='order'
            onClick={() => this.addOrder(type)}
            className='w-3 h-3 orderLocation ml-1 cursor-pointer float-right order'
            viewBox='0 0 1792 1792'
          >
            <path d='M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z' />
          </svg>
        )
      default:
        return (
          <svg
            alt='order'
            onClick={() => this.addOrder(type)}
            className='w-3 h-3 orderLocation ml-1 cursor-pointer float-right order'
            viewBox='0 0 1792 1792'
          >
            <path
              fill='#a0aec0'
              d='M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z'
            />
          </svg>
        )
    }
  }

  getItems = (loading = true) => {
    this.setState({ loading }, () => {
      sessionRecords({
        count: this.state.count,
        page: this.state.page,
        order: this.state.orderQuery, // send order value
        search: this.state.search,
        user: this.state.mySearch,
      })
        .then((respond) => {
          this.setState({
            loading: false,
            items: respond.data.map((x) => {
              this.getImage(x.uuid)
              return { ...x, imageLoading: true }
            }),
            total: respond.meta.total,
            pages: Math.ceil(respond.meta.total / respond.meta.count),
            count: respond.meta.count,
          })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })

          this.props.setErrorHandler(error.response)
        })
    })
  }

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value })
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value

    errors[e.target.name] = null

    this.setState({ item, errors })
  }

  toggleModal = (modal, selected = null) => {
    const modalState = this.state[modal]
    // need to close modal
    if (!selected) {
      this.setState({ item: {}, errors: {}, selected: null, [modal]: !modalState })
      return
    }

    // need to open modal
    if (selected.data) {
      // open model with data
      // to do later
    } else {
      // open model without data
      this.setState({ modalLoading: false, item: {}, errors: {}, selected, [modal]: !modalState })
    }
  }

  selectMySearch = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ mySearch: !this.state.mySearch }, () => {
      this.changepage(1)
    })
  }

  changepage = (page) => {
    // save page to state
    this.setState({ page }, () => {
      this.getItems()
    })
    // save page to context
  }

  render() {
    return (
      <ModalContainer className='w-largeModal' close={this.props.close} title='Saved Maps'>
        <ReactTooltip effect='solid' data-place='right' id='largeImage' />
        {this.state.deleteSessionModal && (
          <DeleteSessionModal
            toggleModal={this.toggleModal}
            action={{ color: 'red', text: 'Yes, Delete', handler: this.deleteSession }}
            cancel={{ text: 'No, Keep it', handler: () => this.toggleModal('deleteSessionModal') }}
          />
        )}
        {this.state.shareUrlModal && (
          <ShareUrlModal
            public={this.state.selected.item.public}
            code={this.state.selected.item.code}
            loading={this.state.modalLoading}
            cancel={{ text: 'Close', handler: () => this.toggleModal('shareUrlModal') }}
          />
        )}

        <div className='my-4 flex justify-between items-center text-sm'>
          <p>
            Choose one of the Available Maps and load the saved configs and the saved datasets from MapLab.world's
            session.
          </p>
        </div>
        <div className='mb-5'>
          <FormGroup horizontal>
            <CheckBox name='mySearch' onChange={this.selectMySearch} checked={this.state.mySearch} />
            <Label htmlFor='mySearch'>Show only my saved maps</Label>
          </FormGroup>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginBlock: '10px' }}>
          <Input
            name='search'
            id='search'
            value={this.state.search}
            onChange={this.handleSearchChange}
            placeholder='Search in maps'
          />
          <Button single action={this.getItems}>
            Search
          </Button>
        </div>
        {/* accordiion item header */}

        <div className=' primary-border-t  primary-border-r  primary-border-l  rounded-sm overflow-hidden '>
          <div className='primary-border-b rounded-t-md'>
            <div className='secondary-background primary-border-b grid grid-cols-12   '>
              <div className='item-title border-r-0 col-span-1 flex p-3 justify-start items-start'>
                <p>Image</p>
              </div>
              <div className='item-options col-span-3 flex justify-start items-center'>
                <p>Title </p> {this.renderOrder('title')}
              </div>
              <div className='item-options col-span-2 flex justify-start items-center '>
                <p>Created </p> {this.renderOrder('created_at')}
              </div>
              <div className='item-options  col-span-2 flex justify-start items-center pl-4'>
                <p>User </p> {this.renderOrder('user_id')}
              </div>
              <div className='item-options col-span-1 flex justify-start items-center '>
                <p>Datasets</p>
              </div>
              <div className='item-options col-span-1 flex justify-start items-center pl-4'>
                <p>Public </p> {this.renderOrder('public')}
              </div>
              <div className='item-options col-span-2 flex justify-center items-center'>
                <p>Actions</p>
              </div>
            </div>
            <Loading value={this.state.loading}>
              <ScrollArea style={{ maxHeight: 350, height: 300 }}>
                {this.state.items.length > 0 ? (
                  this.state.items.map((item, index) => {
                    return (
                      <SessionItem
                        handelTableStatusChange={this.handelTableStatusChange}
                        key={item.id}
                        item={{ ...item }}
                        toggleModal={this.toggleModal}
                        close={this.props.close}
                      />
                    )
                  })
                ) : (
                  <div className='flex justify-center items-center' style={{ height: 300 }}>
                    <p>No data available</p>
                  </div>
                )}
              </ScrollArea>
            </Loading>
          </div>
        </div>

        <Pagination
          page={this.state.page}
          total={this.state.total}
          pages={this.state.pages}
          count={this.state.count}
          changepage={this.changepage}
        />
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  showMessage: (data) => dispatch(showMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageSessions)
