import ToolBar from 'components/Layout/ToolBar'
import { galleryOptions } from 'components/Layout/toolbar-options'
import ModalManger from 'components/Modals/ModalManger'
import { theme } from 'kepler.gl/dist/styles/base'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setUser } from 'store/actions/userActions'
import { controlLoading } from 'store/actions/viewActions'
import styled, { ThemeProvider } from 'styled-components'

const ToolBarWrapper = styled.div`
  z-index: 99;
  height: 100%;
  display: flex;
  flex: 0 1 auto;
  width: 48px;
  pointer-events: none; /* prevent padding from blocking input */
  & > * {
    /* all children should allow input */
    pointer-events: all;
  }
`
const LayoutWrapper = styled.div`
  display: flex;
  position: relative;
  background: #f7f7f7;
  height: 100vh;
  font-family: ff-clan-web-pro, 'Helvetica Neue', Helvetica, sans-serif;
`
const GalleryWrapper = styled.div`
  flex: 1 1 auto;
  width: calc(100% - 48px);
`

const Layout = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(controlLoading(false))
    dispatch(setUser(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}))
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <ModalManger />
      <LayoutWrapper>
        <ToolBarWrapper className='side-panel--container p-0'>
          <ToolBar options={galleryOptions} />
        </ToolBarWrapper>
        <GalleryWrapper>{children}</GalleryWrapper>
      </LayoutWrapper>
    </ThemeProvider>
  )
}
export default Layout
