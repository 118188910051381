/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/static-property-placement */
import ToolBar from 'components/Layout/ToolBar'
import { mapOptions } from 'components/Layout/toolbar-options'
import { SidebarFactory } from 'kepler.gl/components'
import { CollapseButtonFactory } from 'kepler.gl/dist/components/side-panel/side-bar'
import { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// const AsyncFetchData = loadable(() => import('components/FetchData'))

const StyledSidePanelContainer = styled.div`
  z-index: 1000;
  height: 100%;
  width: ${(props) => props.width + 2 * props.theme.sidePanel.margin.left}px;
  display: flex;
  transition: width 250ms;
  position: absolute;

  pointer-events: none; /* prevent padding from blocking input */
  & > * {
    /* all children should allow input */
    pointer-events: all;
  }
`

const SideBarContainer = styled.div`
  transition: left 250ms, right 250ms;
  left: ${(props) => props.left}px;
  align-items: stretch;

  flex-grow: 1;
`

const SideBarInner = styled.div`
  background-color: ${(props) => props.theme.sidePanelBg};
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  position: relative;
  height: 100%;
  border-left: ${(props) => props.theme.sidePanelBorder}px solid ${(props) => props.theme.sidePanelBorderColor};
`

export function CustomSidebarFactory(CollapseButton) {
  class SideBar extends Component {
    static defaultProps = {
      width: 300,
      minifiedWidth: 0,
      onOpenOrClose: function noop() {},
    }

    constructor() {
      super()
      this.state = {
        // eslint-disable-next-line react/no-unused-state
        isFunction: false,
        isOpen: true,
      }
    }

    onOpenOrClose = () => {
      this.setState({ isOpen: !this.state.isOpen }, () => {
        if (this.state.isFunction) {
          this.setState({ isFunction: false })
        }
      })
    }

    onOpenOrCloseFunction = () => {
      this.setState({ isFunction: !this.state.isFunction })
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { minifiedWidth, width } = this.props
      const { isOpen } = this.state
      const horizontalOffset = isOpen ? 0 : minifiedWidth - width

      return (
        <StyledSidePanelContainer
          width={isOpen ? width : 0}
          className={`${this.props.publicUser && 'hiddenImportant'}  side-panel--container p-0 ml-12  }`}
        >
          <SideBarContainer className='side-bar w-side' left={horizontalOffset}>
            <SideBarInner
              style={{
                paddingBottom: this.props.activeSidePanel === 'layer' ? 100 : 0,
                display: !isOpen ? 'none' : 'flex',
              }}
              className='w-side side-bar__inner h-screen'
            >
              {this.props.children}
            </SideBarInner>
          </SideBarContainer>

          <ToolBar options={mapOptions} isOpen={isOpen} onOpenOrClose={this.onOpenOrClose} />
        </StyledSidePanelContainer>
      )
    }
  }
  const mapStateToProps = (state) => ({
    activeSidePanel: state.keplerGl?.MapLab.uiState.activeSidePanel,
    sideBar: state.app.view.sideBar,
    publicUser: state.app.user.publicUser,
    toggleModal: state.app.view.toggleModal,
  })
  const mapDispatchToProps = (dispatch) => ({})

  return connect(mapStateToProps, mapDispatchToProps)(SideBar)
  // return React.memo(SideBar)
}

CustomSidebarFactory.deps = [CollapseButtonFactory]

export const replaceSidebar = () => {
  return [SidebarFactory, CustomSidebarFactory]
}
