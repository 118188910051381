/* eslint-disable no-underscore-dangle */
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { point } from '@turf/helpers'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

const restoreDatasets = (state, action) => ({
  ...state,
  MapLab: {
    ...state.MapLab,
    visState: {
      ...state.MapLab.visState,
      datasets: {
        ...state.MapLab.visState.datasets,
        ...state.MapLab.visState.hiddenDatasets,
      },
      hiddenDatasets: {},
    },
  },
})

const filterByPolygon = (state, action) => {
  const { MapLab } = state // eslint-disable-line
  const { datasets } = MapLab.visState
  const newDatasets = {}
  // if no dataset is loaded or no polygon is selected, reset the filteredByPolygon to null
  if (datasets && Object.keys(datasets).length === 0 && datasets.constructor === Object) return state

  // get the first dataset
  const datasetIds = Object.keys(datasets)
  datasetIds.forEach((datasetId) => {
    const dataset = datasets[datasetId]
    const polygonFilter = action.polygon
    const otherFilters = MapLab.visState.filters.filter((f) => f.type !== 'polygon' && f.dataId.indexOf(datasetId) > -1)
    if (polygonFilter === null) return state

    const pair = dataset.fieldPairs[0]
    if (!pair) return state
    const lonIndex = pair.pair.lng.fieldIdx
    const latIndex = pair.pair.lat.fieldIdx
    let result = dataset.dataContainer._rows.filter(
      (row) =>
        row[lonIndex] && row[latIndex] && booleanPointInPolygon(point([row[lonIndex], row[latIndex]]), polygonFilter),
    )
    otherFilters.forEach((filter) => {
      switch (filter.type) {
        case 'range':
          result = result.filter(
            (row) => filter.value[0] <= row[filter.fieldIdx[0]] && filter.value[1] >= row[filter.fieldIdx[0]],
          )
          break
        case 'timeRange':
          {
            const parser = typeof result[0][filter.fieldIdx[0]] === 'string' ? (x) => moment(x).unix() * 1000 : (x) => x
            result = result.filter(
              (row) =>
                filter.value[0] <= parser(row[filter.fieldIdx[0]]) &&
                filter.value[1] >= parser(row[filter.fieldIdx[0]]),
            )
          }
          break
        case 'multiSelect':
          result = result.filter((row) => filter.value.indexOf(row[filter.fieldIdx[0]]) > -1)
          break
        case 'select':
          result = result.filter((row) => filter.value === row[filter.fieldIdx[0]])
          break
        default:
          result = result.filter(
            (row) => filter.value[0] <= row[filter.fieldIdx[0]] && filter.value[1] >= row[filter.fieldIdx[0]],
          )
          break
      }
    })

    const indexes = [...Array(result.length).keys()]
    const datasetInnerId = `${dataset.id}`
    const datasetInnerLabel = `${dataset.label}`

    newDatasets[datasetInnerId] = Object.assign(cloneDeep(dataset), {
      ...dataset,
      id: datasetInnerId,
      label: datasetInnerLabel,
      metadata: {
        ...dataset.metadata,
        id: datasetInnerId,
        label: datasetInnerLabel,
      },
      dataContainer: Object.assign(cloneDeep(dataset.dataContainer), { ...dataset.dataContainer, _rows: result }),
      allIndexes: indexes,
      filteredIndex: indexes,
      filteredIndexForDomain: indexes,
      isFilteredByPolygon: true,
    })
    return 0
  })
  // To display the points inside the polygon as a kepler dataset spread operator was enough
  // but filtering was not functional, as it requires the function filterTable() which is not copied using the spread operator
  // So i needed to deepcopy the dataset to copy the functions.
  return {
    ...state,
    MapLab: {
      ...state.MapLab,
      visState: {
        ...state.MapLab.visState,
        datasets: {
          ...state.MapLab.visState.datasets,
          ...newDatasets,
        },
        hiddenDatasets: { ...datasets },
      },
    },
  }
}

function filterByPolygonReducer(state, action) {
  switch (action.type) {
    case 'FILTER_BY_POLYGON':
      return filterByPolygon(state, action)
    case '@@kepler.gl/TOGGLE_MODAL':
      return restoreDatasets(state, action)
    default:
      return state
  }
}

export default filterByPolygonReducer
