import SectionHeader from 'pages/Gallery/components/Section/SectionHeader'
import SectionSubHeader from 'pages/Gallery/components/Section/SectionSubHeader'
import { Link } from 'react-router-dom'
import { UserIcon } from 'components/images'
import { mainDarkestBlue } from 'theme/colors'

const RecentlyAdded = ({ map }) => {
  return (
    <div className='mt-8 mb-4'>
      <SectionHeader>Recently Added</SectionHeader>
      <SectionSubHeader>The last added map in the app</SectionSubHeader>

      <div className='rounded-lg relative m-0 shadow-lg flex bg-white'>
        <div className='c-card flex-1 rounded-tl-lg rounded-bl-lg overflow-hidden self-center'>
          {
            // eslint-disable-next-line no-nested-ternary
            map.imageLoading ? (
              <div className='flex w-big_42 h-96 block mx-auto my-auto'>
                <img src='/assets/images/loading.gif' alt='map' className='w-16 h-16 block mx-auto my-auto' />
              </div>
            ) : map.image !== null ? (
              <img
                data-tip={`<img src=${map.image} class="w-big_42 h-96 block mx-auto" />`}
                data-html
                data-class='p-2'
                data-for='largeImage'
                src={map.image}
                alt='map'
                className='w-big_42 h-96 block mx-auto'
              />
            ) : (
              <img className='w-big_42 h-96 block mx-auto' src='/assets/images/no-image.png' alt='map' />
            )
          }
        </div>
        <div className='flex-1 card-block relative'>
          <div className='p-4 md:p-6'>
            <small className='text-gray-600 italic'>Map Title</small>
            <h4 className='-mt-2 font-bold text-3xl mb-2 text-logoMapColor hover:text-gray-900'>
              <Link to={`/map/${map.code}`} target='_blank' rel='noopener noreferrer'>
                {map?.title}
              </Link>
            </h4>
            <span className='inline-block mr-2 text-xs text-gray-500'>Databases </span>
            <span
              className='shadow-md inline-block mr-2 mb-3 px-2 py-1 leading-none bg-gray-200 text-gray-500
             rounded-full font-normal lowercase tracking-wide text-xs'
            >
              {
                map?.datasets?.filter((dataset) => {
                  return dataset.type === 'static'
                }).length
              }
              {` Static`}
            </span>
            <span
              className='shadow-md inline-block mr-2 mb-3 px-2 py-1 leading-none bg-gray-200 text-gray-500
             rounded-full font-normal lowercase tracking-wide text-xs'
            >
              {
                map?.datasets?.filter((dataset) => {
                  return dataset.type !== 'static'
                }).length
              }
              {` Dynamic`}
            </span>
            <p className='text-gray-600 mt-4 md:mt-6 leading-normal h-map-description overflow-auto'>
              {!map.description ? <i> No available description...</i> : map.description}
            </p>

            <div className='flex mt-5 align-center'>
              <div className='flex-initial w-3/5'>
                <p className='text-base md:text-xl text-grey-600 font-medium block '>
                  <span className='flex items-center'>
                    <UserIcon color={mainDarkestBlue} />
                    <span className='ml-1 text-gray-600'>{`${map.user?.first_name} ${map.user?.last_name}`}</span>
                  </span>
                </p>
              </div>
              <span className='text-md text-gray-600 text-right flex-initial w-2/5 self-center'>
                {new Date(map.created_at).toDateString()}
              </span>
            </div>
          </div>
          <span
            className={`absolute top-4 right-4 inline-block px-2 py-1 leading-none border-1 ${
              map.public === 'true'
                ? 'bg-green-200 text-green-800 border-green-800'
                : 'bg-orange-200 text-orange-800 border-orange-800'
            } rounded-full font-semibold uppercase tracking-wide text-xs`}
          >
            {map.public === 'true' ? 'Public Map' : 'Private Map'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default RecentlyAdded
