import styled from 'styled-components'

const HeaderWrapper = styled.div`
  border-bottom: 2px solid rgba(41, 50, 60, 1);
  height: 50px;
`

const Header = () => (
  <HeaderWrapper className='bg-primarycolor'>
    <h3 className='p-2 text-white text-xl'>Maps Gallery</h3>
  </HeaderWrapper>
)

export default Header
