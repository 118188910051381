import DatabaseItem from 'components/Management/Database/DatabaseItem'
import ConnectivityErrorModal from 'components/Management/Database/Modals/ConnectivityErrorModal'
import CreateDatabaseModal from 'components/Management/Database/Modals/CreateDatabaseModal'
import DeleteDatabaseModal from 'components/Management/Database/Modals/DeleteDatabaseModal'
import DeleteTableModal from 'components/Management/Database/Modals/DeleteTableModal'
import RefrechDatabaseModal from 'components/Management/Database/Modals/RefrechDatabaseModal'
import RefrechTableModal from 'components/Management/Database/Modals/RefrechTableModal'
import UpdateDatabaseModal from 'components/Management/Database/Modals/UpdateDatabaseModal'
import UpdateTableModal from 'components/Management/Database/Modals/UpdateTableModal'
import Button from 'components/Portions/Button'
import Loading from 'components/Portions/Loading'
import Pagination from 'components/Portions/Pagination'
import Text from 'components/Portions/Text'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import {
  databaseCheck,
  databaseCreate,
  databaseDelete,
  databaseRecord,
  databaseRecords,
  databaseRefresh,
  // eslint-disable-next-line comma-dangle, prettier/prettier
  databaseUpdate,
} from 'services/database'
import { tableColumnUpdate, tableDelete, tableRecord, tablesRefresh, tableStatus } from 'services/tables'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'
import { setDatabses } from 'store/actions/viewActions'

// eslint-disable-next-line react/prefer-stateless-function
class ManageDatabases extends Component {
  constructor() {
    super()
    this.state = {
      items: [],
      item: {},
      errors: {},
      selected: null,
      loading: false,
      deleteDatabaseModal: false,
      refreshDatabaseModal: false,
      refreshTableModal: false,
      connectivityErrorModal: false,
      deleteTableModal: false,
      modalLoading: true,
      createDatabaseModal: false,
      updateDatabaseModal: false,
      updateTableModal: false,
      page: 1,
      pages: 1,
      total: 0,
      count: 30,
    }
  }

  componentDidMount() {
    this.getItems()
  }

  getItems = (loading = true) => {
    this.setState({ loading }, () => {
      databaseRecords({ page: this.state.page, count: this.state.count })
        .then((respond) => {
          this.props.setDatabses(respond.data)
          this.setState({
            loading: false,
            items: respond.data,
            total: respond.meta.total,
            pages: Math.ceil(respond.meta.total / respond.meta.count),
            count: respond.meta.count,
          })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })

          this.props.setErrorHandler(error.response)
        })
    })
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value

    errors[e.target.name] = null

    this.setState({ item, errors })
  }

  toggleModal = (modal, selected = null) => {
    const modalState = this.state[modal]
    // need to close modal
    if (!selected) {
      this.setState({ item: {}, errors: {}, selected: null, [modal]: !modalState })
      return
    }

    // need to open modal
    if (selected.data) {
      // open model with data
      switch (selected.service) {
        case 'databases':
          databaseRecord(selected.uuid)
            .then((respond) => {
              this.setState({
                modalLoading: false,
                item: respond.data,
                errors: {},
                selected,
                [modal]: !modalState,
              })
            })
            .catch((error) => {})
          break
        case 'tables':
          tableRecord(selected.uuid)
            .then((respond) => {
              this.setState({
                modalLoading: false,
                item: respond.data,
                errors: {},
                selected,
                [modal]: !modalState,
              })
            })
            .catch((error) => {})
          break

        default:
          break
      }
    } else {
      // open model without data
      this.setState({ modalLoading: false, item: {}, errors: {}, selected, [modal]: !modalState })
    }
  }

  deleteDatabase = () => {
    this.setState({ modalLoading: true }, () => {
      databaseDelete(this.state.selected.uuid)
        .then((respond) => {
          this.setState({ deleteDatabaseModal: false })
          this.getItems()
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: `Database deleted successfully!`,
          })
        })
        .catch((error) => {
          this.props.setErrorHandler(error.response)
        })
    })
  }

  refreshDatabase = (uuid) => {
    this.setState({ modalLoading: true }, () => {
      databaseRefresh(uuid || this.state.selected.uuid)
        .then((respond) => {
          if (uuid) {
            respond.data.forEach((table, index, array) => {
              this.refreshTable(table.uuid, index === array.length - 1)
            })
          }
          this.setState({ refreshDatabaseModal: false })
          this.getItems(false)
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  refreshTable = (refresh = true, uuid) => {
    this.setState({ modalLoading: true }, () => {
      tablesRefresh(uuid || this.state.selected.uuid)
        .then((respond) => {
          this.setState({ refreshTableModal: false })
          if (refresh) {
            this.getItems(false)
          }
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  handelTableStatusChange = (e, uuid) => {
    tableStatus(uuid, { status: e.target.value })
      .then((res) => {
        this.getItems(false)
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        switch (error.response.status) {
          case 422:
            {
              const temp = {}
              for (let index = 0; index < error.response.data.errors.length; index++) {
                const element = error.response.data.errors[index]
                // eslint-disable-next-line prefer-destructuring
                temp[element[0]] = element[1][0]
              }
              this.setState({ errors: temp })
            }
            break
          default:
            this.props.setErrorHandler(error.response)
            break
        }
      })
  }

  updateDatabase = () => {
    this.setState({ modalLoading: true }, () => {
      databaseUpdate(this.state.selected.uuid, this.state.item)
        .then((res) => {
          this.getItems(false)
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: 'Database updated please wait while system update you schema informations',
          })
          this.toggleModal('updateDatabaseModal')
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp, modalLoading: false })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  createDatabase = () => {
    this.setState({ modalLoading: true }, () => {
      databaseCreate(this.state.item)
        .then((res) => {
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: 'Database inserted please wait while system getting you schema informations',
          })
          this.getItems()
          this.toggleModal('createDatabaseModal')
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element?.[0][0]] = element[0][1]
                }
                this.setState({ errors: temp, modalLoading: false })
              }
              break
            default:
              this.getItems()
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  checkDatabase = (uuid, callback, cancelCallback) => {
    databaseCheck(uuid)
      .then((res) => {
        cancelCallback()
        if (!res.meta.status) {
          this.toggleModal('connectivityErrorModal', {
            data: false,
            service: 'databases',
            uuid: null,
          })
        } else {
          callback()
          this.getItems(false)
        }
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        switch (error.response.status) {
          case 422:
            {
              const temp = {}
              for (let index = 0; index < error.response.data.errors.length; index++) {
                const element = error.response.data.errors[index]
                // eslint-disable-next-line prefer-destructuring
                temp[element[0]] = element[1][0]
              }
              this.setState({ errors: temp, modalLoading: false })
            }
            break
          default:
            this.props.setErrorHandler(error.response)
            break
        }
      })
  }

  updateTableColumn = (data) => {
    tableColumnUpdate(this.state.selected.uuid, data)
      .then((res) => {
        this.getItems(false)
        this.toggleModal('updateTableModal')
        this.setState({ modalLoading: false })
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        switch (error.response.status) {
          case 422:
            {
              const temp = {}
              for (let index = 0; index < error.response.data.errors.length; index++) {
                const element = error.response.data.errors[index]
                // eslint-disable-next-line prefer-destructuring
                temp[element[0]] = element[1][0]
              }
              this.setState({ errors: temp, modalLoading: false })
            }
            break
          default:
            this.props.setErrorHandler(error.response)
            break
        }
      })
  }

  changepage = (page) => {
    // save page to state
    this.setState({ page }, () => {
      this.getItems()
    })
    // save page to context
  }

  close = () => {
    this.props.close()
  }

  deleteTable = () => {
    this.setState({ modalLoading: true }, () => {
      tableDelete(this.state.selected.uuid)
        .then((respond) => {
          this.setState({ deleteTableModal: false })
          this.getItems(false)
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          this.props.setErrorHandler(error.response)
        })
    })
  }

  render() {
    return (
      <ModalContainer className='w-largeModal' close={this.close} title='Manage Databases'>
        {this.state.deleteTableModal && (
          <DeleteTableModal
            loading={this.state.modalLoading}
            action={{ color: 'red', text: 'Yes, Delete', handler: this.deleteTable }}
            cancel={{ text: 'No, Keep it', handler: () => this.toggleModal('deletTableModal') }}
          />
        )}
        {this.state.deleteDatabaseModal && (
          <DeleteDatabaseModal
            loading={this.state.modalLoading}
            action={{ color: 'red', text: 'Yes, Delete', handler: this.deleteDatabase }}
            cancel={{ text: 'No, Keep it', handler: () => this.toggleModal('deleteDatabaseModal') }}
          />
        )}
        {this.state.refreshDatabaseModal && (
          <RefrechDatabaseModal
            loading={this.state.modalLoading}
            action={{ text: 'Yes, Refresh', handler: this.refreshDatabase }}
            cancel={{ text: 'No, Cancel', handler: () => this.toggleModal('refreshDatabaseModal') }}
          />
        )}
        {this.state.connectivityErrorModal && (
          <ConnectivityErrorModal
            loading={this.state.modalLoading}
            cancel={{ text: 'Ok', handler: () => this.toggleModal('connectivityErrorModal') }}
          />
        )}
        {this.state.refreshTableModal && (
          <RefrechTableModal
            loading={this.state.modalLoading}
            action={{ text: 'Yes, Refresh', handler: this.refreshTable }}
            cancel={{ text: 'No, Cancel', handler: () => this.toggleModal('refreshTableModal') }}
          />
        )}

        {this.state.updateDatabaseModal && (
          <UpdateDatabaseModal
            item={this.state.item}
            loading={this.state.modalLoading}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Yes, Update', handler: this.updateDatabase }}
            cancel={{ text: 'No, Cancel', handler: () => this.toggleModal('updateDatabaseModal') }}
          />
        )}
        {this.state.createDatabaseModal && (
          <CreateDatabaseModal
            item={this.state.item}
            loading={this.state.modalLoading}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Create', handler: this.createDatabase }}
            cancel={{ text: 'Cancel', handler: () => this.toggleModal('createDatabaseModal') }}
          />
        )}
        {this.state.updateTableModal && (
          <UpdateTableModal
            item={this.state.item}
            loading={this.state.modalLoading}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Update', handler: this.updateTableColumn }}
            cancel={{ text: 'Cancel', handler: () => this.toggleModal('updateTableModal') }}
          />
        )}
        <div className=' flex justify-between items-center mb-6 text-sm'>
          <p>Manage your database’s connection and update the tables’ resources</p>
          <Button
            action={() => {
              this.toggleModal('createDatabaseModal', {
                data: false,
                service: 'databases',
                uuid: null,
              })
            }}
            single
            type='button'
            className={` inline-flex items-center justify-center px-5 py-2 border border-transparent font-medium rounded-sm text-white  bg-primarycolor hover:bg-hovercolor focus:outline-none focus:border-primary  focus:shadow-outline-green  transition ease-in-out duration-150  text-xs  leading-5 whitespace-nowrap`}
          >
            New database
          </Button>
        </div>
        {/* accordiion item header */}
        <Loading value={this.state.loading}>
          {this.state.items.length === 0 ? (
            <div className='flex flex-col justify-center items-center my-20'>
              <svg
                className='w-12 h-12'
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M12.983 8.978c3.955 -.182 7.017 -1.446 7.017 -2.978c0 -1.657 -3.582 -3 -8 -3c-1.661 0 -3.204 .19 -4.483 .515m-2.783 1.228c-.471 .382 -.734 .808 -.734 1.257c0 1.22 1.944 2.271 4.734 2.74' />
                <path d='M4 6v6c0 1.657 3.582 3 8 3c.986 0 1.93 -.067 2.802 -.19m3.187 -.82c1.251 -.53 2.011 -1.228 2.011 -1.99v-6' />
                <path d='M4 12v6c0 1.657 3.582 3 8 3c3.217 0 5.991 -.712 7.261 -1.74m.739 -3.26v-4' />
                <line x1='3' y1='3' x2='21' y2='21' />
              </svg>
              <Text className='my-4 text-xs '>There is no databases available</Text>
            </div>
          ) : (
            <>
              <ScrollArea style={{ maxHeight: 350, height: 300, padding: '0 12px 0 0' }}>
                <div
                  className={`${
                    this.state.items.length === 0 ? '' : 'primary-border-t'
                  }  primary-border-r  primary-border-l  rounded-md overflow-hidden `}
                >
                  {this.state.items.map((item) => {
                    return (
                      <DatabaseItem
                        checkDatabase={this.checkDatabase}
                        handelTableStatusChange={this.handelTableStatusChange}
                        key={item.id}
                        item={item}
                        toggleModal={this.toggleModal}
                      />
                    )
                  })}
                </div>
              </ScrollArea>
              <Pagination
                page={this.state.page}
                total={this.state.total}
                pages={this.state.pages}
                count={this.state.count}
                changepage={this.changepage}
              />
            </>
          )}
        </Loading>
      </ModalContainer>
    )
  }
}
const mapStateToProps = (state) => ({
  refreshItems: state.app.view.refreshDatabase,
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  setDatabses: (data) => dispatch(setDatabses(data)),
  showMessage: (data) => dispatch(showMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageDatabases)
