import React, { Component } from 'react'
// eslint-disable-next-line absolute-import/no-relative-path
import Text from './Text'

export default class Loading extends Component {
  constructor() {
    super()
    this.state = { value: true }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    return null
  }

  render() {
    return (
      <>
        {this.state.value ? (
          <div className={`${this.props.bigWidth ? 'h-96' : 'h-32'} flex  text-center m-auto`}>
            <div className={`flex m-auto justify-center items-center gap-2 content-center `}>
              <div>
                <img
                  className={this.props.bigWidth ? 'w-20' : 'w-4'}
                  alt='Loading please wait'
                  src='/assets/images/loading.gif'
                />
              </div>
              <Text
                id='loading'
                className={`${this.props.bigWidth ? 'text-lg' : 'text-xs'} leading-6 px-3 ${this.props.className}`}
              >
                Loading please wait
              </Text>
            </div>
          </div>
        ) : (
          <div>{this.props.children}</div>
        )}
      </>
    )
  }
}
