import Button from 'components/Portions/Button'
import Text from 'components/Portions/Text'
import ModalContainer from 'components/Utilities/ModalContainer'
import React, { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class ConnectivityErrorModal extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title=''>
        <div className='flex flex-col items-center p-4'>
          <img src='/assets/images/error.png' alt='error' className='w-18 ' />
          <Text className='my-3  pr-4 text-base'>Connection Error</Text>
          <Text className='my-3 mb-6 text-xs px-4'>
            You are trying to fetch data from disconnected database. Please check the connectivity and try again
          </Text>
          <Button action={this.props.cancel.handler}>Ok</Button>
        </div>
      </ModalContainer>
    )
  }
}
