import { BasicConfig } from 'react-awesome-query-builder'

const commonOptionsForOperators = {
  cardinality: 0,
  isNotOp: false,
}

const commonOperatorsForDateAndDateTime = [
  'today',
  'yesterday',
  'equal',
  'not_equal',
  'less',
  'less_or_equal',
  'greater',
  'greater_or_equal',
  'between',
  'not_between',
  'is_empty',
  'is_not_empty',
]

const operators = {
  ...BasicConfig.operators,
  between: {
    ...BasicConfig.operators.between,
    valueLabels: ['Value from', 'Value to'],
    textSeparators: ['from', 'to'],
  },
  today: {
    ...commonOptionsForOperators,
    label: 'Today',
    labelForFormat: 'TODAY',
  },
  days: {
    label: 'Days',
    labelForFormat: 'DAYS',
  },
  weeks: {
    label: 'Weeks',
    labelForFormat: 'Weeks',
  },
  months: {
    label: 'Months',
    labelForFormat: 'MONTHS',
  },
  quarters: {
    label: 'Quarters',
    labelForFormat: 'QUARTERS',
  },
  years: {
    label: 'Years',
    labelForFormat: 'YEARS',
  },
  day: {
    ...commonOptionsForOperators,
    label: 'Day',
    labelForFormat: 'DAY',
  },
  week: {
    ...commonOptionsForOperators,
    label: 'Week',
    labelForFormat: 'Week',
  },
  month: {
    ...commonOptionsForOperators,
    label: 'Month',
    labelForFormat: 'MONTH',
  },
  quarter: {
    ...commonOptionsForOperators,
    label: 'Quarter',
    labelForFormat: 'QUARTER',
  },
  year: {
    ...commonOptionsForOperators,
    label: 'Year',
    labelForFormat: 'YEAR',
  },
  yesterday: {
    ...commonOptionsForOperators,
    label: 'Yesterday',
    labelForFormat: 'YESTERDAY',
  },
  pastWeek: {
    ...commonOptionsForOperators,
    label: 'Past Week',
    labelForFormat: 'PAST WEEK',
  },
  pastMonth: {
    ...commonOptionsForOperators,
    label: 'Past Month',
    labelForFormat: 'PAST MONTH',
  },
  pastYear: {
    ...commonOptionsForOperators,
    label: 'Past Year',
    labelForFormat: 'PAST YEAR',
  },
}

const widgets = {
  ...BasicConfig.widgets,
  text: {
    ...BasicConfig.widgets.text,
  },
  textarea: {
    ...BasicConfig.widgets.textarea,
    maxRows: 3,
  },
  slider: {
    ...BasicConfig.widgets.slider,
  },
  rangeslider: {
    ...BasicConfig.widgets.rangeslider,
  },
  date: {
    ...BasicConfig.widgets.date,
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD',
  },
  time: {
    ...BasicConfig.widgets.time,
    timeFormat: 'HH:mm',
    valueFormat: 'HH:mm:ss',
  },
  datetime: {
    ...BasicConfig.widgets.datetime,
    timeFormat: 'HH:mm',
    dateFormat: 'DD.MM.YYYY',
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  func: {
    ...BasicConfig.widgets.func,
    customProps: {
      showSearch: true,
    },
  },
  select: {
    ...BasicConfig.widgets.select,
  },
  customDate: {
    ...BasicConfig.widgets.number,
    type: 'customDate',
  },
}

const types = {
  ...BasicConfig.types,
  text: {
    ...BasicConfig.types.text,
    operators: ['equal', 'not_equal'],
  },
  customDate: {
    ...BasicConfig.types.number,
    defaultOperator: 'days',
    operators: ['days', 'weeks', 'months', 'quarters', 'years'],
    widgets: {
      ...BasicConfig.types.number.widgets,
      number: {
        ...BasicConfig.types.number.widgets.number,
        operators: ['days', 'weeks', 'months', 'quarters', 'years'],
      },
    },
  },
  customThisDate: {
    ...BasicConfig.types.number,
    defaultOperator: 'day',
    operators: ['day', 'week', 'month', 'quarter', 'year'],
    widgets: {
      ...BasicConfig.types.number.widgets,
      number: {
        ...BasicConfig.types.number.widgets.number,
        operators: ['day', 'week', 'month', 'quarter', 'year'],
      },
    },
  },
  date: {
    ...BasicConfig.types.date,
    defaultOperator: 'today',
    operators: commonOperatorsForDateAndDateTime,
    widgets: {
      ...BasicConfig.types.date.widgets,
      date: {
        ...BasicConfig.types.date.widgets.date,
        operators: commonOperatorsForDateAndDateTime,
      },
    },
  },
  datetime: {
    ...BasicConfig.types.datetime,
    defaultOperator: 'today',
    operators: commonOperatorsForDateAndDateTime,
    widgets: {
      ...BasicConfig.types.datetime.widgets,
      date: {
        ...BasicConfig.types.date.widgets.date,
        operators: commonOperatorsForDateAndDateTime,
      },
    },
  },
}

export default {
  ...BasicConfig,
  conjunctions: {
    AND: {
      ...BasicConfig.conjunctions,
      label: 'And',
    },
    OR: { ...BasicConfig.conjunctions, label: 'Or' },
  },
  settings: {
    ...BasicConfig.settings,
    showNot: false,
  },
  widgets,
  operators,
  types,
}
