import CheckBox from 'components/Portions/CheckBox'
import DropList from 'components/Portions/DropList'
import FormGroup from 'components/Portions/FormGroup'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class UpdateTableModal extends Component {
  constructor() {
    super()
    this.state = {
      item: {},
      items: [],
      errors: {},
      loading: false,

      // geometryError: false,
      geometryTypes: {
        none: { value: 0, types: [] },
        geometry: { value: 2, types: ['str', 'geometry'] },
        Lat: { value: 1, types: ['float', 'decimal'] },
        Lon: { value: 1, types: ['float', 'decimal'] },
        startLat: { value: 0.5, types: ['float', 'decimal'] },
        startLon: { value: 0.5, types: ['float', 'decimal'] },
        endLat: { value: 0.5, types: ['float', 'decimal'] },
        endLon: { value: 0.5, types: ['float', 'decimal'] },
        wkb: { value: 2, types: ['str', 'geometry'] },
        wkt: { value: 2, types: ['str', 'geometry'] },
        geojson: { value: 2, types: ['str', 'geometry'] },
      },
      featureTypes: ['point', 'line', 'polygon'],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading || JSON.stringify(props.errors) !== JSON.stringify(state.errors)) {
      return { loading: props.loading, errors: props.errors }
    }
    return false
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ items: this.props.item?.columns, item: this.props.item })
  }

  checkErrors = (column) => {
    // check total size of selected types
    const total = this.state.items
      .map((x) => {
        if (x.geometry === 'false') {
          return 0
        }
        return this.state.geometryTypes[x.geometry_type].value
      })
      .reduce((a, b) => a + b, 0)

    // check if geometry selected or not
    if (column.geometry === 'false') {
      return false
    }

    if (!this.state.geometryTypes[column.geometry_type]?.types?.includes(column.type)) {
      return true
    }
    if (this.state.geometryTypes[column.geometry_type].value === 0.5 && this.state.item.feature_type !== 'line') {
      return true
    }
    if (this.state.geometryTypes[column.geometry_type].value !== 2) {
      return (
        this.state.items.filter((x) => {
          return x.geometry_type === column.geometry_type
        }).length > 1
      )
    }
    return total !== 2
  }

  checkAllErrors = () => {
    return (
      this.state.items
        .map((x) => {
          return this.checkErrors(x)
        })
        .filter(Boolean).length !== 0
    )
  }

  handleChange = (e) => {
    const { item } = this.state
    this.setState({
      item: { ...item, [e.target.name]: e.target.value },
    })
  }

  onCheckUpdate = (column, e) => {
    const { items } = this.state

    this.setState({
      items: items.map((x) => {
        if (x.id === column.id) {
          x[e.target.name] = e.target.value
          if (x.geometry === 'false') {
            x.geometry_type = 'none'
          } else {
            x.select = 'true'
          }
        }

        return x
      }),
    })
  }

  render() {
    return (
      <ModalContainer className='w-smallModal  py-3' title='Update Table'>
        <Modal
          disable={this.checkAllErrors()}
          loading={this.state.loading}
          {...this.props}
          action={{
            text: 'Update',
            handler: () => {
              this.props.action.handler({ feature_type: this.state.item.feature_type, columns: this.state.items })
            },
          }}
        >
          <FormGroup horizontal className=''>
            <Label>Feature type</Label>
            <DropList
              options={this.state.featureTypes.map((x) => {
                return { value: x, text: x }
              })}
              className='w-40'
              name='feature_type'
              onChange={this.handleChange}
              id='feature_type'
              value={this.state.item.feature_type}
            />
          </FormGroup>

          <InputError className='mb-2'>
            {this.checkAllErrors() && this.state.item.feature_type !== 'line'
              ? `you can select start and end only with lines`
              : null}
          </InputError>

          <div className='grid-item grid grid-cols-10  mt-4  rounded-t-md py-1 px-2'>
            <div className='item-title col-span-6 flex justify-start items-center gap-4 cursor-pointer'>
              <div className='item-details text-base'>
                <div className={`flex items-center justify-start gap-2 `}>
                  <p>Field name</p>
                </div>
              </div>
            </div>
            <div className='item-options col-span-4 flex justify-around items-center'>
              <Text className='w-12 text-center'>Selected</Text>
              <Text className='w-14 text-center'>Geometry</Text>
              <Text className='w-24 text-center'>Type</Text>
            </div>
          </div>
          {this.state.items.map((column) => {
            return (
              <div
                className={`${
                  this.checkErrors(column) ? 'bg-red-100' : ''
                } primary-border border-t-0  grid grid-cols-10 py-1 px-2   `}
                key={column.id}
              >
                <div className='item-title col-span-6 flex justify-start items-center gap-4'>
                  <div className='item-details text-sm'>
                    <div className='flex  text-xs items-center justify-start gap-2 '>
                      <p>{`${column.name} `}</p>

                      <span>{`[${column.type}]`}</span>
                    </div>
                  </div>
                </div>
                <div className='item-options col-span-4 flex justify-around items-center'>
                  <div className='w-12 items-center flex justify-center'>
                    <CheckBox
                      onChange={(e) => {
                        this.onCheckUpdate(column, e)
                      }}
                      name='select'
                      options={['true', 'false']}
                      string
                      checked={column.select}
                    />
                  </div>
                  <div className='w-14 items-center flex justify-center'>
                    <CheckBox
                      onChange={(e) => {
                        this.onCheckUpdate(column, e)
                      }}
                      name='geometry'
                      options={['true', 'false']}
                      string
                      checked={column.geometry}
                    />
                  </div>

                  <div className='w-20'>
                    <DropList
                      disabled={column.geometry === 'false'}
                      options={Object.keys(this.state.geometryTypes).map((x) => {
                        return { value: x, text: x }
                      })}
                      className='w-20 px-1'
                      name='geometry_type'
                      onChange={(e) => {
                        this.onCheckUpdate(column, e)
                      }}
                      id='geometry_type'
                      value={column.geometry_type}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </Modal>
      </ModalContainer>
    )
  }
}
