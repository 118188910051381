import DropList from 'components/Portions/DropList'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import PasswordChecker from 'components/Portions/PasswordChecker'
import { Component } from 'react'
import { connect } from 'react-redux'

// eslint-disable-next-line react/prefer-stateless-function
class CreateUserModal extends Component {
  constructor() {
    super()
    this.state = {
      item: {},
      errors: {},
      loading: false,
      types: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.loading !== state.loading ||
      JSON.stringify(props.item) !== JSON.stringify(state.item) ||
      JSON.stringify(props.errors) !== JSON.stringify(state.errors)
    ) {
      return { loading: props.loading, item: props.item, errors: props.errors }
    }
    return false
  }

  componentDidMount() {
    this.setTypes()
  }

  setTypes = () => {
    if (this.props.user.type !== 'systemAdministrator') {
      this.setState({
        types: [
          { value: 'organizationUser', text: 'Organization user' },
          { value: 'organizationAdministrator', text: 'Organization administrator' },
        ],
      })
    } else {
      this.setState({
        types: [
          { value: 'systemAdministrator', text: 'System administrator' },
          { value: 'organizationUser', text: 'Organization user' },
          { value: 'organizationAdministrator', text: 'Organization administrator' },
        ],
      })
    }
  }

  render() {
    return (
      <ModalContainer className='w-smallModal  py-3' title='Create User'>
        <Modal loading={this.state.loading} {...this.props}>
          <Form>
            <FormGroup>
              <Label htmlFor='first_name'>First Name* :</Label>
              <Input
                onChange={this.props.handelChange}
                id='first_name'
                name='first_name'
                placeholder='First Name'
                value={this.state.item?.first_name}
              />
              <InputError>{this.state.errors?.first_name}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='last_name'>Last Name* :</Label>
              <Input
                onChange={this.props.handelChange}
                id='last_name'
                name='last_name'
                placeholder='Last Name'
                value={this.state.item?.last_name}
              />
              <InputError>{this.state.errors?.last_name}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='email'>Email* :</Label>
              <Input
                onChange={this.props.handelChange}
                id='email'
                name='email'
                placeholder='user email'
                value={this.state.item?.email}
              />
              <InputError>{this.state.errors?.email}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='password'>Password:</Label>
              <Input
                onChange={this.props.handelChange}
                type='password'
                id='password'
                name='password'
                placeholder='user password'
                value={this.state.item.password}
              />
              <InputError>{this.state.errors.password?.message}</InputError>
              {this.state.errors.password?.message && (
                <div className='flex'>
                  <PasswordChecker
                    styles={{ marginRight: '16px' }}
                    checker={this.state.errors.password?.length}
                    label='At least 8 Characters length'
                  />
                  <PasswordChecker
                    styles={{ marginRight: '16px' }}
                    checker={this.state.errors.password?.lowerCase}
                    label='Lower case'
                  />
                  <PasswordChecker
                    styles={{ marginRight: '16px' }}
                    checker={this.state.errors.password?.upperCase}
                    label='Upper case'
                  />
                  <PasswordChecker
                    styles={{ marginRight: '16px' }}
                    checker={this.state.errors.password?.number}
                    label='Number'
                  />
                  <PasswordChecker
                    styles={{ marginRight: '16px' }}
                    checker={this.state.errors.password?.symbole}
                    label='Special Character'
                  />
                </div>
              )}
            </FormGroup>

            <FormGroup>
              <Label htmlFor='type'>User type* :</Label>
              <DropList
                options={this.state.types}
                name='type'
                onChange={(e) => {
                  this.props.handelChange(e)
                }}
                id='type'
                // placeholder='Select user type'
                value={this.state?.item?.type}
              />
              <InputError>{this.state?.errors?.type}</InputError>
            </FormGroup>
          </Form>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user.user,
})

export default connect(mapStateToProps)(CreateUserModal)
