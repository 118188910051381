import React, { Component } from 'react'

class TextArea extends Component {
  constructor() {
    super()
    this.state = { value: '' }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    return null
  }

  handleChange = (e) => {
    const target = { name: e.target.name, value: e.target.value }
    if (this.props.json) {
      try {
        target.value = JSON.parse(target.value)
      } catch (error) {
        target.value = this.state.value
      }
    }
    this.props.onChange({ target })
  }

  render() {
    return (
      <textarea
        name={this.props.name}
        id={this.props.id}
        value={this.props.json ? JSON.stringify(this.state.value) : this.state.value}
        placeholder={this.props.placeholder}
        className={
          this.props.className
            ? this.props.className
            : `w-full px-2 py-1 text-gray-700 border-1  text-xs border-gray-300 rounded-sm focus:border-focused focus:shadow-input focus:outline-none placeholder-gray-400`
        }
        onChange={this.handleChange}
        onKeyDown={this.props.onKeyDown}
        onBlur={this.props?.onBlur}
      />
    )
  }
}

export default TextArea
