import Button from 'components/Portions/Button'
import { Component } from 'react'
import ScrollArea from 'react-scrollbar'

export default class Modal extends Component {
  constructor() {
    super()
    this.state = { disable: false, loading: false }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.disable !== nextProps.disable || prevState.loading !== nextProps.loading) {
      return {
        disable: nextProps.disable,
        loading: nextProps.loading,
      }
    }
    return null
  }

  render() {
    return (
      <>
        <ScrollArea
          smoothScrolling
          style={{
            // eslint-disable-next-line no-nested-ternary
            height: this.props.small ? 160 : this.props.medium ? 240 : this.props.height || 300,
            padding: !this.props.padding ? '0 12px 10px 0' : this.props.padding,
            ...this.props?.style,
          }}
        >
          {this.props.children}
        </ScrollArea>
        <div className=' mt-5 flex flex-row-reverse select-none'>
          {this.props?.action && (
            <Button
              disable={this.props?.disableSaveButton || this.state.disable}
              loading={this.state.loading}
              single
              color={this.props?.action?.color}
              action={this.props?.action?.handler}
              className={this.props?.action?.className}
            >
              {this.props?.action?.text || 'Save'}
            </Button>
          )}
          {this.props?.cancel && (
            <Button
              // className={`${this.props.loading && 'cursor-default'}`}
              action={this.props.loading ? () => {} : this.props?.cancel?.handler}
              className={this.props?.cancel?.className}
              secondary
            >
              {this.props?.cancel?.text || 'Cancel'}
            </Button>
          )}
        </div>
      </>
    )
  }
}
