const ErrorX = ({ dark, normalSize, active }) => {
  const classes = dark ? ' bg-droplist' : ' bg-gray-100'
  const defaultClass = dark ? ' text-subPrimary' : ' text-gray-200'
  const svgActiveClasses = active ? 'text-white' : defaultClass
  const size = normalSize ? '0 0 32 32' : '0 0 24 24'
  return (
    <span
      className={` ${
        active ? ' bg-red-600' : classes
      }   h-4 w-4 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox={size}
        strokeWidth='1.5'
        stroke='currentColor'
        className={`w-4 h-4 ${svgActiveClasses}`}
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
      </svg>
    </span>
  )
}

export default ErrorX
