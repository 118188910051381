export const LOCALES = {
  en: 'English',
  fr: 'Française',
  es: 'Español',
  pt: 'Português',
  ja: '日本語',
  cn: '简体中文',
  ru: 'Русский',
  fi: 'Suomi',
  // sv: 'Svenska',
}

export const LOCALE_CODES = Object.keys(LOCALES).reduce((acc, key) => ({ ...acc, [key]: key }), {})
