/* eslint-disable linebreak-style */
import mainService from 'services/mainService'

export function organizationRecords(data) {
  let parameters = ''

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const value = data[key]
    switch (key) {
      case 'order':
        parameters = `${parameters}&order=${value.type}.${value.order}`
        break
      case 'page':
        parameters = `${parameters}&page=${value}`
        break
      case 'count':
        parameters = `${parameters}&limit=${value}`
        break
      case 'filter':
        if (value.length >= 1) {
          parameters = `${parameters}&filter=${value}`
        }
        break
      case 'search':
        if (value.length >= 1) {
          parameters = `${parameters}&search=${value.join(',')}`
        }
        break
      default:
        break
    }
  }
  return mainService
    .get(`organizations/records?rand=${new Date().getTime()}${parameters}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function organizationRecord(uuid) {
  return mainService
    .get(`organizations/${uuid}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function organizationList() {
  return mainService
    .get(`organizations/list`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function organizationUpdate(uuid, data) {
  return mainService
    .put(`organizations/${uuid}/update`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function organizationDelete(uuid) {
  return mainService
    .delete(`organizations/${uuid}/delete`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function organizationRefresh(uuid) {
  return mainService
    .get(`organizations/${uuid}/refreshorganization`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function organizationRefreshTable(uuid) {
  return mainService
    .get(`organizations/${uuid}/refreshTable`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function organizationCheck(uuid) {
  return mainService
    .get(`organizations/${uuid}/check`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function organizationCreate(data) {
  return mainService
    .post('organizations/create', data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
