/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setErrorHandler } from 'store/actions/errorsActions'

class ServerError extends Component {
  render() {
    return (
      <>
        {this.props.serverError && (
          <section className='items-center justify-center inset-0 flex fixed-position w-full h-full z-first bg-white overflow-hidden  '>
            <div className='relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
              <div className='relative'>
                <img src='/assets/images/server-error.png' className='mx-auto  ' alt='error' />
                <blockquote className='mt-8'>
                  <div className='max-w-3xl mx-auto text-center  leading-9 font-sm text-gray-900'>
                    <p className='font-sm font-bold'>Application Error</p>
                    <p className='text-sm'>
                      Application error please contact your system administrator or
                      <a
                        className='text-primary cursor-pointer'
                        onClick={() => {
                          this.props.setErrorHandler({ status: null })
                        }}
                      >
                        {` click here`}
                      </a>
                      {` To try again .`}
                    </p>
                  </div>
                </blockquote>
              </div>
            </div>
          </section>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  serverError: state.app.errors.serverError,
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ServerError)
