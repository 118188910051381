/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import Console from 'global/window'
import * as MapStateActions from 'kepler.gl/dist/actions/map-state-actions'
import * as MapStyleActions from 'kepler.gl/dist/actions/map-style-actions'
import * as ProviderActions from 'kepler.gl/dist/actions/provider-actions'
import * as UIStateActions from 'kepler.gl/dist/actions/ui-state-actions'
import * as VisStateActions from 'kepler.gl/dist/actions/vis-state-actions'
import { KeplerGlFactory } from 'kepler.gl/dist/components'
import { RootContext } from 'kepler.gl/dist/components/context'
import { connect as keplerGlConnect } from 'kepler.gl/dist/connect/keplergl-connect'
import {
  DEFAULT_MAPBOX_API_URL,
  DIMENSIONS,
  KEPLER_GL_NAME,
  KEPLER_GL_VERSION,
  // eslint-disable-next-line comma-dangle, prettier/prettier
  THEME,
} from 'kepler.gl/dist/constants/default-settings'
import { MISSING_MAPBOX_TOKEN } from 'kepler.gl/dist/constants/user-feedbacks'
import { theme as basicTheme, themeBS, themeLT } from 'kepler.gl/dist/styles/base'
import { mergeMessages } from 'kepler.gl/dist/utils/locale-utils'
import { validateToken } from 'kepler.gl/dist/utils/mapbox-utils'
import { observeDimensions, unobserveDimensions } from 'kepler.gl/dist/utils/observe-dimensions'
import { generateHashId } from 'kepler.gl/dist/utils/utils'
import React, { Component, createRef } from 'react'
import { IntlProvider } from 'react-intl'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import styled, { ThemeProvider, withTheme } from 'styled-components'
// eslint-disable-next-line absolute-import/no-relative-path
import { messages } from 'translations'

// Maybe we should think about exporting this or creating a variable
// as part of the base.js theme
const GlobalStyle = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.fontWeight};
  font-size: ${(props) => props.theme.fontSize};
  line-height: ${(props) => props.theme.lineHeight};

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.labelColor};
  }

  .mapboxgl-ctrl .mapboxgl-ctrl-logo {
    display: none;
  }
`
const BottomWidgetOuter = styled.div(
  ({ absolute }) => `
  ${absolute ? 'position: absolute; bottom: 0; right: 0;' : ''}
  pointer-events: none; /* prevent padding from blocking input */
  & > * {
    /* all children should allow input */
    pointer-events: all;
  }`,
)

export const mapFieldsSelector = (props) => ({
  getMapboxRef: props.getMapboxRef,
  mapboxApiAccessToken: props.mapboxApiAccessToken,
  mapboxApiUrl: props.mapboxApiUrl,
  mapState: props.mapState,
  mapStyle: props.mapStyle,
  onDeckInitialized: props.onDeckInitialized,
  onViewStateChange: props.onViewStateChange,
  deckGlProps: props.deckGlProps,
  uiStateActions: props.uiStateActions,
  visStateActions: props.visStateActions,
  mapStateActions: props.mapStateActions,

  // visState
  editor: props.visState.editor,
  datasets: props.visState.datasets,
  layers: props.visState.layers,
  layerOrder: props.visState.layerOrder,
  layerData: props.visState.layerData,
  layerBlending: props.visState.layerBlending,
  filters: props.visState.filters,
  interactionConfig: props.visState.interactionConfig,
  hoverInfo: props.visState.hoverInfo,
  clicked: props.visState.clicked,
  mousePos: props.visState.mousePos,
  animationConfig: props.visState.animationConfig,

  // uiState
  activeSidePanel: props.uiState.activeSidePanel,
  mapControls: props.uiState.mapControls,

  locale: props.uiState.locale,
})

export const sidePanelSelector = (props, availableProviders) => ({
  appName: props.appName,
  version: props.version,
  appWebsite: props.appWebsite,
  mapStyle: props.mapStyle,
  onSaveMap: props.onSaveMap,
  uiState: props.uiState,
  mapStyleActions: props.mapStyleActions,
  visStateActions: props.visStateActions,
  uiStateActions: props.uiStateActions,

  datasets: props.visState.datasets,
  filters: props.visState.filters,
  layers: props.visState.layers,
  layerOrder: props.visState.layerOrder,
  layerClasses: props.visState.layerClasses,
  interactionConfig: props.visState.interactionConfig,
  mapInfo: props.visState.mapInfo,
  layerBlending: props.visState.layerBlending,

  width: props.sidePanelWidth,
  availableProviders,
  mapSaved: props.providerState.mapSaved,
})

export const plotContainerSelector = (props) => ({
  width: props.width,
  height: props.height,
  exportImageSetting: props.uiState.exportImage,
  mapFields: mapFieldsSelector(props),
  addNotification: props.uiStateActions.addNotification,
  setExportImageSetting: props.uiStateActions.setExportImageSetting,
  setExportImageDataUri: props.uiStateActions.setExportImageDataUri,
  setExportImageError: props.uiStateActions.setExportImageError,
  splitMaps: props.visState.splitMaps,
})

export const isSplitSelector = (props) => props.visState.splitMaps && props.visState.splitMaps.length > 1

export const bottomWidgetSelector = (props, theme) => ({
  filters: props.visState.filters,
  datasets: props.visState.datasets,
  uiState: props.uiState,
  layers: props.visState.layers,
  animationConfig: props.visState.animationConfig,
  visStateActions: props.visStateActions,
  toggleModal: props.uiStateActions.toggleModal,
  sidePanelWidth: props.uiState.readOnly ? 0 : props.sidePanelWidth + theme.sidePanel.margin.left,
})

export const modalContainerSelector = (props, rootNode) => ({
  appName: props.appName,
  mapStyle: props.mapStyle,
  visState: props.visState,
  mapState: props.mapState,
  uiState: props.uiState,
  providerState: props.providerState,

  mapboxApiAccessToken: props.mapboxApiAccessToken,
  mapboxApiUrl: props.mapboxApiUrl,
  visStateActions: props.visStateActions,
  uiStateActions: props.uiStateActions,
  mapStyleActions: props.mapStyleActions,
  providerActions: props.providerActions,

  rootNode,
  // User defined cloud provider props
  cloudProviders: props.cloudProviders,
  onExportToCloudSuccess: props.onExportToCloudSuccess,
  onLoadCloudMapSuccess: props.onLoadCloudMapSuccess,
  onLoadCloudMapError: props.onLoadCloudMapError,
  onExportToCloudError: props.onExportToCloudError,
})

export const geoCoderPanelSelector = (props) => ({
  isGeocoderEnabled: props.visState.interactionConfig.geocoder.enabled,
  mapboxApiAccessToken: props.mapboxApiAccessToken,
  mapState: props.mapState,
  updateVisData: props.visStateActions.updateVisData,
  removeDataset: props.visStateActions.removeDataset,
  updateMap: props.mapStateActions.updateMap,
})

export const notificationPanelSelector = (props) => ({
  removeNotification: props.uiStateActions.removeNotification,
  notifications: props.uiState.notifications,
})

export const DEFAULT_KEPLER_GL_PROPS = {
  mapStyles: [],
  mapStylesReplaceDefault: false,
  mapboxApiUrl: DEFAULT_MAPBOX_API_URL,
  width: 800,
  height: 800,
  appName: KEPLER_GL_NAME,
  version: KEPLER_GL_VERSION,
  sidePanelWidth: DIMENSIONS.sidePanel.width,
  theme: {},
  cloudProviders: [],
  readOnly: false,
}

CustomKeplerGlFactory.deps = KeplerGlFactory.deps
function CustomKeplerGlFactory(
  BottomWidget,
  GeoCoderPanel,
  MapContainer,
  MapsLayout,
  ModalContainer,
  SidePanel,
  PlotContainer,
  NotificationPanel,
) {
  class KeplerGL extends Component {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = DEFAULT_KEPLER_GL_PROPS

    // eslint-disable-next-line react/static-property-placement
    static contextType = RootContext

    // eslint-disable-next-line react/state-in-constructor
    state = {
      dimensions: null,
    }

    componentDidMount() {
      this.validateMapboxToken()
      this.loadMapStyle()
      if (typeof this.props.onKeplerGlInitialized === 'function') {
        this.props.onKeplerGlInitialized()
      }
      if (this.root.current instanceof HTMLElement) {
        observeDimensions(this.root.current, this.handleResize)
      }
    }

    componentWillUnmount() {
      if (this.root.current instanceof HTMLElement) {
        unobserveDimensions(this.root.current)
      }
    }

    handleResize = (dimensions) => {
      this.setState({ dimensions })
    }

    // eslint-disable-next-line react/sort-comp
    root = createRef()

    bottomWidgetRef = createRef()

    /* selectors */
    // eslint-disable-next-line react/sort-comp
    themeSelector = (props) => props.theme

    availableThemeSelector = createSelector(this.themeSelector, (theme) =>
      typeof theme === 'object'
        ? {
            ...basicTheme,
            ...theme,
          }
        : theme === THEME.light
        ? themeLT
        : theme === THEME.base
        ? themeBS
        : theme,
    )

    availableProviders = createSelector(
      (props) => props.cloudProviders,
      (providers) =>
        Array.isArray(providers) && providers.length
          ? {
              hasStorage: providers.some((p) => p.hasPrivateStorage()),
              hasShare: providers.some((p) => p.hasSharingUrl()),
            }
          : {},
    )

    localeMessagesSelector = createSelector(
      (props) => props.localeMessages,
      (customMessages) => (customMessages ? mergeMessages(messages, customMessages) : messages),
    )

    /* private methods */
    // eslint-disable-next-line react/sort-comp
    validateMapboxToken() {
      const { mapboxApiAccessToken } = this.props
      if (!validateToken(mapboxApiAccessToken)) {
        // eslint-disable-next-line no-undef
        Console.warn(MISSING_MAPBOX_TOKEN)
      }
    }

    loadMapStyle = () => {
      const defaultStyles = Object.values(this.props.mapStyle.mapStyles)
      // add id to custom map styles if not given
      const customStyles = (this.props.mapStyles || []).map((ms) => ({
        ...ms,
        id: ms.id || generateHashId(),
      }))

      const allStyles = [...customStyles, ...defaultStyles].reduce(
        (acc, style) => {
          const hasStyleObject = style.style && typeof style.style === 'object'
          acc[hasStyleObject ? 'toLoad' : 'toRequest'][style.id] = style

          return acc
        },
        { toLoad: {}, toRequest: {} },
      )

      this.props.mapStyleActions.loadMapStyles(allStyles.toLoad)
      this.props.mapStyleActions.requestMapStyles(allStyles.toRequest)
    }

    render() {
      const { id, width, height, uiState, visState } = this.props

      const dimensions = this.state.dimensions || { width, height }
      const {
        splitMaps, // this will store support for split map view is necessary
      } = visState

      const isSplit = isSplitSelector(this.props)
      const theme = this.availableThemeSelector(this.props)
      const localeMessages = this.localeMessagesSelector(this.props)
      const isExportingImage = uiState.exportImage.exporting
      const availableProviders = this.availableProviders(this.props)

      const mapFields = mapFieldsSelector(this.props)
      const sideFields = sidePanelSelector(this.props, availableProviders)
      const plotContainerFields = plotContainerSelector(this.props)
      const bottomWidgetFields = bottomWidgetSelector(this.props, theme)
      const modalContainerFields = modalContainerSelector(this.props, this.root.current)
      const geoCoderPanelFields = geoCoderPanelSelector(this.props)
      const notificationPanelFields = notificationPanelSelector(this.props)
      const mapContainers = !isSplit
        ? [<MapContainer primary key={0} index={0} locale={uiState.locale} {...mapFields} mapLayers={null} />]
        : splitMaps.map((settings, index) => (
            <MapContainer
              key={index}
              index={index}
              primary={index === 1}
              {...mapFields}
              mapLayers={splitMaps[index].layers}
            />
          ))

      return (
        <RootContext.Provider value={this.root}>
          <IntlProvider locale={uiState.locale} messages={localeMessages[uiState.locale]}>
            <ThemeProvider theme={theme}>
              <GlobalStyle
                className='kepler-gl'
                id={`kepler-gl__${id}`}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  width: `${width}px`,
                  height: `${height}px`,
                }}
                ref={this.root}
              >
                <NotificationPanel {...notificationPanelFields} />
                <SidePanel {...sideFields} />
                <MapsLayout className='maps'>{mapContainers}</MapsLayout>
                {isExportingImage && <PlotContainer {...plotContainerFields} />}
                <GeoCoderPanel {...geoCoderPanelFields} />
                <BottomWidgetOuter absolute>
                  <BottomWidget ref={this.bottomWidgetRef} {...bottomWidgetFields} containerW={dimensions.width} />
                </BottomWidgetOuter>
                <ModalContainer
                  {...modalContainerFields}
                  containerW={dimensions.width}
                  containerH={dimensions.height}
                />
              </GlobalStyle>
            </ThemeProvider>
          </IntlProvider>
        </RootContext.Provider>
      )
    }
  }

  return keplerGlConnect(mapStateToProps, makeMapDispatchToProps)(withTheme(React.memo(KeplerGL)))
}

function mapStateToProps(state = {}, props) {
  return {
    ...props,
    visState: state.visState,
    mapStyle: state.mapStyle,
    mapState: state.mapState,
    uiState: state.uiState,
    providerState: state.providerState,
  }
}

const defaultUserActions = {}
const getDispatch = (dispatch) => dispatch
const getUserActions = (dispatch, props) => props.actions || defaultUserActions

function makeGetActionCreators() {
  return createSelector([getDispatch, getUserActions], (dispatch, userActions) => {
    const [visStateActions, mapStateActions, mapStyleActions, uiStateActions, providerActions] = [
      VisStateActions,
      MapStateActions,
      MapStyleActions,
      UIStateActions,
      ProviderActions,
    ].map((actions) => bindActionCreators(mergeActions(actions, userActions), dispatch))

    return {
      visStateActions,
      mapStateActions,
      mapStyleActions,
      uiStateActions,
      providerActions,
      dispatch,
    }
  })
}

function makeMapDispatchToProps() {
  const getActionCreators = makeGetActionCreators()
  const mapDispatchToProps = (dispatch, ownProps) => {
    const groupedActionCreators = getActionCreators(dispatch, ownProps)

    return {
      ...groupedActionCreators,
      dispatch,
    }
  }

  return mapDispatchToProps
}

/**
 * Override default kepler.gl actions with user defined actions using the same key
 */
function mergeActions(actions, userActions) {
  const overrides = {}
  for (const key in userActions) {
    // eslint-disable-next-line no-prototype-builtins
    if (userActions.hasOwnProperty(key) && actions.hasOwnProperty(key)) {
      overrides[key] = userActions[key]
    }
  }

  return { ...actions, ...overrides }
}

export function replaceKeplerGl() {
  return [KeplerGlFactory, CustomKeplerGlFactory]
}
