/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import { setErrorHandler } from 'store/actions/errorsActions'

class DatabasesList extends Component {
  constructor(props) {
    super(props)
    this.listRef = React.createRef()

    this.state = {
      selectedDatabase: null,
      selectedTable: null,
      opened: false,
      items: [],
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      JSON.stringify(props.selectedDatabase) !== JSON.stringify(state.selectedDatabase) ||
      JSON.stringify(props.selectedTable) !== JSON.stringify(state.selectedTable) ||
      JSON.stringify(props.items) !== JSON.stringify(state.items)
    ) {
      return {
        selectedDatabase: props.selectedDatabase,
        selectedTable: props.selectedTable,
        items: props.items,
      }
    }
    return null
  }

  toggleList = () => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      opened: !this.state.opened,
    })
  }

  handleClickOutside = (e) => {
    if (this.listRef && !this.listRef.current?.contains(e.target)) {
      this.setState({ opened: false })
    }
  }

  render() {
    return (
      <div ref={this.listRef} className='w-full flex items-center mb-3  noSelect relative databaseDropList'>
        <div
          onClick={this.toggleList}
          className='cursor-pointer  selectedArea px-1 w-full flex justify-between items-center'
        >
          {this.state.selectedDatabase && (
            <p className='selectedDatabaseName'>
              {this.state.selectedDatabase.title || this.state.selectedDatabase.label}
            </p>
          )}
          <div className='flex flex-col justify-center items-start'>
            {!this.state.selectedDatabase && <p> Select database</p>}
            {this.state.selectedTable && <p className='text-xs selectedTableName'>{this.state.selectedTable.name}</p>}
          </div>
          <svg
            className='icon '
            viewBox='0 0 24 24'
            strokeWidth='2'
            stroke='currentColor'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <polyline points='6 15 12 9 18 15' />
          </svg>
        </div>
        {this.state.opened && (
          <div className='absolute bottom-0 z-10 options w-full mb-10 overflow-hidden  '>
            <ScrollArea style={{ height: 200 }} smoothScrolling>
              {this.state.items.map((item) => {
                return (
                  <Fragment key={item.id}>
                    <p className=' gray w-full  bg-primarycolor  text-white py-1 px-2 cursor-default '>{item.title}</p>

                    <div>
                      {item.tables.filter((x) => x.status === 'active').length === 0 && (
                        <p className='dark-gray text-white tableName hover:bg-gray-300 transition duration-150 cursor-pointer'>
                          No table
                        </p>
                      )}
                      {item.tables
                        .filter((x) => x.status === 'active')
                        .map((table) => {
                          return (
                            <p
                              onClick={() => {
                                this.props.selectTable(item, table)
                                this.setState({ opened: false })
                              }}
                              key={table.id}
                              className='  dark-gray text-white  tableName hover:bg-gray-300 transition duration-150 cursor-pointer'
                            >
                              {table.name}
                            </p>
                          )
                        })}
                    </div>
                  </Fragment>
                )
              })}
            </ScrollArea>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DatabasesList)
