/* eslint-disable react/sort-comp */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import styled from 'styled-components'
import get from 'lodash.get'

import CustomMultiSelectFilterPanelFactory from 'components/CustomKepler/filters/filter-panels/CustomMultiSelectFilterPanel'
import { FilterPanelFactory } from 'kepler.gl/components'
import { ALL_FIELD_TYPES, FILTER_TYPES } from 'kepler.gl/constants'

const StyledFilterPanel = styled.div`
  margin-bottom: 12px;
  border-radius: 1px;
`

CustomFilterPanelFactory.deps = [
  FilterPanelFactory.deps[0],
  FilterPanelFactory.deps[1],
  FilterPanelFactory.deps[2],
  CustomMultiSelectFilterPanelFactory,
  FilterPanelFactory.deps[4],
  FilterPanelFactory.deps[5],
]

function CustomFilterPanelFactory(
  NewFilterPanel,
  TimeRangeFilterPanel,
  SingleSelectFilterPanel,
  MultiSelectFilterPanel,
  RangeFilterPanel,
  PolygonFilterPanel,
) {
  const FilterPanelComponents = {
    default: NewFilterPanel,
    [FILTER_TYPES.timeRange]: TimeRangeFilterPanel,
    [FILTER_TYPES.select]: SingleSelectFilterPanel,
    [FILTER_TYPES.multiSelect]: MultiSelectFilterPanel,
    [FILTER_TYPES.range]: RangeFilterPanel,
    [FILTER_TYPES.polygon]: PolygonFilterPanel,
  }

  return class FilterPanel extends Component {
    static propTypes = {
      idx: PropTypes.number,
      filters: PropTypes.arrayOf(PropTypes.any).isRequired,
      filter: PropTypes.object.isRequired,
      setFilter: PropTypes.func.isRequired,
      removeFilter: PropTypes.func.isRequired,
      enlargeFilter: PropTypes.func.isRequired,
      toggleAnimation: PropTypes.func.isRequired,
      toggleFilterFeature: PropTypes.func.isRequired,
      datasets: PropTypes.object,
      showDatasetTable: PropTypes.func,
      isAnyFilterAnimating: PropTypes.bool,
    }

    /* selectors */

    nameSelector = (props) => props.filter.name

    dataIdSelector = (props) => props.filter.dataId[0]

    filterSelector = (props) => props.filters

    fieldsSelector = (props) => {
      const datasetId = props.filter.dataId[0]
      if (!datasetId) {
        return []
      }
      return get(props, ['datasets', datasetId, 'fields'], [])
    }

    // only show current field and field that's not already been used as a filter
    availableFieldsSelector = createSelector(
      this.fieldsSelector,
      this.filterSelector,
      this.nameSelector,
      this.dataIdSelector,
      (fields, filters, name, dataId) =>
        fields.filter(
          (f) =>
            f.type &&
            f.type !== ALL_FIELD_TYPES.geojson &&
            (f.name === name || !filters.find((d) => d.name === f.name && d.dataId === dataId)),
        ),
    )

    render() {
      const { filter } = this.props

      const { type } = filter
      const FilterFilterComponent = (type && FilterPanelComponents[type]) || FilterPanelComponents.default
      const allAvailableFields = this.availableFieldsSelector(this.props)

      return (
        <StyledFilterPanel className='filter-panel'>
          <FilterFilterComponent allAvailableFields={allAvailableFields} {...this.props} />
        </StyledFilterPanel>
      )
    }
  }
}

export default CustomFilterPanelFactory
