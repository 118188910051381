/* eslint-disable react/no-unused-state */
/* eslint-disable no-shadow */
import MapLabFiltersConfig from 'components/FetchData/Modals/FilterModal/FilterQueryBuilder.config'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { Builder, Utils as QbUtils, Query } from 'react-awesome-query-builder'

const operatorMapping = {
  equal: 'eq',
  not_equal: 'ne',
  greater: 'gt',
  greater_or_equal: 'ge',
  less: 'lt',
  less_or_equal: 'le',
  between: 'between',
  not_between: 'not_between',
  is_empty: 'is_empty',
  is_not_empty: 'is_not_empty',
  today: 'today',
  yesterday: 'yesterday',
}
const typesMapping = {
  boolean: 'boolean',
  decimal: 'number',
  number: 'number',
  float: 'number',
  int: 'number',
  numeric: 'number',
  str: 'text',
  text: 'text',
  date: 'datetime',
  datetime: 'datetime',
  timestamp: 'datetime',
  time: 'time',
}

const types = {
  text: {
    type: 'text',
    fieldSettings: {
      min: 0,
    },
  },
  number: {
    type: 'number',
    fieldSettings: {
      min: 0,
    },
  },
  datetime: {
    type: '!group',
    subfields: {
      Default: {
        type: 'datetime',
      },
      Past: {
        type: 'customDate',
        operators: ['days', 'weeks', 'months', 'quarters', 'years'],
        defaultValue: 1,
        fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        initialEmptyWhere: false,
      },
      This: {
        type: 'customThisDate',
        operators: ['day', 'week', 'month', 'quarter', 'year'],
        valueSources: ['value'],
      },
      Next: {
        type: 'customDate',
        operators: ['days', 'weeks', 'months', 'quarters', 'years'],
        defaultValue: 1,
        fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        initialEmptyWhere: false,
      },
    },
  },
  boolean: {
    type: 'boolean',
    widgets: {
      boolean: {
        widgetProps: {
          labelYes: 'True',
          labelNo: 'False',
        },
      },
    },
  },
  time: {
    type: 'time',
  },
}
// eslint-disable-next-line react/prefer-stateless-function
export default class FilterModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      config: {},
      columns: [],
      tree: null,
      filters: [],
      jsonTree: {},
      CURRENT_TS: 'currentTimeStamp(days=0)',
    }
  }

  componentDidMount = () => {
    const config = { ...MapLabFiltersConfig, fields: {} }

    this.props.columns
      .filter((x) => Object.keys(typesMapping).includes(x.type))
      .forEach((column) => {
        config.fields[column.name] = { ...types[typesMapping[column.type]], label: column.name }
      })

    this.setState({
      filters: this.props.filters,
      jsonTree: this.props.jsonTree,
      columns: this.props.columns,
      config,
      tree:
        Object.keys(this.props.jsonTree).length >= 1
          ? QbUtils.checkTree(QbUtils.loadTree(this.props.jsonTree), config)
          : QbUtils.checkTree(QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }), config),
    })
  }

  renderBuilder = (props) => (
    <div className='query-builder-container' style={{ padding: '10px' }}>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  )

  onChange = (immutableTree) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    this.setState({ tree: immutableTree })

    const jsonTree = QbUtils.getTree(immutableTree)
    this.setState({ jsonTree, filters: this.rulesToFilters(jsonTree) })

    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  }

  filterOperator = ({ operator, field, value }, type) => {
    console.log({ operator, field, value }, type)
    switch (type) {
      case 'This': {
        switch (operator) {
          case 'day': {
            return {
              col_name: field,
              symbol: operatorMapping.equal,
              value: this.state.CURRENT_TS,
            }
          }
          case 'week': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentWeek(weeks=0)', this.state.CURRENT_TS],
            }
          }
          case 'month': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentMonth(months=0)', this.state.CURRENT_TS],
            }
          }
          case 'quarter': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentQuarter(quarters=0)', this.state.CURRENT_TS],
            }
          }
          case 'year': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentYear(years=0)', this.state.CURRENT_TS],
            }
          }
          default: {
            return {}
          }
        }
      }
      case 'Past':
      case 'Next': {
        switch (operator) {
          case 'days': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentTimeStamp(days=${type === 'Past' ? -1 : 1})`
                : `currentTimeStamp(days=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'weeks': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentWeek(weeks=${type === 'Past' ? -1 : 1})`
                : `currentWeek(weeks=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'months': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentMonth(months=${type === 'Past' ? -1 : 1})`
                : `currentMonth(months=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'quarters': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentQuarter(quarters=${type === 'Past' ? -1 : 1})`
                : `currentQuarter(quarters=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'years': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentYear(years=${type === 'Past' ? -1 : 1})`
                : `currentYear(years=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          default: {
            return {}
          }
        }
      }
      default: {
        switch (operator) {
          case 'not_between':
          case 'between': {
            return {
              col_name: field,
              symbol: operatorMapping[operator],
              value,
            }
          }
          case 'is_null':
          case 'is_not_null': {
            return {
              col_name: field,
              symbol: operator === 'is_null' ? operatorMapping.equal : operatorMapping.not_equal,
              value: null,
            }
          }
          case 'is_empty':
          case 'is_not_empty': {
            return {
              col_name: field,
              symbol: operator === 'is_empty' ? operatorMapping.equal : operatorMapping.not_equal,
              value: null,
            }
          }
          case 'today': {
            return {
              col_name: field,
              symbol: operatorMapping.greater_or_equal,
              value: 'currentDate(days=0)',
            }
          }
          case 'yesterday': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: 'currentDate(days=-1)',
            }
          }
          default: {
            return {
              col_name: field,
              symbol: operatorMapping[operator],
              value: value[0],
            }
          }
        }
      }
    }
  }

  rulesToFilters = (rules) => {
    return Object.values(rules?.children1)
      .flatMap((r) => {
        if (r.type === 'rule') {
          return this.filterOperator(r.properties)
        }

        if ((r.type === 'rule_group' || r.type === 'group') && r.children1) {
          return Object.values(r?.children1).map((innerRule) => {
            if (innerRule.properties?.field?.includes('Past')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field }, 'Past')
            }
            if (innerRule.properties?.field?.includes('This')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field }, 'This')
            }
            if (innerRule.properties?.field?.includes('Next')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field }, 'Next')
            }
            return this.filterOperator({ ...innerRule.properties, field: r.properties.field })
          })
        }
        return {}
      })
      .filter((f) => f.value !== undefined)
  }

  render() {
    return (
      <ModalContainer className='w-mediumModal  py-3' title='Data Filtering'>
        <Modal
          {...this.props}
          padding='0'
          action={{
            handler: () => {
              this.props.action.handler(
                this.state.jsonTree,
                this.state.filters,
                this.state.jsonTree?.properties?.conjunction || 'AND',
              )
            },
          }}
        >
          {this.state.tree && (
            <Query
              {...this.state.config}
              value={this.state.tree}
              onChange={this.onChange}
              renderBuilder={this.renderBuilder}
            />
          )}
        </Modal>
      </ModalContainer>
    )
  }
}
