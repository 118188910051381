/* eslint-disable react/prefer-stateless-function */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import { setErrorHandler } from 'store/actions/errorsActions'

class FunctionsList extends Component {
  constructor(props) {
    super(props)
    this.listRef = React.createRef()

    this.state = {
      selectedFunction: null,
      opened: false,
      items: [],
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      JSON.stringify(props.selectedFunction) !== JSON.stringify(state.selectedFunction) ||
      JSON.stringify(props.items) !== JSON.stringify(state.items)
    ) {
      return {
        selectedFunction: props.selectedFunction,
        items: props.items,
      }
    }
    return null
  }

  // eslint-disable-next-line consistent-return
  toggleList = () => {
    if (this.props.disable) {
      return false
    }
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      opened: !this.state.opened,
    })
  }

  handleClickOutside = (e) => {
    if (this.listRef && !this.listRef.current?.contains(e.target)) {
      this.setState({ opened: false })
    }
  }

  render() {
    return (
      <div
        ref={this.listRef}
        className={` ${
          this.props.error ? 'bg-red-200' : 'bg-menu'
        } text-gray-400 py-1 col-span-10 rounded-sm     noSelect relative datasetDropList`}
      >
        <div
          onClick={this.toggleList}
          className='cursor-pointer  selectedArea px-1 w-full flex justify-between items-center'
        >
          <div className='flex'>
            {this.state.selectedFunction && <p>{this.state.selectedFunction.name}</p>}
            <div className='flex flex-col justify-center items-start'>
              {!this.state.selectedFunction && <p> Select function</p>}
            </div>
          </div>
          {this.state.opened && (
            <div className='absolute -ml-3 mt-20 z-10  w-66 options  overflow-hidden  '>
              <ScrollArea style={{ maxHeight: 200 }} smoothScrolling>
                {this.state.items.map((item) => {
                  return (
                    <Fragment key={item.id}>
                      <p
                        onClick={() => {
                          this.props.selectFunction(this.props.uuid, item)
                          this.setState({ opened: false })
                        }}
                        className='  dark-gray text-white justify-between flex  tableName hover:bg-gray-300 transition duration-150 cursor-pointer'
                      >
                        <span>{item.name}</span> {item.beta && <span className='text-red-400'>Beta</span>}
                      </p>
                    </Fragment>
                  )
                })}
              </ScrollArea>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FunctionsList)
