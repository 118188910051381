import React, { Component } from 'react'

export default class ToolTip extends Component {
  constructor() {
    super()
    this.state = { enabled: true }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.enabled !== state.enabled) {
      return {
        enabled: props.enabled,
      }
    }
    return null
  }

  renderPosition = () => {
    switch (this.props.position) {
      case 'top':
        return 'w-32 text-center toptooltip'
      case 'bottom':
        return 'w-64 bottomtooltip'
      case 'right':
        return ' top-0  ml-12  tooltip'
      case 'center':
        return ' top-0 tooltip'
      default:
        break
    }
    return null
  }

  render() {
    return (
      <div className={`${this.props.className} tooltip-container relative`}>
        {this.props.children}
        {this.state.enabled ? (
          <div
            className={` ${this.renderPosition()}  group-hover:block   hidden   z-40  absolute   bg-white   mt-2     ${
              this.props.big ? '' : 'whitespace-nowrap'
            }`}
          >
            <span>{this.props.title}</span>
          </div>
        ) : null}
      </div>
    )
  }
}
