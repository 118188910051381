/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import FunctionIcon from 'components/CustomKepler/common/icons/FunctionIcon'
import FetchData from 'components/FetchData/FetchData'
import FunctionData from 'components/FunctionData/FunctionData'
import { SidePanelFactory } from 'kepler.gl/components'
import {
  ADD_DATA_ID,
  ADD_MAP_STYLE_ID,
  EXPORT_DATA_ID,
  EXPORT_IMAGE_ID,
  EXPORT_MAP_ID,
  OVERWRITE_MAP_ID,
  SAVE_MAP_ID,
  SHARE_MAP_ID,
  // eslint-disable-next-line prettier/prettier, comma-dangle
  SIDEBAR_PANELS,
} from 'kepler.gl/dist/constants/default-settings'
import get from 'lodash.get'
import { useCallback, useMemo, useState } from 'react'

import styled from 'styled-components'
import { FormattedMessage } from 'translations'

export const StyledSidePanelContent = styled.div`
  ${(props) => props.theme.sidePanelScrollBar};
  flex-grow: 1;
  padding: ${(props) => props.theme.sidePanelInnerPadding}px;
  overflow-y: scroll;
  overflow-x: hidden;

  .side-panel__content__inner {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
`

CustomSidePanelFactory.deps = SidePanelFactory.deps

/**
 * Vertical sidebar containing input components for the rendering layers
 */
export default function CustomSidePanelFactory(
  Sidebar,
  PanelHeader,
  PanelToggle,
  PanelTitle,
  LayerManager,
  FilterManager,
  InteractionManager,
  MapManager,
  CustomPanels,
) {
  // inject components
  const SIDEBAR_COMPONENTS = {
    layer: LayerManager,
    filter: FilterManager,
    interaction: InteractionManager,
    map: MapManager,
  }

  // We should defined sidebar panels here but keeping them for backward compatible
  const fullPanels = SIDEBAR_PANELS.map((component) => ({
    ...component,
    component: SIDEBAR_COMPONENTS[component.id],
  }))

  const getCustomPanelProps = get(CustomPanels, ['defaultProps', 'getProps']) || (() => ({}))

  /** @type {typeof import('./side-panel').SidePanel} */
  // eslint-disable-next-line max-statements
  const SidePanel = (props) => {
    const {
      appName,
      appWebsite,
      availableProviders,
      datasets,
      filters,
      layers,
      layerBlending,
      layerClasses,
      layerOrder,
      interactionConfig,
      panels,
      mapInfo,
      mapSaved,
      mapStateActions,
      mapStyle,
      mapStyleActions,
      onSaveMap,
      uiState,
      uiStateActions,
      visStateActions,
      version,
      width,
    } = props
    const { openDeleteModal, toggleModal, toggleSidePanel } = uiStateActions
    const { activeSidePanel } = uiState
    const { setMapInfo, showDatasetTable, updateTableColor } = visStateActions
    const { hasShare, hasStorage } = availableProviders
    const [customPanels, setCustomPanels] = useState(null)
    const { title } = mapInfo

    const onClickExportImage = useCallback(() => toggleModal(EXPORT_IMAGE_ID), [toggleModal])
    const onClickExportData = useCallback(() => toggleModal(EXPORT_DATA_ID), [toggleModal])
    const onClickExportMap = useCallback(() => toggleModal(EXPORT_MAP_ID), [toggleModal])
    const onClickSaveToStorage = useCallback(
      () => toggleModal(mapSaved ? OVERWRITE_MAP_ID : SAVE_MAP_ID),
      [mapSaved, toggleModal],
    )
    const onClickSaveAsToStorage = useCallback(() => {
      setMapInfo({
        title: `${title || 'Kepler.gl'} (Copy)`,
      })

      toggleModal(SAVE_MAP_ID)
    }, [title, setMapInfo, toggleModal])
    const onClickShareMap = useCallback(() => toggleModal(SHARE_MAP_ID), [toggleModal])
    const onShowDatasetTable = useCallback((dataId) => showDatasetTable(dataId), [showDatasetTable])
    const onUpdateTableColor = useCallback((dataId, newColor) => updateTableColor(dataId, newColor), [updateTableColor])
    const onShowAddDataModal = useCallback(() => toggleModal(ADD_DATA_ID), [toggleModal])
    const onShowAddMapStyleModal = useCallback(() => toggleModal(ADD_MAP_STYLE_ID), [toggleModal])
    const onRemoveDataset = useCallback((dataId) => openDeleteModal(dataId), [openDeleteModal])
    const onToggleSidePanel = useCallback((dataId) => toggleSidePanel(dataId), [activeSidePanel, toggleSidePanel])
    const onSaveToStorage = useMemo(
      () => (hasStorage ? onClickSaveToStorage : null),
      [hasStorage, onClickSaveToStorage],
    )
    const onSaveAsToStorage = useMemo(
      () => (hasStorage && mapSaved ? onClickSaveAsToStorage : null),
      [hasStorage, mapSaved, onClickSaveAsToStorage],
    )

    const newPanels = [
      ...panels,
      {
        id: 'functions',
        label: 'Functions',
        iconComponent: FunctionIcon,
        onClick: () => {
          onToggleSidePanel('functions')
        },
      },
    ]

    const currentPanel = useMemo(
      () => newPanels.find(({ id }) => id === activeSidePanel) || {},
      [activeSidePanel, newPanels],
    )
    const onShareMap = useMemo(() => (hasShare ? onClickShareMap : null), [hasShare, onClickShareMap])
    const customPanelProps = useMemo(() => getCustomPanelProps(props), [props])

    const PanelComponent = currentPanel.component

    return (
      <Sidebar width={width} minifiedWidth={0}>
        <PanelHeader
          appName={appName}
          version={version}
          appWebsite={appWebsite}
          visibleDropdown={uiState.visibleDropdown}
          showExportDropdown={uiStateActions.showExportDropdown}
          hideExportDropdown={uiStateActions.hideExportDropdown}
          onExportImage={onClickExportImage}
          onExportData={onClickExportData}
          onExportMap={onClickExportMap}
          onSaveMap={onSaveMap}
          onSaveToStorage={onSaveToStorage}
          onSaveAsToStorage={onSaveAsToStorage}
          onShareMap={onShareMap}
        />
        {/* the next two components should be moved into one */}
        {/* but i am keeping them because of backward compatibility */}
        <PanelToggle panels={newPanels} activePanel={activeSidePanel} togglePanel={uiStateActions.toggleSidePanel} />
        <StyledSidePanelContent className='relative side-panel__content'>
          <div className='side-panel__content__inner'>
            <PanelTitle className='side-panel__content__title'>
              <FormattedMessage id={currentPanel.label} />
            </PanelTitle>
            {PanelComponent && customPanels === null ? (
              <PanelComponent
                datasets={datasets}
                filters={filters}
                layers={layers}
                layerClasses={layerClasses}
                layerOrder={layerOrder}
                layerBlending={layerBlending}
                mapStyle={mapStyle}
                mapStyleActions={mapStyleActions}
                mapStateActions={mapStateActions}
                interactionConfig={interactionConfig}
                removeDataset={onRemoveDataset}
                showDatasetTable={onShowDatasetTable}
                updateTableColor={onUpdateTableColor}
                showAddDataModal={onShowAddDataModal}
                showAddMapStyleModal={onShowAddMapStyleModal}
                uiStateActions={uiStateActions}
                visStateActions={visStateActions}
              />
            ) : null}

            {activeSidePanel === 'functions' && <FunctionData />}
            <CustomPanels {...customPanelProps} activeSidePanel={activeSidePanel} />
          </div>
        </StyledSidePanelContent>
        <FetchData />
      </Sidebar>
    )
  }

  SidePanel.defaultProps = {
    panels: fullPanels,
    sidebarComponents: SIDEBAR_COMPONENTS,
    uiState: {},
    visStateActions: {},
    mapStyleActions: {},
    uiStateActions: {},
    availableProviders: {},
    mapInfo: {},
  }

  return SidePanel
}

export const replaceSidePanel = () => {
  return [SidePanelFactory, CustomSidePanelFactory]
}
