import { Component } from 'react'

class Button extends Component {
  constructor() {
    super()
    this.state = { disable: false, loading: false }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.disable !== nextProps.disable || prevState.loading !== nextProps.loading) {
      return {
        disable: nextProps.disable,
        loading: nextProps.loading,
      }
    }
    return null
  }

  render() {
    return (
      <button
        disabled={this.state.disable}
        onClick={() => {
          if (this.state.loading) {
            return false
          }
          return this.props?.action()
        }}
        // eslint-disable-next-line react/button-has-type
        type={this.props.type || 'button'}
        className={`${this.state.disable && 'isDisabled'}  ${!this.props.width ? 'w-32' : this.props.width}     ${
          !this.props.single ? 'mr-5' : 'mr-0'
        }  ${
          this.props.secondary
            ? 'text-gray-700 bg-white hover:text-gray-900 active:text-gray-800 active:bg-gray-100'
            : `text-white  ${this.props?.color ? `bg-${this.props?.color}-500` : 'bg-primarycolor'} ${
                this.props?.color ? `hover:bg-${this.props?.color}-700` : 'hover:bg-hovercolor'
              }  focus:outline-none focus:border-primary  focus:shadow-outline-${this.props?.color || 'green'}  `
        } px-4 py-2  justify-center items-center flex whitespace-nowrap text-xs leading-5 font-medium rounded-sm   focus:outline-none  focus:shadow-outline-blue  transition ease-in-out duration-150 relative ${
          this.props.className
        }`}
      >
        {this.state.loading ? (
          <>
            <img src='/assets/images/btnloading.gif' alt='loading' className='w-4 -ml-1 mr-1 ' />
            {this.props.loadingText || 'Saving ...'}
          </>
        ) : (
          this.props.children
        )}
      </button>
    )
  }
}

export default Button
