/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import DropList from 'components/Portions/DropList'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import {
  cleanupExportImage,
  setExportDataType,
  setExportFiltered,
  setExportImageSetting,
  // eslint-disable-next-line prettier/prettier
  setExportSelectedDataset,
} from 'kepler.gl/actions'
import { applyCPUFilter, setMapInfo } from 'kepler.gl/dist/actions/vis-state-actions'
import { formatCsv } from 'kepler.gl/dist/processors/data-processor'
import { createIndexedDataContainer } from 'kepler.gl/dist/utils/table-utils/data-container-utils'
import KeplerGlSchema from 'kepler.gl/schemas'
import { Component } from 'react'
import { connect } from 'react-redux'
import { setErrorHandler } from 'store/actions/errorsActions'
import { setSession } from 'store/actions/viewActions'

class ExportData extends Component {
  constructor(props) {
    super(props)

    this.state = {
      item: { type: 'csv', filter: false },
      errors: {},
      datasets: [],
      modalLoading: false,
    }
  }

  componentDidMount() {
    const toCPUFilter = this.props.selectedDataset || Object.keys(this.props.datasets)
    this.props.applyCPUFilter(toCPUFilter)
  }

  onSelectDataset = ({ target: { value } }) => {
    this.props.applyCPUFilter(value)
    this.props.onChangeExportSelectedDataset(value)
  }

  isMSEdge = (window) => {
    return Boolean(window.navigator && window.navigator.msSaveOrOpenBlob)
  }

  downloadFile = (fileBlob, fileName) => {
    if (this.isMSEdge(window)) {
      window.navigator.msSaveOrOpenBlob(fileBlob, fileName)
    } else {
      const url = URL.createObjectURL(fileBlob)

      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }

  exportData = (state, option) => {
    const { visState, appName } = state
    const { datasets } = visState
    const { selectedDataset, dataType, filtered } = option
    // get the selected data
    const filename = appName
    const selectedDatasets = datasets[selectedDataset] ? [datasets[selectedDataset]] : Object.values(datasets)
    if (!selectedDatasets.length) {
      // error: selected dataset not found.
      return
    }

    selectedDatasets.forEach((selectedData) => {
      const { dataContainer, fields, label, filteredIdxCPU = [] } = selectedData
      const toExport = filtered ? createIndexedDataContainer(dataContainer, filteredIdxCPU) : dataContainer

      // start to export data according to selected data type
      switch (dataType) {
        case 'CSV': {
          const csv = formatCsv(toExport, fields)

          const fileBlob = new Blob([csv], { type: 'text/csv' })
          this.downloadFile(fileBlob, `${filename}_${label}.csv`)
          break
        }
        case 'JSON': {
          break
        }
        // TODO: support more file types.
        default:
          break
      }
    })
  }

  onExportData = () => {
    const { selectedDataset, dataType, filtered } = this.props
    this.exportData(this.props.appState, { selectedDataset, dataType, filtered })
  }

  render() {
    const { selectedDataset, dataType, filtered, onChangeExportDataType, onChangeExportFiltered } = this.props
    const datasets = KeplerGlSchema.getDatasetToSave(this.props.keplerInstance)

    return (
      <ModalContainer close={this.props.close} className='w-smallModal px-6 py-3' title='Export Data'>
        <Modal
          loading={this.state.modalLoading}
          disable={!selectedDataset || selectedDataset === 'Select dataset'}
          height={350}
          action={{ text: 'Export', handler: this.onExportData }}
          cancel={{ text: 'Cancel', handler: this.props.close }}
        >
          <Form className='mt-8 '>
            <FormGroup className='grid grid-cols-12' horizontal>
              <div className='flex col-span-6 flex-col items-start justify-start'>
                <Label htmlFor='dataset'>Dataset :</Label>
                <Text className='text-xxs text-gray-500'>Choose the datasets you want to export</Text>
              </div>
              <DropList
                className='col-span-6'
                options={datasets.map((x) => {
                  return { value: x.data.id, text: x.data.label }
                })}
                name='dataset'
                onChange={this.onSelectDataset}
                placeholder='Select dataset'
                id='dataset'
                value={selectedDataset}
              />
              <InputError>{this.state.errors.dataset}</InputError>
            </FormGroup>
            <FormGroup horizontal className='grid grid-cols-12 pt-8'>
              <div className='flex col-span-6 flex-col items-start justify-start'>
                <Label htmlFor='type'>Data Type :</Label>
                <Text className='text-xxs text-gray-500'>Choose the type of data you want to export</Text>
              </div>
              <div className='col-span-6 flex justify-start items-center gap-2'>
                {['CSV'].map((x) => {
                  return (
                    <div
                      onClick={() => {
                        onChangeExportDataType(x)
                      }}
                      key={x}
                      className={` ${
                        dataType === x ? 'border-primarycolor bg-gray-100' : 'border-transparent'
                      } px-2 py-2 border-2  rounded-sm cursor-pointer `}
                    >
                      <img src={`/assets/images/${x.toLowerCase()}.png`} className='w-14 h-14' alt={`${x}`} />
                    </div>
                  )
                })}
              </div>
            </FormGroup>
            <FormGroup horizontal className='grid grid-cols-12 pt-8 '>
              <div className='flex col-span-6 flex-col items-start justify-start'>
                <Label htmlFor='type'>Filter Data :</Label>
                <Text className='text-xxs text-gray-500'>You can choose exporting original data or filtered data</Text>
              </div>
              <div className='col-span-6 flex justify-start items-center gap-2'>
                {[true, false].map((x) => {
                  return (
                    <div
                      onClick={() => {
                        onChangeExportFiltered(x)
                      }}
                      key={x}
                      className={` ${
                        filtered === x ? 'border-primarycolor' : 'border-transparent'
                      } px-2 py-2 border-2  rounded-sm cursor-pointer `}
                    >
                      {x ? 'Filtered data' : 'Unfiltered Data'}
                    </div>
                  )
                })}
              </div>
              <div className='col-span-8 flex justify-start items-center gap-2' />
            </FormGroup>
          </Form>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  keplerInstance: state.keplerGl?.MapLab,
  exportImage: state.keplerGl?.MapLab.uiState.exportImage,
  selectedDataset: state.keplerGl?.MapLab.uiState.exportData.selectedDataset,
  dataType: state.keplerGl?.MapLab.uiState.exportData.dataType,
  filtered: state.keplerGl?.MapLab.uiState.exportData.filtered,
  datasets: state.app.view.datasets,
  session: state.app.view.session,
  appState: state.keplerGl?.MapLab,
})

const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  setSession: (data) => dispatch(setSession(data)),
  setMapInfo: (data) => dispatch(setMapInfo(data)),
  applyCPUFilter: (data) => dispatch(applyCPUFilter(data)),
  onUpdateImageSetting: (data) => dispatch(setExportImageSetting(data)),
  cleanupExportImage: () => dispatch(cleanupExportImage()),
  onChangeExportFiltered: (data) => dispatch(setExportFiltered(data)),
  onChangeExportSelectedDataset: (data) => dispatch(setExportSelectedDataset(data)),
  onChangeExportDataType: (data) => dispatch(setExportDataType(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportData)
