import Input from 'components/Portions/Input'
import { CloseIcon, SearchIcon } from 'components/images'
import styled from 'styled-components'

const SearchWrapper = styled.div`
  max-width: 664px;
  display: flex;
  margin-block: 10px;
`

const Search = ({ onSearchChange, search }) => {
  const showDeleteButton = search && search !== ''

  const clearSearch = () => {
    onSearchChange({ target: { value: '' } })
  }

  return (
    <SearchWrapper className='border-1'>
      <div className='bg-white pt-3 pl-3'>
        <SearchIcon />
      </div>
      <Input
        name='search'
        id='search'
        value={search}
        onChange={onSearchChange}
        placeholder='Search in maps'
        className='w-full px-2 py-3 text-gray-800 text-md border-gray-500 rounded-sm focus:border-focused focus:shadow-input focus:outline-none placeholder-gray-700'
      />
      {showDeleteButton && (
        <div className='bg-white pt-3 pr-3 hover:cursor-pointer' onClick={clearSearch}>
          <CloseIcon />
        </div>
      )}
    </SearchWrapper>
  )
}

export default Search
