import Button from 'components/Portions/Button'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import PasswordChecker from 'components/Portions/PasswordChecker'
import { Component } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import { passwordChange } from 'services/user'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'
import passwordValidation from 'utils/passwordValidation'

// eslint-disable-next-line react/prefer-stateless-function
class ChangePasswordForm extends Component {
  constructor() {
    super()
    this.state = {
      item: {},
      errors: {},
      modalLoading: false,
      disableSaveButton: true,
      passwordsNotSame: false,
    }
  }

  componentDidMount() {}

  handelChange = (e) => {
    const { item, errors, passwordsNotSame } = this.state
    const { name } = e.target
    item[name] = e.target.value

    if (name === 'new_password' || name === 'confirmPassword') {
      const { pass, ...restResponse } = passwordValidation(e.target.value)
      if (!pass) {
        errors[name] = {
          message: 'Password must contain at least:',
          ...restResponse,
        }
      } else {
        errors[name] = {
          message: ' ',
          ...restResponse,
        }
        setTimeout(() => {
          errors[name] = null
          this.setState({ item, errors })
        }, 1800)
      }
      this.setState({
        disableSaveButton:
          !pass ||
          item?.confirmPassword !== item?.new_password ||
          !item.old_password ||
          item?.old_password?.length === 0,
        passwordsNotSame: item?.confirmPassword !== item?.new_password,
        item,
        errors,
      })
      return
    }
    errors[name] = null

    this.setState({
      item,
      errors,
      disableSaveButton:
        !item?.confirmPassword ||
        !item?.new_password ||
        passwordsNotSame ||
        !item.old_password ||
        item?.old_password?.length === 0,
    })
  }

  save = () => {
    const { errors } = this.state
    if (this.state.item?.new_password !== this.state.item?.confirmPassword) {
      this.setState({ errors: { ...errors, confirmPassword: 'please, make sure your password match' } })
    } else {
      this.setState({ modalLoading: true })

      passwordChange(this.state.item)
        .then((res) => {
          this.props.close()
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: 'Password changed successfully',
          })
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    }
  }

  render() {
    return (
      <ScrollArea>
        <p className='text-lg'>Change Password</p>
        <Form>
          <FormGroup>
            <Label htmlFor='oldPassword'>Old password:</Label>
            <Input
              onChange={this.handelChange}
              type='password'
              id='oldPassword'
              name='old_password'
              placeholder='Enter your old password'
              value={this.state.item.old_password}
            />
            <InputError>{this.state.errors.old_password}</InputError>
          </FormGroup>
          <FormGroup>
            <Label htmlFor='newPassword'>New password:</Label>
            <Input
              onChange={this.handelChange}
              type='password'
              id='newPassword'
              name='new_password'
              placeholder='Enter your new password'
              value={this.state.item.new_password}
            />
            <InputError>{this.state.errors.new_password?.message}</InputError>
            {this.state.errors.new_password?.message && (
              <div className='flex'>
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.new_password?.length}
                  label='At least 8 Characters'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.new_password?.lowerCase}
                  label='Lower case'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.new_password?.upperCase}
                  label='Upper case'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.new_password?.number}
                  label='Number'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.new_password?.symbole}
                  label='Special Character'
                />
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label htmlFor='confirmPassword'>Confirm password:</Label>
            <Input
              onChange={this.handelChange}
              type='password'
              id='confirmPassword'
              name='confirmPassword'
              placeholder='Confirm your new password'
              value={this.state.item.confirmPassword}
            />
            <InputError>{this.state.errors.confirmPassword?.message}</InputError>
            {this.state.errors.confirmPassword?.message && (
              <div className='flex'>
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.confirmPassword?.length}
                  label='At least 8 Characters'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.confirmPassword?.lowerCase}
                  label='Lower case'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.confirmPassword?.upperCase}
                  label='Upper case'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.confirmPassword?.number}
                  label='Number'
                />
                <PasswordChecker
                  styles={{ marginRight: '8px' }}
                  checker={this.state.errors.confirmPassword?.symbole}
                  label='Special Character'
                />
              </div>
            )}
            <InputError>
              {this.state.passwordsNotSame && this.state.item.confirmPassword && this.state.item.new_password
                ? 'Passwords are not the same'
                : ''}
            </InputError>
          </FormGroup>

          <div className=' mt-5 flex flex-row-reverse select-none'>
            <Button
              disable={this.state.disableSaveButton}
              loading={this.state.modalLoading}
              single
              action={this.save}
              className=''
            >
              Save
            </Button>

            <Button
              // className={`${this.props.loading && 'cursor-default'}`}
              action={this.props.close}
              className=''
              secondary
            >
              Cancel
            </Button>
          </div>
        </Form>
      </ScrollArea>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user.user,
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  showMessage: (data) => dispatch(showMessage(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
