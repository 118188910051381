import React, { Component } from 'react'

class Form extends Component {
  constructor() {
    super()
    this.state = {}
  }

  onSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit()
  }

  render() {
    return (
      <form onSubmit={this.onSubmit} className={`mb-4 w-full text-gray-600 text-sm ${this.props.className}`}>
        {this.props.children}
      </form>
    )
  }
}

export default Form
