const newState = {
  applicationError: false,
  serverError: false,
  ForbiddenError: false,
}

export const errorsReducer = (state = newState, action) => {
  switch (action.type) {
    case 'MESSAGE_HANDLER':
      switch (action.payload.messageType) {
        case 'SUCCESS':
          window.toast.success(action.payload.message)
          break
        case 'ERROR':
          window.toast.error(action.payload.message)
          break
        case 'WARN':
          window.toast.warn(action.payload.message)
          break
        case 'INFO':
          window.toast.info(action.payload.message)
          break
        default:
          break
      }
      break
    case 'ERROR_HANDLER':
      switch (action.payload?.status) {
        case 401:
          localStorage.clear()
          window.location = '/'
          break
        case 404:
        case 403:
          return { ...state, ForbiddenError: true }
        case 500:
          return { ...state, serverError: !state.serverError }
        case 400:
          switch (action.payload.data.errors.messageType) {
            case 'SUCCESS':
              window.toast.success(action.payload.data.message)
              break
            case 'ERROR':
              window.toast.error(action.payload.data.errors.message)
              break
            case 'WARN':
              window.toast.warn(action.payload.data.errors.message)
              break
            case 'INFO':
              window.toast.info(action.payload.data.errors.message)
              break
            default:
              break
          }
          break
        case null:
          return { ...state, serverError: false }
        default:
          break
      }
      break
    default:
      return state
  }
  return state
}
