import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

// eslint-disable-next-line react/prefer-stateless-function
class ResetMap extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return { loading: props.loading }
    }
    return null
  }

  restMap = () => {
    this.props.resetMap()
    browserHistory.push(`/maps`)
    this.props.close()
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Reset Map'>
        <Modal
          loading={this.state.loading}
          small
          action={{ color: 'red', text: 'Yes, Reset', handler: this.restMap }}
          cancel={{ text: 'No, Cancel', handler: () => this.props.close() }}
        >
          <Text className='my-3 text-lg'>Are you sure you want to reset this map?</Text>
          <Text className='my-6 text-xs pr-4'>
            {`Resetting the map would affect ${this.props.layers.length} layers and ${
              Object.keys(this.props.datasets).length
            } datasets.
            The view would be restored to the default application view.`}
          </Text>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  datasets: state.keplerGl.MapLab.visState.datasets,
  layers: state.keplerGl.MapLab.visState.layers,
  resetMap: state.app.view.resetMap,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ResetMap)
