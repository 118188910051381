import React, { Component } from 'react'

class Label extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <label
        htmlFor={this.props.htmlFor}
        className={this.props.className ? this.props.className : `mb-1  text-gray-800 select-none  text-xs`}
      >
        {this.props.children}
      </label>
    )
  }
}

export default Label
