/* eslint-disable react/prefer-stateless-function */

import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'

class UserHelp extends Component {
  constructor(props) {
    super(props)

    this.state = { modalLoading: false }
  }

  componentDidMount() {}

  render() {
    return (
      <ModalContainer close={this.props.close} className='w-largeModal py-3' title='User Help' description=''>
        <Modal loading={this.state.modalLoading} height={400}>
          <ol className='relative     mt-6'>
            <li className='mb-10'>
              <div className='px-2 overflow-hidden'>
                <div>
                  <h3 className='flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white'>
                    Tables of Cell Statistics Across Resolutions
                  </h3>
                </div>
                <div className={`  max-h-full   theight`}>
                  <>
                    <Text className='font-bold' variant='body2' component='b'>
                      Cell counts
                    </Text>
                    <table className='table-auto mt-5 w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                      <thead className='text-xs text-left text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                        <tr className='bg-white border-b text-left dark:bg-gray-800 dark:border-gray-700'>
                          <th className=' py-4 '>Res</th>
                          <th className=' py-4'>Total number of cells</th>
                          <th className=' py-4'>Number of hexagons</th>
                          <th className=' py-4'>Number of pentagons</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>0</td>
                          <td>122</td>
                          <td>110</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>1</td>
                          <td>842</td>
                          <td>830</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>2</td>
                          <td>5,882</td>
                          <td>5,870</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>3</td>
                          <td>41,162</td>
                          <td>41,150</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>4</td>
                          <td>288,122</td>
                          <td>288,110</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>5</td>
                          <td>2,016,842</td>
                          <td>2,016,830</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>6</td>
                          <td>14,117,882</td>
                          <td>14,117,870</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>7</td>
                          <td>98,825,162</td>
                          <td>98,825,150</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>8</td>
                          <td>691,776,122</td>
                          <td>691,776,110</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>9</td>
                          <td>4,842,432,842</td>
                          <td>4,842,432,830</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>10</td>
                          <td>33,897,029,882</td>
                          <td>33,897,029,870</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>11</td>
                          <td>237,279,209,162</td>
                          <td>237,279,209,150</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>12</td>
                          <td>1,660,954,464,122</td>
                          <td>1,660,954,464,110</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>13</td>
                          <td>11,626,681,248,842</td>
                          <td>11,626,681,248,830</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>14</td>
                          <td>81,386,768,741,882</td>
                          <td>81,386,768,741,870</td>
                          <td>12</td>
                        </tr>
                        <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                          <td>15</td>
                          <td>569,707,381,193,162</td>
                          <td>569,707,381,193,150</td>
                          <td>12</td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                </div>
              </div>
            </li>
          </ol>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user.user,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UserHelp)
