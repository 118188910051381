import polygonConfig from 'configs/polygon'

/**
 * MapBox custom style object
 * @typedef MapBoxStyle
 * @property {string} id unique id for custom style object
 * @property {string} label name of custom style
 * @property {string} url a mapbox url e.g mapbox://styles/myAccountInMapBox/ckl64o537391w17qh81pg46qv
 * @property {string} icon a url link for custom map icon e.g. https://storage.googleapis.com/test/images/x.png
 * @property {string[]} [layerGroups] a list layerGroups - NOT CLEAR what the use of this attribute
 */

/**
 * MapBox custom styles array
 * @type {MapBoxStyle[]}
 */
export const mapboxStyle = [
  {
    id: 'maplab-default-map',
    label: 'MapLab.world Default Map',
    url: 'mapbox://styles/alatoccam/ckymrtcb14ndk14l2n4r0bs1z',
    icon: 'https://api.mapbox.com/styles/v1/alatoccam/ckvaqt9ag2dnx14rns0owbaqn/static/-122.3391,37.7922,9,0,0/400x300?access_token=pk.eyJ1IjoiYWxhdG9jY2FtIiwiYSI6ImNqcXFmdXhzYzBiMWg0MnBmaXRtbmp0MDgifQ.lqaCHLpzviZEBjW-YjUvIg&logo=false&attribution=false',
    layerGroups: [],
  },
  // {
  //   id: 'maplab-number-of-transactions',
  //   label: 'Number of Transactions',
  //   url: 'mapbox://styles/alatoccam/ckl64o537391w17qh81pg46qv',
  //   icon: 'https://storage.googleapis.com/davinci-web-assets/images/number_of_transacitons.png',
  //   layerGroups: [],
  // },
  // {
  //   id: 'maplab-avg-price-per-sm',
  //   label: 'Average Price per Square Meter',
  //   url: 'mapbox://styles/alatoccam/ckl51g3bk270s17qh3y6hpjjw',
  //   icon: 'https://storage.googleapis.com/davinci-web-assets/images/av_price_per_sq_meter.png',
  //   layerGroups: [],
  // },

  // {
  //   id: 'maplab-avg-selling-price-on-transaction',
  //   label: 'Average Selling Price on Transaction',
  //   url: 'mapbox://styles/alatoccam/ckl64pat70h8k17s1k161qld2',
  //   icon: 'https://storage.googleapis.com/davinci-web-assets/images/av_selling_price_trans.png',
  //   layerGroups: [],
  // },
  // {
  //   id: 'maplab-navigation',
  //   label: 'Navigation',
  //   url: 'mapbox://styles/alatoccam/ckvaqt9ag2dnx14rns0owbaqn',
  //   token: 'pk.eyJ1IjoiYWxhdG9jY2FtIiwiYSI6ImNqcXFmdXhzYzBiMWg0MnBmaXRtbmp0MDgifQ.lqaCHLpzviZEBjW-YjUvIg',
  //   icon: 'https://api.mapbox.com/styles/v1/alatoccam/ckvaqt9ag2dnx14rns0owbaqn/static/-122.3391,37.7922,9,0,0/400x300?access_token=pk.eyJ1IjoiYWxhdG9jY2FtIiwiYSI6ImNqcXFmdXhzYzBiMWg0MnBmaXRtbmp0MDgifQ.lqaCHLpzviZEBjW-YjUvIg&logo=false&attribution=false',
  //   layerGroups: [],
  // },
]

/**
 * Get Map config for Points.
 * @param {?string} id
 * @returns A map configuration for the dataset that will be added in Kepler
 */
export function getMapConfig(id = null, label = null) {
  const settings = {
    ...polygonConfig,
    id,
    config: {
      // ...pointSettings.config,
      dataId: id,
      label,
    },
  }

  return {
    version: 'v1',
    config: {
      visState: {
        filters: [
          // CPU OVERLOAD IF WE ASSIGN IN EACH FETCH A FILTER
          // {
          //   dataId: ['points'],
          //   id: 'points',
          //   name: ['date'],
          //   type: 'timeRange',
          //   enlarged: true,
          //   plotType: 'histogram',
          //   animationWindow: 'free',
          //   yAxis: null,
          //   speed: 1,
          // },
        ],
        layers: [settings],
        interactionConfig: {
          // tooltip: {
          //   fieldsToShow: {
          //     // [`data-${id}`]: [
          //     //   'commune_name',
          //     //   'date',
          //     //   'street_number',
          //     //   'street',
          //     //   'surface',
          //     //   'nr_rooms',
          //     //   'price',
          //     //   'price_per_sm',
          //     // ],
          //   },
          //   enabled: true,
          //   compareMode: true,
          //   compareType: 'relative',
          // },
        },
        layerBlending: 'normal',
        // splitMaps: []
      },
      mapState: {
        bearing: 0,
        dragRotate: true,
        pitch: 0, // an to kaneis 50 energopoieis to 3d view
        isSplit: false,
      },
      // ENABLE 3D VIEW FOR POINTS
      //   ? {
      //       ...mapState,
      //       pitch: 50,
      //       dragRotate: true,
      //       bearing: 1
      //     }
      //   : mapState
    },
  }
}

// export const getExportedMapConfig = () => {
//   return {
//     version: 'v1',
//     config: {
//       visState: {
//         filters: [],
//         layers: [],
//         interactionConfig: {
//           tooltip: {
//             fieldsToShow: {
//               points: ['commune_name', 'date', 'street_number', 'street', 'surface', 'nr_rooms', 'price'],
//             },
//             compareMode: false,
//             compareType: 'absolute',
//             enabled: true,
//           },
//           brush: { size: 0.5, enabled: true },
//           geocoder: { enabled: true },
//           coordinate: { enabled: false },
//         },
//         layerBlending: 'normal',
//         splitMaps: [],
//         animationConfig: { currentTime: null, speed: 1 },
//       },
//       mapState: {
//         bearing: 0,
//         dragRotate: false,
//         latitude: 46.2276,
//         longitude: 2.2137,
//         pitch: 0,
//         zoom: 10,
//         isSplit: false,
//       },
//       mapStyle: {
//         styleType: 'occamlab-avg-price-per-sm',
//         topLayerGroups: { label: true },
//         visibleLayerGroups: {
//           label: true,
//           road: true,
//           border: false,
//           building: true,
//           water: true,
//           land: true,
//           '3d building': false,
//         },
//         threeDBuildingColor: [194.6103322548211, 191.81688250953655, 185.2988331038727],
//         mapStyles: mapboxStyle,
//       },
//     },
//   }
// }
