import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import React, { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class DeleteUserModal extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return { loading: props.loading }
    }
    return null
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Delete User'>
        <Modal loading={this.state.loading} small {...this.props}>
          <Text className='my-3 text-lg'>Are you sure you want to delete this user?</Text>
          <Text className='my-6 text-xs pr-4'>
            If you delete an existing user, the user’s access to the application would be restrained. This action cannot
            be undone, do you wish to continue?
          </Text>
        </Modal>
      </ModalContainer>
    )
  }
}
