import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'

// eslint-disable-next-line react/prefer-stateless-function
class DeleteFunction extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Delete function'>
        <Modal
          loading={this.state.loading}
          small
          action={{ color: 'red', text: 'Yes, Delete', handler: this.props.action }}
          cancel={{ text: 'No, Cancel', handler: this.props.close }}
        >
          <Text className='my-3 text-lg'>Are you sure you want to delete this function?</Text>

          <Text className='my-6 text-xs pr-4'>
            The data set and map layers generated from this function also will be deleted
          </Text>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFunction)
