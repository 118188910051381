/* eslint-disable react/prefer-stateless-function */
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'
import SqlEditor from 'react-sql-editor'
import { setErrorHandler } from 'store/actions/errorsActions'

class QueryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalLoading: false,
    }
  }

  setSqlQuery = (query) => {
    this.setState({ query })
  }

  save = () => {
    this.props.getQuery(this.state.query)
  }

  render() {
    return (
      <ModalContainer className='w-smallModal  py-3' title='SQL Query to Database'>
        <Modal
          loading={this.state.modalLoading}
          action={{ handler: this.save, text: 'Fetch Data', className: 'rounded-sm databaseButton' }}
          {...this.props}
        >
          <p className='my-1'>Sql Editor</p>
          <SqlEditor
            title='Sql Editor'
            width='auto'
            height='200px'
            isShowHeader
            value={this.state.query}
            enableBasicAutocompletion
            debounceChangePeriod={500}
            onChange={(e) => {
              this.setSqlQuery(e)
            }}
            validatorConfig={{
              maxSqlNum: 1,
            }}
          />
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(QueryModal)
