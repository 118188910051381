/* BASIC 6 COLORS FOR APP */
export const mainDarkestBlue = '#28323c'
export const mainDarkestBlueOpacity = 'rgba(40, 50, 60, .9)'
export const darkestBlue = '#242730'
export const darkestBlueOpacity = 'rgba(17, 35, 48, .8)'
export const darkerBlue = '#004D5D'
export const darkTurquoise = '#4CA99D'
export const green = '#0F9668'
export const greenHover = '#4ca99d'
export const lightGreen = '#8FD578'
export const yello = '#F9F871'
export const logoMapColor = '#57787c'
export const logoMapColorLight = '#b8c6c7'
export const logoLabColor = '#031825'

/* APP's GREY COLORS */
export const greyBlue = '#3a414c'
export const lightGrey = '#D9D9DA'
export const buttonGrey = '#6A7485'
export const shadow = 'rgba(0, 0, 0, 0.1)'
