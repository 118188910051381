/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react'

export default class CheckBox extends Component {
  constructor() {
    super()
    this.state = { checked: true, disable: false }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.checked !== nextProps.checked || prevState.disable !== nextProps.disable) {
      return {
        checked: nextProps.string ? nextProps.checked === nextProps.options[0] : nextProps.checked,
        disable: nextProps.disable,
      }
    }
    return null
  }

  handleChange = (e) => {
    if (this.state.disable) {
      return false
    }
    const target = { name: this.props.name }
    if (this.props.string) {
      target.value = !this.state.checked ? this.props.options[0] : this.props.options[1]
    } else {
      target.value = !this.state.checked
    }
    return this.props.onChange({ target })
  }

  render() {
    return (
      <svg
        onClick={this.handleChange}
        className={`icon ${this.state.disable && 'text-gray-400'} cursor-pointer icon-tabler icon-tabler-square-check`}
        width='24'
        height='24'
        viewBox='0 0 24 24'
        strokeWidth='2'
        stroke='currentColor'
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <rect x='4' y='4' width='16' height='16' rx='2' />
        {this.state.checked && <path d='M9 12l2 2l4 -4' />}
      </svg>
    )
  }
}
