import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class RefrechTableModal extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return { loading: props.loading }
    }
    return null
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Refresh Table'>
        <Modal loading={this.state.loading} small {...this.props}>
          <Text className='my-3 text-lg'>Are you sure you want to refresh the table metadata?</Text>
          <Text className='my-6 text-xs pr-4'>This action will update current information with new data.</Text>
        </Modal>
      </ModalContainer>
    )
  }
}
