import React, { Component } from 'react'

export default class InputError extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    if (this.props.children) {
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      return <label className={`mt-2 text-xs  text-red-500 ${this.props.className}`}>{this.props.children}</label>
    }
    return null
  }
}
