export function setSelectedPolygon(selectedPolygon) {
  return {
    type: 'SET_SELECTED_POLYGON',
    selectedPolygon,
  }
}

export function setPointsToLoad(pointsToLoad) {
  return {
    type: 'SET_POINTS_TO_LOAD',
    pointsToLoad,
  }
}

export function setPolygonsToLoad(polygonsToLoad) {
  return {
    type: 'SET_POLYGONS_TO_LOAD',
    polygonsToLoad,
  }
}

export function filterByPolygon(polygon) {
  return {
    type: 'FILTER_BY_POLYGON',
    polygon,
  }
}
