import axios from 'axios'
import { SETTINGS } from 'constants/default-settings'

export const baseURL = SETTINGS.BASE_URL

const mainService = axios.create({
  baseURL,
  headers: {
    Authorization: `Token ${
      localStorage.getItem('token') ? localStorage.getItem('token') : SETTINGS.PUBLIC_USER_TOKEN
    }`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

mainService.interceptors.request.use((config) => {
  /* let loadinBar = document.getElementById("loadinBar");
  let bar = loadinBar.querySelector("span");

  bar.classList.remove("w-3/4");
  bar.classList.add("w-full");

  setTimeout(() => {
    document.body.classList.remove("load");
  }, 500); */

  return config
})

mainService.interceptors.request.use((response) => {
  /* document.body.classList.add("load");

  let loadinBar = document.getElementById("loadinBar");
  let bar = loadinBar.querySelector("span");

  setTimeout(() => {
    bar.classList.add("w-1/4");
  }, 100);
  setTimeout(() => {
    bar.classList.remove("w-1/4");
    bar.classList.add("w-2/4");
  }, 800);
  setTimeout(() => {
    bar.classList.remove("w-2/4");
    bar.classList.add("w-3/4");
  }, 1500); */

  return response
})

export default mainService
