/* eslint-disable no-shadow */
import { UserIcon, Share } from 'components/images'
import ShareUrlModal from 'components/Management/Sessions/Modals/shareUrlModal'
import { mainDarkestBlue } from 'theme/colors'
import { useState } from 'react'

const MapCard = ({ map }) => {
  const [shareUrlModal, setShareUrlModal] = useState(false)
  const [modalLoading, setModalLoading] = useState(true)

  const toggleModal = (selected = null) => {
    // need to close modal
    if (!selected) {
      setShareUrlModal(false)
      return
    }

    // need to open modal
    if (selected.data) {
      // open model with data
      // to do later
    } else {
      // open model without data
      setModalLoading(false)
      setShareUrlModal(!shareUrlModal)
    }
  }

  const RedirectToMaps = (code) => () => {
    window.open(`${window.location.origin}/map/${code}`)
  }

  return (
    <div className='w-full sm:w-1/2 md:w-1/2 xl:w-1/4 p-4'>
      {shareUrlModal && (
        <ShareUrlModal
          public={map.public}
          code={map.code}
          loading={modalLoading}
          cancel={{ text: 'Close', handler: () => toggleModal() }}
        />
      )}
      <div className='c-card block bg-white shadow-md hover:shadow-xl rounded-lg overflow-hidden'>
        {
          // eslint-disable-next-line no-nested-ternary
          map.imageLoading ? (
            <div
              onClick={RedirectToMaps(map.code)}
              className='hover:cursor-pointer inset-0 h-48 w-48 object-cover flex  block mx-auto my-auto'
            >
              <img src='/assets/images/loading.gif' alt='map' className='w-16 h-16 block mx-auto my-auto' />
            </div>
          ) : map.image !== null ? (
            <div onClick={RedirectToMaps(map.code)} className='hover:cursor-pointer relative pb-48 overflow-hidden'>
              <img
                data-tip={`<img src=${map.image} class="absolute inset-0 h-full w-full object-cover" />`}
                data-html
                data-class='p-2'
                data-for='largeImage'
                src={map.image}
                alt='map'
                className='absolute inset-0 h-full w-full object-cover'
              />
            </div>
          ) : (
            <div
              onClick={RedirectToMaps(map.code)}
              className='hover:cursor-pointer relative pb-48 overflow-hidden object-cover'
            >
              <img className='absolute inset-0 m-auto' src='/assets/images/no-image.png' alt='map' />
            </div>
          )
        }

        <div className='p-4'>
          <span
            className={`inline-block px-2 py-1 leading-none border-1 ${
              map.public === 'true'
                ? 'bg-green-200 text-green-800 border-green-800'
                : 'bg-orange-200 text-orange-800 border-orange-800'
            } rounded-full font-semibold uppercase tracking-wide text-xs`}
          >
            {map.public === 'true' ? 'Public Map' : 'Private Map'}
          </span>
          <h2
            className='break-words hover:cursor-pointer mt-2 mb-0 text-2xl font-bold text-logoMapColor hover:text-gray-900'
            onClick={RedirectToMaps(map.code)}
          >
            {map.title}
          </h2>
          <span className='inline-block mr-2 text-xs text-gray-500'>Databases </span>
          <span
            className='shadow-md inline-block mr-2 mb-3 px-2 py-1 leading-none bg-gray-200 text-gray-500
             rounded-full font-normal lowercase tracking-wide text-xs'
          >
            {
              map.datasets.filter((dataset) => {
                return dataset.type === 'static'
              }).length
            }
            {` Static`}
          </span>
          <span
            className='shadow-md inline-block mr-2 mb-3 px-2 py-1 leading-none bg-gray-200 text-gray-500
             rounded-full font-normal lowercase tracking-wide text-xs'
          >
            {
              map.datasets.filter((dataset) => {
                return dataset.type !== 'static'
              }).length
            }
            {` Dynamic`}
          </span>
          <p className='break-words text-gray-700 text-sm max-h-desc min-h-desc overflow-auto'>
            {!map.description ? <span className='italic'>No available description... </span> : map.description}
          </p>
          <div className='mt-3 flex items-center' />
        </div>
        <div className='pt-2 pb-0 px-4 border-t border-b text-gray-700'>
          <span className='flex items-center mb-1'>
            <UserIcon color={mainDarkestBlue} />
            <span className='ml-1 text-md text-grey-600 font-medium'>{`${map.user?.first_name} ${map.user?.last_name}`}</span>
          </span>
        </div>
        <div className='p-4 flex text-sm text-gray-600'>
          <span className='ml-2 flex-initial w-1/2'>{new Date(map.created_at).toDateString()}</span>
          <div className='flex-initial w-1/2 flex justify-end'>
            <span
              className='ml-2 cursor-pointer'
              style={{ height: '24px', width: '24px' }}
              onClick={() => toggleModal({ data: false })}
            >
              <Share color={mainDarkestBlue} />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapCard
