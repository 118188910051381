import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class DeleteOrganisationModal extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return { loading: props.loading }
    }
    return null
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Delete Organization'>
        <Modal loading={this.state.loading} small {...this.props}>
          <Text className='my-3 text-lg'>Are you sure you want to delete this organisation?</Text>
          <Text className='my-6 text-xs pr-4'>Are you sure you want to delete this organization? </Text>
          <Text className='my-6 text-xs pr-4'>
            If you delete an organization, all users within it would be restrained from accessing the application. All
            existing maps would eventually be deleted. This action cannot be undone, do you wish to continue?
          </Text>
        </Modal>
      </ModalContainer>
    )
  }
}
