import React, { Component } from 'react'

class DropList extends Component {
  constructor() {
    super()
    this.state = { value: '' }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    return null
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  render() {
    return (
      <select
        name={this.props.name}
        id={this.props.id}
        value={this.state.value}
        placeholder={this.props.placeholder}
        className={`${
          this.props.className ? this.props.className : 'w-full px-3'
        }   py-1 border-1 text-gray-700 rounded-sm focus:border-focused focus:shadow-input focus:outline-none placeholder-gray-300 `}
        onBlur={() => {}}
        disabled={this.props.disabled}
        onChange={this.props.onChange}
      >
        {this.props.placeholder && <option value={null}>{this.props.placeholder}</option>}
        {this.props.options?.map((x) => {
          return (
            <option key={x.value} value={x.value}>
              {x.text}
            </option>
          )
        })}
      </select>
    )
  }
}

export default DropList
