/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { connect } from 'react-redux'

class ApplicationLoading extends React.Component {
  render() {
    return (
      <div>
        {this.props.applicationLoading && (
          <section className='items-center justify-center inset-0 flex fixed-position w-full h-full z-first bg-white overflow-hidden  '>
            <div className='relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
              <div className='relative'>
                <img src='/assets/images/logo-white.png' className='mx-auto w-40' alt='logo' />

                <blockquote className='mt-2'>
                  <div className='flex justify-start items-center gap-1 max-w-3xl mx-auto text-center  leading-9 font-sm text-gray-900'>
                    <img src='/assets/images/loading.gif' className='mx-auto w-4' alt='error' />

                    <p className='text-xs'>Loading, Please Wait...</p>
                  </div>
                </blockquote>
              </div>
            </div>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  applicationLoading: state.app.view.applicationLoading,
})
export default connect(mapStateToProps)(ApplicationLoading)
