// eslint-disable-next-line import/no-named-as-default
import CheckBox from 'components/Portions/CheckBox'
import ToolTip from 'components/Portions/ToolTip'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class OrganisationItem extends Component {
  constructor() {
    super()
    this.state = {
      opened: false,
      checked: false,
    }
  }

  toggleHandler = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ opened: !this.state.opened })
  }

  handleChecked = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ checked: !this.state.checked })
  }

  render() {
    const { item, index, items } = this.props
    return (
      <>
        <div
          className={`grid-item grid grid-cols-10 py-px px-1  ${index === 0 ? 'rounded-t-md' : 'border-t-0'} ${
            index === items.length - 1 && !this.state.opened && 'rounded-b-md'
          }`}
        >
          <div
            className='item-title col-span-9 flex justify-start items-center gap-4 cursor-pointer'
            onClick={this.toggleHandler}
          >
            <div className={`${this.state.opened && 'transform rotate-180 '} transition duration-150`}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <polyline points='6 15 12 9 18 15' />
              </svg>
            </div>

            <div className='item-details text-base'>
              <div className={`flex items-center justify-start gap-2 ${item.status === 'inactive' && 'disconnected'}`}>
                <p>{item.name}</p>
                <p className='text-xs'>{item.description}</p>

                {item.status === 'inactive' && (
                  <ToolTip enabled className='icon disconnected' title='Trial exipred'>
                    <svg
                      viewBox='0 0 24 24'
                      strokeWidth='2'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <rect x='5' y='11' width='14' height='10' rx='2' />
                      <circle cx='12' cy='16' r='1' />
                      <path d='M8 11v-4a4 4 0 0 1 8 0v4' />
                    </svg>
                  </ToolTip>
                )}
              </div>
              <p className='text-xs'>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <span>
                  {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                  {`${item.users?.length || 0} of ${item.max_users} users - ${item.max_maps} max maps`}
                </span>
                <span>
                  {`  - last update
                  ${item.updated_at}`}
                </span>
              </p>
            </div>
          </div>
          <div className='item-options col-span-1 flex justify-around items-center'>
            {this.props.user.type === 'systemAdministrator' && (
              <div
                className='icon cursor-pointer hover:text-primarycolor transition duration-150'
                onClick={() => {
                  this.props.toggleModal('deleteOrganisationModal', {
                    data: false,
                    service: 'organisations',
                    uuid: item.uuid,
                  })
                }}
              >
                <svg
                  viewBox='0 0 24 24'
                  strokeWidth='1'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <line x1='4' y1='7' x2='20' y2='7' />
                  <line x1='10' y1='11' x2='10' y2='17' />
                  <line x1='14' y1='11' x2='14' y2='17' />
                  <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                  <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                </svg>
              </div>
            )}
            {this.props.user.type === 'systemAdministrator' && (
              <div
                className='icon cursor-pointer hover:text-primarycolor transition duration-150'
                onClick={() => {
                  this.props.toggleModal('updateOrganisationModal', {
                    data: true,
                    service: 'organisations',
                    uuid: item.uuid,
                  })
                }}
              >
                <svg
                  viewBox='0 0 24 24'
                  strokeWidth='1'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
                  <circle cx='12' cy='12' r='3' />
                </svg>
              </div>
            )}
            <div
              onClick={() => {
                this.props.toggleModal('createUserModal', {
                  data: false,
                  service: 'users',
                  organization_id: item.id,
                  uuid: item.uuid,
                })
              }}
              className='icon cursor-pointer hover:text-primarycolor transition duration-150'
            >
              <svg
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <line x1='12' y1='5' x2='12' y2='19' />
                <line x1='5' y1='12' x2='19' y2='12' />
              </svg>
            </div>
          </div>
        </div>
        {this.state.opened && !item.locked
          ? item.users?.map((user, userIndex) => {
              return (
                <div
                  className={`grid-subItem grid grid-cols-10 py-px px-1 bg-white border-t-0 ${
                    index === items.length - 1 &&
                    this.state.opened &&
                    userIndex === item.users.length - 1 &&
                    'rounded-b-md'
                  }`}
                  key={user.id}
                >
                  <div className='item-title col-span-9 flex justify-start items-center gap-4'>
                    <CheckBox
                      name='status'
                      onChange={(e) => {
                        this.props.handelUserStatusChange(e, user.uuid)
                      }}
                      disable={item.status === 'inactive'}
                      checked={user.status}
                      options={['active', 'inactive']}
                      string
                    />
                    <div className='item-details text-sm'>
                      <p>{user.email}</p>
                      <p>
                        <span className='text-xs'>
                          {`last update 
                          ${user.updated_at}`}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='item-options col-span-1 flex justify-around items-center'>
                    <div
                      onClick={() => {
                        this.props.toggleModal('deleteUserModal', {
                          data: false,
                          service: 'users',
                          uuid: user.uuid,
                        })
                      }}
                      className='icon cursor-pointer hover:text-primarycolor transition duration-150'
                    >
                      <svg
                        viewBox='0 0 24 24'
                        strokeWidth='1'
                        stroke='currentColor'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <line x1='4' y1='7' x2='20' y2='7' />
                        <line x1='10' y1='11' x2='10' y2='17' />
                        <line x1='14' y1='11' x2='14' y2='17' />
                        <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                        <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                      </svg>
                    </div>

                    <div
                      onClick={() => {
                        this.props.toggleModal('updateUserModal', {
                          data: true,
                          service: 'users',
                          uuid: user.uuid,
                        })
                      }}
                      className='icon cursor-pointer hover:text-primarycolor transition duration-150'
                    >
                      <svg
                        viewBox='0 0 24 24'
                        strokeWidth='1'
                        stroke='currentColor'
                        fill='none'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      >
                        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                        <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
                        <circle cx='12' cy='12' r='3' />
                      </svg>
                    </div>
                    <div className='icon' />
                  </div>
                </div>
              )
            })
          : null}
      </>
    )
  }
}
