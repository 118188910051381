/* eslint-disable react-hooks/rules-of-hooks */

import React, { useCallback } from 'react'

import CustomMultiSelectFilterFactory from 'components/CustomKepler/filters/CustomMultiSelectFilter'
import FieldPanelWithFieldSelectFactory from 'kepler.gl/dist/components/filters/filter-panels/filter-panel-with-field-select'

CustomMultiSelectFilterPanelFactory.deps = [FieldPanelWithFieldSelectFactory, CustomMultiSelectFilterFactory]

function CustomMultiSelectFilterPanelFactory(FieldPanelWithFieldSelect, MultiSelectFilter) {
  /** @type {import('./filter-panel-types').FilterPanelComponent} */
  const MultiSelectFilterPanel = React.memo(
    ({
      idx,
      datasets,
      allAvailableFields,
      filter,
      isAnyFilterAnimating,
      enlargeFilter,
      setFilter,
      removeFilter,
      toggleAnimation,
    }) => {
      // Selecting option from the list
      const onInitializeFilter = useCallback(
        (value) => {
          // Value is always an array with all the selected options
          // If we are in normal mode
          if (!filter?.reverse) {
            setFilter(idx, 'value', value)
            return
          }
          // Else we are in reverse mode
          setFilter(idx, 'reverseValue', value)
          // Get opposite values array and assigned them in filter's 'value' key
          const oppositeValues = filter.domain.filter((val) => !value.find((itm) => itm === val))
          setFilter(idx, 'value', oppositeValues)
        },
        [idx, setFilter, filter?.reverse],
      )

      const onSetReverse = useCallback(
        (value) => {
          // If both are empty means that users just checked on the "include" or "exclude"
          // radio battons without any select.
          if (filter?.reverseValue?.length === 0 && filter?.value?.length === 0) {
            return
          }
          // Toggle reverse key
          setFilter(idx, 'reverse', !value)
          // If the value was false, means that with the above setFilter the reverse will become true
          if (!value) {
            // Keep the opposite from the selected values
            const oppositeValues = filter.domain.filter((val) => !filter.value.some((itm) => itm === val))
            // Keep actual selected values in another key('reverseValue') inside filter object
            setFilter(idx, 'reverseValue', filter?.value)
            // Update filter's 'value' key with the opposite values
            setFilter(idx, 'value', oppositeValues?.length === 0 ? ['dummy'] : oppositeValues)
            return
          }
          // Else, the value was true (we were in reverse mode) and will become false (normal mode), so alternate the assigned keys
          const normalValue = filter.reverseValue
          setFilter(idx, 'reverseValue', filter?.value)
          setFilter(idx, 'value', normalValue?.length === 0 ? [] : normalValue)
        },
        [idx, setFilter, filter.value, filter.reverseValue],
      )

      const onResetSelection = () => {
        // Reseting all 'reverse', 'value' & 'reverseValue' keys
        setFilter(idx, 'reverse', false)
        setFilter(idx, 'reverseValue', [])
        setFilter(idx, 'value', [])
      }

      return (
        <div className='multi-select-filter-panel'>
          <FieldPanelWithFieldSelect
            allAvailableFields={allAvailableFields}
            datasets={datasets}
            filter={filter}
            idx={idx}
            removeFilter={removeFilter}
            setFilter={setFilter}
          >
            {filter.type && !filter.enlarged && (
              <div className='filter-panel__filter'>
                <MultiSelectFilter
                  filter={filter}
                  idx={idx}
                  isAnyFilterAnimating={isAnyFilterAnimating}
                  toggleAnimation={toggleAnimation}
                  initFilter={onInitializeFilter}
                  setFilter={setFilter}
                  setReverse={onSetReverse}
                  resetSelection={onResetSelection}
                />
              </div>
            )}
          </FieldPanelWithFieldSelect>
        </div>
      )
    },
  )

  MultiSelectFilterPanel.displayName = 'MultiSelectFilterPanel'

  return MultiSelectFilterPanel
}

export default CustomMultiSelectFilterPanelFactory
