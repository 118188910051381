export const messages = {
  en: {
    'toolbar.fr': 'Français',
    'toolbar.hideUserMapsLoader': 'Hide saved maps',
    'toolbar.showUserMapsLoader': 'Show saved maps',
    'toolbar.showSavedPolygons': 'Saved polygons',
    'load.user.points': 'Load User Points',
    'load.user.polygons': 'Load User Polygons',
    'load.user.maps': 'Load User Maps',
    'bug.report.under.construction': 'Bug Report - under construction',
    'user.guide': 'User Guide',
    'panel.header.logout': 'Log out',
    'modal.button.resetMap': 'Reset',
    'modal.button.fetch': 'Fetch',
    'modal.title.savedMaps': 'Saved Maps',
    'modal.title.savedPolygons': 'Saved Polygons',
    'modal.iconInfo.description2': ' MapLab.world will automatically create a icon layer for you.',
    'modal.addStyle.publishSubtitle5': 'To use your added (private) style, paste your',
    'modal.addStyle.publishSubtitle7': 'here.',
    'modal.addStyle.publishSubtitle8':
      '*MapLab.world is a client-side application that stores all data locally on your machine/browser, and no information or map data is transmitted to any servers, unless you opt to save the map.',
    'modal.shareMap.shareDisclaimer':
      'MapLab.world will save your map data to your personal cloud storage, only people with the URL can access your map and data. ' +
      'You can edit/delete the data file in your cloud account anytime.',
    'modal.shareMap.gotoPage': 'Go to your MapLab.world {currentProvider} page',
    'modal.exportMap.json.configDisclaimer':
      'Map config will be included in the Json file. If you are using MapLab.world in your own app. You can copy this config and pass it to ',
    'modal.exportMap.json.selection':
      'Export current map data and config into a single Json file. You can later open the same map by uploading this file to MapLab.world.',
    'modal.loadStorageMap.goToPage': 'Go to your MapLab.world {displayName} page',
    'fileUploader.disclaimer':
      '*MapLab.world is a client-side application with no server backend. Data lives only on your machine/browser. ' +
      'No information or map data is sent to any server.',
    resetMap: 'Reset Map',
    'modal.addStyle.publishTitle':
      '2. If a Mapbox style URL was entered in step 1, please either publish the style on Mapbox or provide an access token (optional).',
    'modal.addStyle.pasteTitle': '1. Paste style URL',
    'modal.addStyle.pasteSubtitle0': 'Supported style URL formats:',
    'modal.addStyle.pasteSubtitle2': 'Mapbox style URL',
    'modal.addStyle.pasteSubtitle3': 'or JSON adhering to ',
    'modal.addStyle.pasteSubtitle4': 'Mapbox GL Style Specification.',
    'tooltip.showLegend': 'Show Legend',
  },
  pt: {
    'toolbar.fr': 'Français',
  },
  fr: {
    'load.user.points': "Charger les points d'utilisateur",
    'load.user.polygons': 'Charger les polygones utilisateur',
    'load.user.maps': 'Charger les cartes utilisateur',
    'panel.header.logout': 'Se déconnecter',
    'toolbar.fr': 'Français',
    'bug.report.under.construction': 'Rapport d’erreur',
    'user.guide': 'Guide d’utilisateur',
  },
}
