import React, { Component } from 'react'

class FormGroup extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <div
        className={` ${
          this.props.horizontal ? 'flex-row justify-start  gap-2 items-start ' : 'flex-col justify-start '
        } mt-2 flex ${this.props.className || ''}`}
      >
        {this.props.children}
      </div>
    )
  }
}

export default FormGroup
