/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { connect } from 'react-redux'

class ApplicationError extends React.Component {
  render() {
    return (
      <div>
        {this.props.applicationError && (
          <section className='items-center justify-center inset-0 flex fixed-position w-full h-full z-first bg-white overflow-hidden  '>
            <div className='relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8'>
              <div className='relative'>
                <img src='/assets/images/application-error.png' className='mx-auto ' alt='error' />
                <blockquote className='mt-8'>
                  <div className='max-w-3xl mx-auto text-center  leading-9 font-sm text-gray-900'>
                    <p className='font-sm font-bold'>System Error</p>
                    <p className='text-sm'>Application error please contact the system administrator</p>
                  </div>
                </blockquote>
              </div>
            </div>
          </section>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  applicationError: state.app.errors.applicationError,
})
export default connect(mapStateToProps)(ApplicationError)
