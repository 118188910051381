/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prefer-stateless-function */

import axios from 'axios'
import ShareUrlModal from 'components/Management/Sessions/Modals/shareUrlModal'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import RadioButton from 'components/Portions/RadioButton'
import Text from 'components/Portions/Text'
import TextArea from 'components/Portions/TextArea'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { cleanupExportImage, setExportImageSetting } from 'kepler.gl/actions'
import { setMapInfo } from 'kepler.gl/dist/actions/vis-state-actions'
import ImagePreview from 'kepler.gl/dist/components/common/image-preview'
import ImageModalContainer from 'kepler.gl/dist/components/modals/image-modal-container'
import { MAP_THUMBNAIL_DIMENSION } from 'kepler.gl/dist/constants/default-settings'
import KeplerGlSchema from 'kepler.gl/schemas'
import { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { datasetSave, datasetToken, datasetUpload } from 'services/dataset'
import { sessionSave } from 'services/session'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'
import { addDataset, setSession, updateDataset } from 'store/actions/viewActions'

class SaveSession extends Component {
  constructor(props) {
    super(props)

    this.state = {
      item: { private: 'false' },
      errors: {},
      datasets: [],
      shareUrlModal: false,
      modalLoading: false,
      overwrite: true,
      config: {},
    }
  }

  componentDidMount() {
    const datasets = KeplerGlSchema.getDatasetToSave(this.props.keplerInstance)

    this.setState({
      config: KeplerGlSchema.getConfigToSave(this.props.keplerInstance),
      datasets: datasets
        .filter((x) => {
          return !x.data?.label?.includes('geocoder_dataset')
        })
        .map((dataset) => {
          const find = this.props.datasets[dataset.data.id]

          if (!find) {
            return {
              save: 'static',
              type: 'static',
              info: { id: dataset.data.id, sid: undefined, label: dataset.data.label },
              data: dataset.data,
              request: {},
              respond: dataset.respond,
            }
          }
          return { ...find, data: dataset.data }
        }),
      item: this.props.session,
    })

    this.props.onUpdateImageSetting({
      mapW: 1200,
      mapH: 800,
      ratio: 2,
      legend: false,
    })
  }

  save = () => {
    // console.log('info : start saving map')
    this.setState({ modalLoading: true }, () => {
      const { datasets } = this.state

      const getTokens = datasets.map((dataset) => {
        if (
          // if is static file wiathout id
          ((!dataset.info.sid || dataset.info.sid === undefined) && dataset.type === 'static') ||
          // if save as static and data is not static
          (dataset.save === 'static' && dataset.type !== 'static')
        ) {
          console.log('info : set get token for static dataset')
          return datasetToken({
            filename: dataset.info.id,
            origin: `${window.location.protocol}//${window.location.host}`,
          })
        }
        return null
      })
      if (getTokens.filter((x) => x !== null).length !== 0) {
        axios
          .all(getTokens.filter((x) => x !== null))
          .then(
            axios.spread((...tokenResponses) => {
              if (tokenResponses.length >= 1) {
                console.log('event : static token recived')
                const uploadFiles = tokenResponses.map((tokenResponse) => {
                  const dataset = datasets.find((x) => x.info.id === tokenResponse.data.data.uuid)
                  console.log('info : start uploading datasets')
                  return datasetUpload(
                    tokenResponse.data.data.token,
                    tokenResponse.data.data.fileName,
                    this.createRawDataset(dataset),
                  )
                })
                axios
                  .all(uploadFiles)
                  .then(
                    axios.spread((...uploadResponses) => {
                      if (uploadResponses.length >= 1) {
                        console.log('event : datasets uploaded successfully')
                        this.setState(
                          {
                            datasets: datasets.map((x) => {
                              uploadResponses.forEach((y) => {
                                if (y.data.name === `${x.info.id}.json`) {
                                  x.file = { label: x.info.label, url: y.data.mediaLink }
                                  x.type = 'static'
                                }
                              })
                              return x
                            }),
                          },
                          this.saveDatasets,
                        )
                      }
                    }),
                  )
                  .catch((uploadErrors) => {
                    this.setState({ modalLoading: false })
                  })
              }
            }),
          )
          .catch((tokenErrors) => {
            console.log(tokenErrors)
            this.props.setErrorHandler(tokenErrors)
            this.setState({ modalLoading: false })
          })
      } else {
        this.saveDatasets()
      }
    })
  }

  createRawDataset = (dataset) => {
    console.log('event : update dataset data to be raw')
    const data = dataset.data.allData.map((y) => {
      const x = {}
      for (let index = 0; index < dataset.data.fields.length; index++) {
        x[dataset.data.fields[index].name] = y[index]
      }
      return x
    })
    return data
  }

  saveDatasets = () => {
    console.log('info : start saving datasets ')
    const { datasets } = this.state
    axios
      .all(
        datasets.map((dataset) => {
          return datasetSave({
            request: dataset.request || {},
            respond: dataset.respond || {},
            file: dataset.file || {},
            uuid: dataset.info.id,
            type: dataset.type,
            id: this.state.overwrite ? dataset.info.sid : null,
          })
        }),
      )
      .then(
        axios.spread((...datasetResponses) => {
          console.log('event : datasets saved successfully')
          datasetResponses.map(async (datasetResponse) => {
            if (datasetResponse.data.data.type === 'static') {
              const dataset = {
                request: {},
                file: datasetResponse.data.data.file,
                type: 'static',
                save: 'static',
                respond: {
                  queryType: 'static',
                  pages: 1,
                  page: 1,
                  uuid: datasetResponse.data.data.uuid,
                  id: datasetResponse.data.data.id,
                  dataType: 'ROW',
                },
                info: {
                  sid: datasetResponse.data.data.id,
                  id: datasetResponse.data.data.uuid,
                  label: datasetResponse.data.data.file.label,
                },
                loading: false,
              }
              await this.props.addDataset({ [datasetResponse.data.data.uuid]: dataset })
            } else if (datasetResponse.data.data.type !== 'function') {
              await this.props.addDataset({
                [datasetResponse.data.data.uuid]: {
                  ...this.props.datasets[datasetResponse.data.data.uuid],
                  info: {
                    ...this.props.datasets[datasetResponse.data.data.uuid].info,
                    sid: datasetResponse.data.data.id,
                  },
                },
              })
            }
          })

          this.saveMapSession(datasetResponses)
        }),
      )
      .catch((dataseterrors) => {
        console.log(dataseterrors)
        this.props.setErrorHandler(dataseterrors)
        this.setState({ modalLoading: false })
      })
  }

  saveMapSession = (datasets) => {
    console.log('info : start session saving')
    const { item } = this.state
    if (!this.state.overwrite) {
      delete item.uuid
      delete item.id
      delete item.code
    }
    const { config } = this.state
    config.config.visState.filters = config.config.visState.filters
      .filter((x) => {
        return x.dataId?.length > 0
      })
      .map((filter) => {
        const founded = this.props.filters.find((visFilter) => visFilter.id === filter.id)
        if (
          founded?.type === 'multiSelect' &&
          founded?.hasOwnProperty('reverse') &&
          founded?.hasOwnProperty('reverseValue')
        ) {
          return { ...filter, reverse: founded.reverse, reverseValue: founded.reverseValue }
        }
        return filter
      })
    sessionSave({
      ...item,
      datasets: datasets.map((dataset) => {
        return { uuid: dataset.data.data.uuid, id: dataset.data.data.id }
      }),
      image: this.props.exportImage.imageDataUri,
      config: { ...config, mapControls: this.props.mapControls },
    })
      .then((respond) => {
        console.log('event : session saved successfully')
        this.setState({ item: respond.data, shareUrlModal: true, modalLoading: false }, () => {
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: 'You have successfully saved your map.',
          })
          if (respond.data.public === 'true') {
            this.props.setMapInfo({
              title: respond.data.title,
              description: respond.data.description,
            })
          }
          browserHistory.push(`/map/${respond.data.code}`)

          this.props.setSession(respond.data)
        })
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        switch (error.response.status) {
          case 422:
            {
              const temp = {}
              for (let index = 0; index < error.response.data.errors.length; index++) {
                const element = error.response.data.errors[index]
                // eslint-disable-next-line prefer-destructuring
                temp[element[0]] = element[1][0]
              }
              this.setState({ errors: temp })
            }
            break
          default:
            this.props.setErrorHandler(error.response)
            break
        }
      })
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value

    errors[e.target.name] = null

    this.setState({ item, errors })
  }

  handleOverwriteMap = (e) => {
    this.setState({ overwrite: e.target.value })
  }

  handelDataChange = (e) => {
    const { datasets } = this.state

    const dynamicDatasets = datasets.filter((dataset) => {
      return ['dynamic', 'query', 'function'].includes(dataset.type)
    })
    dynamicDatasets[e.target.value.index].save = e.target.value.save

    const staticDatasets = datasets.filter((dataset) => {
      return ['static'].includes(dataset.type)
    })

    this.setState({ datasets: [...dynamicDatasets, ...staticDatasets] })
  }

  render() {
    return (
      <>
        <ModalContainer close={this.props.close} className='w-largeModal py-3' title='Save Map'>
          <Modal
            loading={this.state.modalLoading}
            disable={this.state.datasets.length === 0}
            height={400}
            action={{ text: 'Save', handler: this.save }}
            cancel={{ text: 'Cancel', handler: this.props.close }}
          >
            <div className='my-4'>
              <p>Save the same configuration/view of your map.</p>
              <p>
                You can save your custom uploaded data (all data or part of it) by just ticking the desired selections.
              </p>
            </div>
            <div className='grid grid-cols-12'>
              <div className='col-span-6 px-16'>
                <ImageModalContainer
                  cleanupExportImage={this.props.cleanupExportImage}
                  onUpdateImageSetting={this.props.onUpdateImageSetting}
                >
                  <ImagePreview
                    exportImage={this.props.exportImage}
                    width={MAP_THUMBNAIL_DIMENSION.width}
                    showDimension={false}
                  />
                </ImageModalContainer>
              </div>
              <div className='col-span-6'>
                <Form>
                  <FormGroup>
                    <Label htmlFor='name'>Title*</Label>
                    <Input
                      onChange={this.handelChange}
                      id='title'
                      name='title'
                      placeholder='Type your map’s title here.'
                      value={this.state.item.title}
                    />
                    <InputError>{this.state.errors.title}</InputError>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor='description'>Description (optional)</Label>
                    <TextArea
                      onChange={this.handelChange}
                      id='description'
                      name='description'
                      placeholder='Type your map’s description here.'
                      value={this.state.item.description}
                    />
                    <InputError>{this.state.errors.description}</InputError>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor='public'>Public Availability</Label>
                    <Text className='text-gray-600 text-xs '>
                      Select whether the map is to be shared publicly or privately
                    </Text>
                    <div className='flex items-center gap-3 mt-2 '>
                      <RadioButton
                        name='public'
                        value='false'
                        label='Private'
                        checked={this.state.item.public === 'false'}
                        onChange={this.handelChange}
                      />
                      <RadioButton
                        value='true'
                        name='public'
                        label='Public'
                        checked={this.state.item.public === 'true'}
                        onChange={this.handelChange}
                      />
                    </div>
                    <InputError>{this.state.errors.public}</InputError>
                  </FormGroup>

                  {this.state.item.uuid && (
                    <FormGroup>
                      <Label htmlFor='private'>Overwrite the Existing Map?</Label>
                      <Text className='text-gray-600 text-xs '>
                        {this.state.overwrite
                          ? 'Overwrite Map - Your current settings will overwrite the existing Map'
                          : 'Save as New - Your current settings will be saved as a new Map'}
                      </Text>
                      <div className='flex items-center gap-3 mt-2 '>
                        <RadioButton
                          name='overwrite'
                          value
                          label='Yes'
                          checked={this.state.overwrite === true}
                          onChange={this.handleOverwriteMap}
                        />
                        <RadioButton
                          value={false}
                          name='overwrite'
                          label='No'
                          checked={this.state.overwrite === false}
                          onChange={this.handleOverwriteMap}
                        />
                      </div>
                      <InputError>{this.state.errors.private}</InputError>
                    </FormGroup>
                  )}

                  {this.state.datasets.length === 0 ? (
                    <FormGroup>
                      <Text className=' text-xs mt-4  text-red-600 '>
                        There are no available custom data added to the map.
                      </Text>
                    </FormGroup>
                  ) : (
                    <>
                      <FormGroup>
                        {this.state.datasets.filter((dataset) => dataset.type !== 'static').length === 0 ? (
                          <Label>All datasets are static files</Label>
                        ) : (
                          <Label htmlFor='data'>Select the type of the dataset’s saving availability</Label>
                        )}
                      </FormGroup>
                      {this.state.datasets
                        .filter((dataset) => dataset.type !== 'static')
                        .map((dataset, index) => {
                          return (
                            <FormGroup key={dataset.id}>
                              <Label htmlFor='private' className='text-primarycolor'>
                                {`Dataset: ${dataset.type} `}
                              </Label>
                              {dataset.type === 'static' ? (
                                <Text>{`static file ${dataset.info.label}`}</Text>
                              ) : (
                                <>
                                  <Text>
                                    {`dynamic dataset from  ${
                                      dataset.respond.database.label || dataset.respond.database.name
                                    } ${dataset.respond.table.name}`}
                                  </Text>
                                  <div className='flex items-center gap-3 mt-2 '>
                                    <RadioButton
                                      name='data'
                                      value={{ index, save: 'static' }}
                                      label='Static'
                                      checked={dataset.save === 'static'}
                                      onChange={this.handelDataChange}
                                    />
                                    <RadioButton
                                      value={{ index, save: 'dynamic' }}
                                      name='data'
                                      label='Dynamic'
                                      checked={dataset.save === 'dynamic'}
                                      onChange={this.handelDataChange}
                                    />
                                  </div>
                                </>
                              )}
                            </FormGroup>
                          )
                        })}
                    </>
                  )}
                </Form>
              </div>
            </div>
          </Modal>
        </ModalContainer>
        {this.state.shareUrlModal && (
          <ShareUrlModal
            public={this.state.item.public}
            code={this.state.item.code}
            cancel={{ text: 'Close', handler: this.props.close }}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  keplerInstance: state.keplerGl?.MapLab,
  exportImage: state.keplerGl?.MapLab.uiState.exportImage,
  datasets: state.app.view.datasets,
  session: state.app.view.session,
  toggleModal: state.app.view.toggleModal,
  filters: state.keplerGl?.MapLab.visState.filters,
  mapControls: {
    mapLegend: state.keplerGl?.MapLab.uiState.mapControls.mapLegend,
    visibleLayers: state.keplerGl?.MapLab.uiState.mapControls.visibleLayers,
  },
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  setSession: (data) => dispatch(setSession(data)),
  setMapInfo: (data) => dispatch(setMapInfo(data)),
  showMessage: (data) => dispatch(showMessage(data)),
  updateDataset: (data) => dispatch(updateDataset(data)),
  addDataset: (data) => dispatch(addDataset(data)),
  onUpdateImageSetting: (data) =>
    dispatch(setExportImageSetting({ ...data, mapH: window.screen.height, mapW: window.screen.width })),
  cleanupExportImage: () => dispatch(cleanupExportImage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveSession)
