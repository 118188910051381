import classnames from 'classnames'
import ActionPanel, { ActionPanelItem } from 'components/CustomKepler/common/action-panel'
import { showDatasetTable } from 'kepler.gl/actions'
import { FeatureActionPanelFactory, Icons } from 'kepler.gl/components'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { filterByPolygon } from 'store/actions/polygonActions'
// import { getPointsInPolygon, saveSelectedPointsAndPolygon } from 'store/services/polygonService'
import styled from 'styled-components'

const LAYOVER_OFFSET = 4

const StyledActionsLayer = styled.div`
  position: absolute;
`

const CustomFeatureActionPanel = () => {
  class FeatureActionPanel extends Component {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
      position: {},
    }

    // Used by onClickOutside
    handleClickOutside = (e) => {
      e.preventDefault()
      e.stopPropagation()
      // eslint-disable-next-line react/prop-types
      this.props.onClose()
    }

    // onSavePolygon = async (datasets, layers, selectedFeature) => {
    //   // save selected polygon points
    //   // eslint-disable-next-line react/prop-types
    //   const pointsInPoly = getPointsInPolygon(selectedFeature, layers, datasets)
    //   this.props.dispatch(saveSelectedPointsAndPolygon(selectedFeature, pointsInPoly))
    //   // get selected polygon
    // }

    onFilterByPolygon = async (selectedFeature, datasetId) => {
      this.props.dispatch(filterByPolygon(selectedFeature))
      this.props.dispatch(showDatasetTable(datasetId))
    }

    render() {
      // eslint-disable-next-line react/prop-types
      const { className, datasets, position, layers, currentFilter, onToggleLayer, onDeleteFeature, selectedFeature } =
        this.props

      const { layerId = [] } = currentFilter || {}

      return (
        <StyledActionsLayer
          className={classnames('feature-action-panel', className)}
          style={{
            top: `${position?.y + LAYOVER_OFFSET}px`,
            left: `${position?.x + LAYOVER_OFFSET}px`,
            zIndex: 10,
          }}
        >
          {layers?.filter((filLayer) => filLayer.type === 'point').length > 0 ? (
            <ActionPanel>
              <ActionPanelItem className='editor-layers-list' label='layers' Icon={Icons?.Layers}>
                {layers
                  .filter((filLayer) => filLayer.type === 'point')
                  .map((layer, index) => (
                    <ActionPanelItem
                      key={`${layer?.config?.label}_${layer?.id}`}
                      label={layer?.config?.label}
                      color={datasets[layer?.config?.dataId].color}
                      isSelection
                      isActive={layerId?.includes(layer.id)}
                      onClick={() => onToggleLayer(layer)}
                      className='layer-panel-item'
                    />
                  ))}
              </ActionPanelItem>
              <ActionPanelItem
                label='delete'
                className='delete-panel-item'
                Icon={Icons.Trash}
                onClick={onDeleteFeature}
              />

              {/* Save Polygon button will enable in the future
              <ActionPanelItem
                label='save'
                className='save-panel-item'
                Icon={Icons.Db}
                onClick={() => this.onSavePolygon(datasets, layers, selectedFeature)}
              /> */}

              <ActionPanelItem
                label='Inspect Data'
                className='filter-polygon-item'
                Icon={Icons.SquareSelect}
                onClick={() => this.onFilterByPolygon(selectedFeature, Object.keys(datasets)[0])}
              />
            </ActionPanel>
          ) : null}
        </StyledActionsLayer>
      )
    }
  }

  FeatureActionPanel.displayName = 'FeatureActionPanel'

  const mapStateToProps = (state) => ({
    polygons: state.app.polygons,
  })
  const mapDispatchToProps = (dispatch) => ({ dispatch })

  return connect(mapStateToProps, mapDispatchToProps)(FeatureActionPanel)
}

CustomFeatureActionPanel.deps = FeatureActionPanelFactory.deps

export function replaceFeatureActionPanel() {
  return [FeatureActionPanelFactory, CustomFeatureActionPanel]
}
