/* eslint-disable react/prefer-stateless-function */
import { BugReport, Help, UserIcon } from 'components/images'
import DropDown from 'components/Portions/DropDown'
import Icon from 'components/Portions/Icon'
import ToolTip from 'components/Portions/ToolTip'
import { SETTINGS } from 'constants/default-settings'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class ToolBar extends Component {
  toggle = (opened) => {}

  logout = () => {
    localStorage.clear()
    window.location = '/'
  }

  render() {
    return (
      <div className='flex flex-col fixed-position h-12 left-0 top-0'>
        <div
          className={`${
            window.location.pathname.includes('gallery')
              ? 'bg-primary'
              : 'cursor-pointer bg-primarycolor hover:bg-hovercolor'
          } w-12 h-12 p-1 flex justify-center items-center  z-20`}
          onClick={this.props.onOpenOrClose}
        >
          <svg
            className={`w-6 h-6 text-white transform ${
              this.props.isOpen && 'rotate-180'
            } transition-transform duration-150`}
            viewBox='0 0 24 24'
            strokeWidth='1'
            stroke='currentColor'
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <polyline points='9 6 15 12 9 18' />
          </svg>
        </div>

        <div className='bg-primary h-screen fixed-position top-0 left-0 pt-12 flex flex-col justify-between z-10'>
          <div>
            {this.props.options.map(({ key, title, icon: SvgIcon, link }) => {
              if (
                key !== 'manageOrganisations' ||
                (key === 'manageOrganisations' &&
                  (this.props.user.type === 'systemAdministrator' ||
                    this.props.user.type === 'organizationAdministrator'))
              ) {
                return (
                  <div
                    key={key}
                    className='w-12 h-12 flex justify-center items-center cursor-pointer text-gray-700 hover:text-white hover:bg-gray-500 transition duration-150 tooltip-container'
                    onClick={() => {
                      link ? window.open(`${window.location.origin}${link}`) : this.props.toggleModal(key)
                    }}
                  >
                    <ToolTip enabled title={title} position='top'>
                      <SvgIcon />
                    </ToolTip>
                  </div>
                )
              }
              return <></>
            })}
          </div>

          <div>
            <div
              onClick={() => {
                window.open(`${SETTINGS.REPORT_BUG}`)
              }}
              className='w-12 h-12 relative flex justify-center items-center  cursor-pointer text-gray-700 hover:text-white hover:bg-gray-500 transition duration-150 tooltip-container justify-self-end'
            >
              <ToolTip enabled title='Report a Bug' position='top'>
                <BugReport />
              </ToolTip>
            </div>
            <div
              onClick={() => {
                this.props.toggleModal('userGuide')
              }}
              className='w-12 h-12 relative flex justify-center items-center  cursor-pointer text-gray-700 hover:text-white hover:bg-gray-500 transition duration-150 tooltip-container justify-self-end'
            >
              <ToolTip enabled title='Quick User Guide' position='top'>
                <Help />
              </ToolTip>
            </div>

            <DropDown toggle={this.toggle} hideAfterClick>
              <div className='w-12 h-12 relative flex justify-center items-center  cursor-pointer text-gray-700 hover:text-white hover:bg-gray-500 transition duration-150 tooltip-container justify-self-end'>
                <ToolTip enabled title='Account' position='top'>
                  <UserIcon />
                </ToolTip>
              </div>
              <div className='absolute setting-modal bg-white max-w-xs w-48 left-15  bottom-2  rounded-sm overflow-hidden'>
                <div
                  onClick={() => {
                    this.props.toggleModal('settings')
                  }}
                  className='flex justify-start gap-2 items-center py-2 px-2 border-gray-400 border-b-1 hover:bg-gray-200 transition duration-150'
                >
                  <Icon className='w-5 h-5' stroke='2'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
                    <circle cx='12' cy='12' r='3' />
                  </Icon>
                  <span className='whitespace-no-wrap'>Settings</span>
                </div>
                <div
                  className='py-2 px-2 flex justify-start items-center gap-2 text-red-500 hover:bg-gray-200 transition duration-150'
                  onClick={this.logout}
                >
                  <Icon className='w-5 h-5' stroke='2'>
                    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                    <path d='M7 6a7.75 7.75 0 1 0 10 0' />
                    <line x1='12' y1='4' x2='12' y2='12' />
                  </Icon>
                  <span className='whitespace-no-wrap '>Sign Out</span>
                </div>
              </div>
            </DropDown>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  toggleModal: state.app.view.toggleModal,
  user: state.app.user.user,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ToolBar))
