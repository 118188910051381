/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
import { ModalDialogFactory, PanelHeaderFactory } from 'kepler.gl/components'
import React, { Component } from 'react'
import styled from 'styled-components'

const StyledPanelHeader = styled.div.attrs({
  className: 'side-side-panel__header',
})`
  background-color: ${(props) => props.theme.sidePanelHeaderBg};
  padding: 12px 16px 0 16px;
  padding-top: 18px;
`

function CustomPanelHeaderFactory(SaveExportDropdown, CloudStorageDropdown, ModalDialog) {
  class PanelHeader extends Component {
    static propTypes = {}

    static defaultProps = {}

    constructor() {
      super()

      if (!localStorage.getItem('modalInfoShown')) {
        localStorage.setItem('modalInfoShown', 'true')
      }
    }

    closeUserInfoModal = () => {
      localStorage.setItem('modalInfoShown', 'false')
    }

    render() {
      return <StyledPanelHeader className='side-panel__panel-header' />
    }
  }
  return React.memo(PanelHeader)
}

CustomPanelHeaderFactory.deps = [PanelHeaderFactory.deps[1], ModalDialogFactory]

export const panelHeaderFactories = [PanelHeaderFactory, CustomPanelHeaderFactory]

export const customPanelHeaderFactory = CustomPanelHeaderFactory

export function replacePanelHeader() {
  return [PanelHeaderFactory, CustomPanelHeaderFactory]
}
