import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import React, { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class RefrechDatabaseModal extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return { loading: props.loading }
    }
    return null
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Refresh Database'>
        <Modal loading={this.state.loading} small {...this.props}>
          <Text className='my-3 text-lg'>Are you sure you want to refresh the database metadata?</Text>
          <Text className='my-6 text-xs pr-4'>
            Refreshing the database would include all updates made to data in the database’s tables and associated
            columns.
          </Text>
        </Modal>
      </ModalContainer>
    )
  }
}
