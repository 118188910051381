import OutsideAlerter from 'components/helpers/OutsideAlerter'
import React, { Component } from 'react'

class DropDown extends Component {
  constructor(props) {
    super(props)

    this.state = { enabled: true, show: false }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.enabled !== state.enabled) {
      return {
        enabled: props.enabled,
      }
    }
    return null
  }

  toggleDropDown = () => {
    if (this.props.disabled === true) {
      return false
    }
    this.props?.toggle(!this.state.show)
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ show: !this.state.show })
    return true
  }

  clickOutside = () => {
    try {
      this.props?.toggle(false)
    } catch (error) {
      this.setState({ show: false })
    }

    this.setState({ show: false })
  }

  hideOnClickInside = () => {
    if (this.props?.hideAfterClick) {
      this.props.toggle(false)
      this.setState({ show: false })
    }
  }

  render() {
    const data = React.Children.toArray(this.props.children)

    return (
      <OutsideAlerter
        clickOutside={this.clickOutside}
        styles={`${this.props.styles} tooltip-container   group relative  cursor-pointer`}
      >
        <div onClick={this.toggleDropDown}>{data[0]}</div>
        {this.state.show ? <div onClick={this.hideOnClickInside}>{data[1]}</div> : null}
      </OutsideAlerter>
    )
  }
}

export default DropDown
