/* eslint-disable react/prefer-stateless-function */
import ChangePasswordForm from 'components/Modals/Settings/ChangePasswordForm'
import UserInfoForm from 'components/Modals/Settings/UserInfoForm'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'

export default class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      changePassword: false,
      userInfo: true,
    }
  }

  render() {
    return (
      <ModalContainer className='pl-0 pb-0 w-largeModal' start close={this.close} title='Settings'>
        <div className='grid grid-cols-12 mt-4 '>
          <div className='col-span-3 border-r-1 border-r-gray-400  '>
            <div className='flex flex-col justify-center items-start '>
              <p
                onClick={() => {
                  this.setState({ userInfo: true, changePassword: false })
                }}
                className={`${
                  this.state.userInfo && 'bg-gray-100'
                } hover:bg-gray-200 w-full px-3 py-1 cursor-pointer transition duration-200`}
              >
                User Information
              </p>
              <p
                className={`${
                  this.state.changePassword && 'bg-gray-100'
                } hover:bg-gray-200 w-full px-3 py-1 cursor-pointer transition duration-200`}
                onClick={() => {
                  this.setState({ changePassword: true, userInfo: false })
                }}
              >
                Change Password
              </p>
            </div>
          </div>
          <div className='col-span-9 px-6'>
            {this.state.changePassword && <ChangePasswordForm close={this.props.close} />}
            {this.state.userInfo && <UserInfoForm close={this.props.close} />}
          </div>
        </div>
      </ModalContainer>
    )
  }
}
