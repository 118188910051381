import { Component } from 'react'

export default class Pagination extends Component {
  renderPages = () => {
    const elements = []
    const from = this.props.page - 2 > 1 ? this.props.page - 2 : 1
    const to = this.props.page + 2 < this.props.pages ? this.props.page + 2 : this.props.pages

    if (this.props.page > 3) {
      elements.push(
        <span
          key={0}
          className='-mt-px pb-3 border-t-2 border-transparent inline-flex items-center text-sm leading-5 font-medium text-gray-500'
        >
          ...
        </span>,
      )
    }

    for (let index = from; index <= to; index++) {
      if (index === this.props.page) {
        elements.push(
          <a
            key={index}
            className='-mt-px border-b-2 p-2 pb-3 border-primarycolor inline-flex items-center text-sm leading-5 font-medium text-primarycolor focus:outline-none focus:text-primarycolor focus:border-primarycolor transition ease-in-out duration-150'
          >
            {index}
          </a>,
        )
      } else {
        elements.push(
          <a
            key={index}
            onClick={() => this.props.changepage(index)}
            className='cursor-pointer p-2 pb-3 -mt-px border-b-2 border-transparent   inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150'
          >
            {index}
          </a>,
        )
      }
    }

    if (this.props.page + 2 < this.props.pages && this.props.pages > 2) {
      elements.push(
        <span
          key={this.props.pages + 1}
          className='-mt-px pb-3 border-t-2 border-transparent   inline-flex items-center text-sm leading-5 font-medium text-gray-500'
        >
          ...
        </span>,
      )
    }

    return elements
  }

  render() {
    return (
      <div className=' flex justify-between items-center flex-no-wrap   py-1 w-full   '>
        <div className='flex justify-start items-center'>{this.renderPages()}</div>
        <p className='text-sm  leading-5 text-gray-700'>
          displaying from:
          <span className='font-medium ml-1 mr-1'>{(this.props.page - 1) * this.props.count + 1}</span>
          to:
          <span className='font-medium ml-1 mr-1'>
            {(this.props.page - 1) * this.props.count + this.props.count > this.props.total
              ? this.props.total
              : (this.props.page - 1) * this.props.count + this.props.count}
          </span>
          from:
          <span className='font-medium ml-1 mr-1'>{this.props.total}</span>
        </p>
      </div>
    )
  }
}
