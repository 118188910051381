import React, { Component } from 'react'

class Badge extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <div
        className={` ${
          !this.props.color ? 'bg-primary' : this.props.color
        } top-3 z-20 left-3  w-4 h-4  p-2 absolute rounded-full text-xxs flex items-center justify-center text-white text-center  `}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Badge
