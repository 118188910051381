export function setMapRef(mapRef) {
  return {
    type: 'MAP_REF',
    payload: mapRef,
  }
}

export function setMapCode(code) {
  return {
    type: 'MAP_CODE',
    payload: code,
  }
}

export function addDataset(dataset) {
  return {
    type: 'ADD_DATASET',
    payload: dataset,
  }
}

export function updateDataset(uuid, key, value) {
  return {
    type: 'UPDATE_DATASET',
    payload: { uuid, key, value },
  }
}

export function addFunction(fn) {
  return {
    type: 'ADD_FUNCTION',
    payload: fn,
  }
}

export function updateFunction(uuid, value) {
  return {
    type: 'UPDATE_FUNCTION',
    payload: { uuid, value },
  }
}
export function deleteFunction(uuid) {
  return {
    type: 'DELETE_FUNCTION',
    payload: uuid,
  }
}

export function deleteAllFunction() {
  return {
    type: 'DELETE_ALL_FUNCTION',
  }
}

export function setDatabses(databses) {
  return {
    type: 'SET_DATABASES',
    payload: databses,
  }
}

export function clearDataset() {
  return {
    type: 'CLEAR_DATASET',
  }
}

export function setResetMap(fn) {
  return {
    type: 'SET_RESET_MAP',
    payload: fn,
  }
}
export function setToggleModal(fn) {
  return {
    type: 'SET_TOGGLE_MODAL',
    payload: fn,
  }
}

export function setSession(session) {
  return {
    type: 'SET_SESSION',
    payload: session,
  }
}

export function clearSession(session) {
  return {
    type: 'CLEAR_SESSION',
  }
}
export function controlSideBar(state) {
  return {
    type: 'CONTROL_SIDEBAR',
    payload: state,
  }
}
export function controlLoading(state) {
  return {
    type: 'CONTROL_LOADING',
    payload: state,
  }
}
