/* eslint-disable react/prefer-stateless-function */

import Button from 'components/Portions/Button'
import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'

class UserGuide extends Component {
  constructor(props) {
    super(props)

    this.state = { modalLoading: false, activeStep: 0 }
  }

  componentDidMount() {}

  next = () => {
    const { activeStep } = this.state

    this.setState({ activeStep: activeStep + 1 })
  }

  back = () => {
    const { activeStep } = this.state

    this.setState({ activeStep: activeStep - 1 })
  }

  render() {
    return (
      <ModalContainer
        close={this.props.close}
        className='w-largeModal py-3'
        title='User Guide'
        description='You are 3 steps away from building your custom map visualization.'
      >
        <Modal loading={this.state.modalLoading} height={400}>
          <ol className='relative border-l border-gray-200 dark:border-gray-700 mt-6'>
            <li className='mb-10 ml-6 pl-3'>
              <span className='flex absolute justify-center items-center w-6 h-6 bg-primarycolor rounded-full ring-8 ring-white dark:ring-gray-900 mt-1 text-white z-50'>
                1
              </span>
              <div className='px-10 step overflow-hidden'>
                <div>
                  <h3 className='flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white'>
                    Enter your data
                  </h3>
                </div>
                <div className={`${this.state.activeStep !== 0 ? 'max-h-hiddenStep ' : 'max-h-full '}  theight`}>
                  <>
                    <Text className='font-bold' variant='body2' component='b'>
                      Use the left panel to build the view by entering your data.
                    </Text>
                    <Text variant='body2'>
                      It is possible to add static geocoded (single/multi coordinates) files by clicking on the
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Add Data" `}
                      </span>
                      button.
                    </Text>
                    <Text>
                      Supported formats are csv, json, or geojson. Or, you can fetch data from your PostGIS/SQL servers
                      by clicking on
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Select a database" `}
                      </span>
                      on the bottom left menu.
                    </Text>
                    <Text variant='body2' className='my-2 text-black text-xs'>
                      Once you have selected the database from the list of databases that were connected to your
                      organization, it is possible to apply dynamic filters by clicking on the
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Fetch Data Filtering" `}
                      </span>
                      button.
                    </Text>
                    <Text variant='body2' className='my-2 text-black text-xs'>
                      Finally you can fetch your filtered/unfiltered data by clicking on the
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Fetch Data" `}
                      </span>
                      button.
                    </Text>
                    <Text variant='body2' className='my-2 text-black text-xs'>
                      Now, your data will be visible on your map through a default layer depending on the geocoded
                      format of the data
                      <span component='i' variant='body2'>
                        {` (single coordinates by Points layer, multi-coordinates by Polygon or Trips layer).`}
                      </span>
                    </Text>
                    <Text variant='body2'>
                      After that, you can edit the configuration of the map by entering the appropriate attributes for
                      coordinates
                      <Text component='i' variant='body2'>
                        (if your data has more than one: for instance pick-up and drop-offs points)
                      </Text>
                    </Text>
                  </>
                  <div className=' mt-5 flex flex-row-reverse select-none'>
                    <Button single action={this.next}>
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </li>
            <li className='mb-10 ml-6 pl-3'>
              <span className='flex absolute  justify-center items-center w-6 h-6 bg-primarycolor rounded-full ring-8 ring-white dark:ring-gray-900 mt-1 text-white z-50'>
                2
              </span>
              <div className='px-10 step overflow-hidden'>
                <div>
                  <h3 className='flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white'>
                    Build your view
                  </h3>
                </div>
                <div className={`${this.state.activeStep !== 1 ? 'max-h-hiddenStep ' : 'max-h-full '}  theight`}>
                  <Text className='font-bold' variant='body2' component='b'>
                    You can build your view according to your needs.
                  </Text>
                  <br />
                  <Text variant='body2'>You can edit the layer type from the available ones:</Text>
                  <ul className='list-disc' style={{ padding: '0 30px 10px' }}>
                    <li>
                      <Text component='li' variant='body2'>
                        <span component='span' variant='body2' className='font-bold'>
                          Hexbin, Grid, Cluster
                        </span>
                        {` for points aggregation on a X km/pixels radius`}
                      </Text>
                    </li>
                    <li>
                      <Text component='li' variant='body2'>
                        <span component='span' variant='body2' className='font-bold'>
                          Heatmap
                        </span>
                        {` to visualize areas of high density`}
                      </Text>
                    </li>
                    <li>
                      <Text component='li' variant='body2'>
                        <span component='span' variant='body2' className='font-bold'>
                          Arc, Line
                        </span>
                        {` to visualize "source" to "target" itinerary`}
                      </Text>
                    </li>
                    <li>
                      <Text component='li' variant='body2'>
                        <span component='span' variant='body2' className='font-bold'>
                          Polygons, Trips
                        </span>
                        {` for multi-coordinates data`}
                      </Text>
                    </li>
                  </ul>
                  <Text variant='body2'>
                    {` You can eventually base your layer colors`}
                    <span component='i' variant='body2'>
                      (or heights in hexbin/grid layers case)
                    </span>
                    {` on the different attributes of your fetched/inserted data.`}
                  </Text>

                  <Text variant='body2' className='text-black my-2 text-xs'>
                    You can select the mapstyle by clicking on the 4th tab of the left menu. You can either choose one
                    of the default map styles provided by MapLab.world or insert a custom one by clicking on
                    <span component='span' variant='body2' className='font-bold'>
                      {` "Add Map Style ."`}
                    </span>
                  </Text>

                  <Text variant='body2'>
                    It is also possible to customize what you want to be seen on our default maps by clicking on the
                    <span component='span' variant='body2' className='font-bold'>
                      {` "eye icon" `}
                    </span>
                    placed next to the different map layers such as
                    <span component='span' variant='body2' className='font-bold'>
                      {` Borders, Water, Labels, Roads, `}
                    </span>
                    etc..
                  </Text>

                  <Text variant='body2' className='my-2 text-black text-xs'>
                    Last, it is possible to customize the color of your 3D buildings if you have chosen to view 3D
                    buildings by clicking on
                    <span component='span' variant='body2' className='font-bold'>
                      {` "3D building color" `}
                    </span>
                    and choosing the preferred color.
                  </Text>

                  <div className=' mt-5 flex flex-row-reverse select-none'>
                    <Button single action={this.next}>
                      Next
                    </Button>

                    <Button
                      // className={`${this.props.loading && 'cursor-default'}`}
                      action={this.back}
                      className='border-1 border-gray-400'
                      secondary
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </li>
            <li className='ml-6 pl-3'>
              <span className='flex absolute  justify-center items-center w-6 h-6 bg-primarycolor rounded-full ring-8 ring-white dark:ring-gray-900 mt-1 text-white z-50'>
                3
              </span>
              <div className='px-10 overflow-hidden'>
                <div>
                  <h3 className='flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white'>
                    Interact with your map
                  </h3>
                </div>
                <div className={`${this.state.activeStep !== 2 ? 'max-h-hiddenStep ' : 'max-h-full '}  theight`}>
                  <>
                    <Text variant='body2'>
                      Finally, now that you have built your view, you can use the 2nd and 3rd tab on the left panel to
                      interact with your map and derive insights based on your analysis purpose. You can filter your
                      data by clicking on
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Add filter" > "Choose Data Source" > `}
                      </span>
                      Choose the column you want to filter and enter the desired figures
                    </Text>

                    <Text variant='body2' className='my-2 text-xs text-black'>
                      When hovering over the map, a tooltip appears for each data point. By clicking the 3rd tab from
                      the left menu, you can add/remove visible attributes according to your analysis. You can always
                      conduct a comparative analysis of two different data points by checking
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Comparison mode" `}
                      </span>
                      then selecting the appropriate type
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Absolute - for raw values comparison" `}
                      </span>
                      or
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Relative - for percentage values comparison" `}
                      </span>
                      .
                    </Text>

                    <Text variant='body2'>The right part of the product allows more interaction: </Text>
                    <ul className='list-disc' style={{ padding: '0 30px 10px' }}>
                      <li>
                        <Text component='li' variant='body2'>
                          You can
                          <span component='span' variant='body2' className='font-bold'>
                            {` "Switch to dual map view" `}
                          </span>
                          if you want to analyze the same map through different layers.
                        </Text>
                      </li>
                      <li>
                        <Text component='li' variant='body2'>
                          Enter an address or coordinates
                        </Text>
                      </li>
                      <li>
                        <Text component='li' variant='body2'>
                          <Text component='span' variant='body2' className='font-bold'>
                            "Enable/Disable 3D mode".
                          </Text>
                        </Text>
                      </li>
                      <li>
                        <Text component='li' variant='body2'>
                          <Text component='span' variant='body2' className='font-bold'>
                            "Trace polygons or rectangles for a particular desired area"
                          </Text>
                        </Text>
                      </li>
                    </ul>
                    <Text variant='body2'>
                      Once your map is ready, you can generate a private or public link to access it by clicking on the
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Save" `}
                      </span>
                      button on the left panel from above.
                    </Text>

                    <Text variant='body2'>
                      You can
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Name" `}
                      </span>
                      your map, choose the type of saving
                      <span component='i' variant='body2'>
                        {` (Static or Dynamic) `}
                      </span>
                      , choose the availability of the map
                      <span component='i' variant='body2'>
                        {` (Private, Public) `}
                      </span>
                      and finally
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Save" `}
                      </span>
                      .
                    </Text>

                    <Text variant='body2' className='my-2 text-black text-xs'>
                      Once your map is saved a menu will appear displaying the Public/Private URL and an iframe code to
                      embed it on your website
                      <span component='i' variant='body2'>
                        {` (in case of public saving) `}
                      </span>
                    </Text>
                    <br />
                    <Text variant='body2'>
                      You can also click on the
                      <span component='span' variant='body2' className='font-bold'>
                        {` "Share" `}
                      </span>
                      button if you want to save your view in image format or if you want to save the data in it in csv
                      format.
                    </Text>
                  </>
                  <div className=' mt-5 flex flex-row-reverse select-none'>
                    <Button
                      // className={`${this.props.loading && 'cursor-default'}`}
                      action={this.back}
                      className='border-1 border-gray-400'
                      secondary
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user.user,
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(UserGuide)
