/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react'

class ScreanContainer extends Component {
  render() {
    return (
      <>
        <div className='w-full fixed-position h-1 left-0 top-0 widthTransition bg-transparent z-100  '>
          <span />
        </div>

        <div className=' h-screen flex overflow-hidden fixed-position top-0 bottom-0 right-0 left-0 bg-neutral-100 z-50'>
          {this.props.children}
        </div>
      </>
    )
  }
}

export default ScreanContainer
