/* eslint-disable linebreak-style */
import mainService from 'services/mainService'

export function tableStatus(uuid, data) {
  return mainService
    .put(`tables/${uuid}/status`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function tablesRefresh(uuid) {
  return mainService
    .get(`tables/${uuid}/refresh`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function tableDelete(uuid) {
  return mainService
    .delete(`tables/${uuid}/delete`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function tableRecord(uuid) {
  return mainService
    .get(`tables/${uuid}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function tableColumnUpdate(uuid, data) {
  return mainService
    .patch(`tables/${uuid}/updateColumns`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
