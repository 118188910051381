/* eslint-disable react/prefer-stateless-function */
import DynamicFilters from 'components/FunctionData/Modals/DynamicFilters'
import WithQuery from 'components/FunctionData/Modals/WithQuery'
import Icon from 'components/Portions/Icon'
import KeplerGlSchema from 'kepler.gl/schemas'
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import { setErrorHandler } from 'store/actions/errorsActions'

class DatasetsList extends Component {
  constructor(props) {
    super(props)
    this.listRef = React.createRef()

    this.state = {
      selectedDataset: null,
      opened: false,
      withQuery: false,
      dynamicFilters: false,
      items: [],
      data: {},
      jsonTree: {},
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  static getDerivedStateFromProps = (props, state) => {
    if (
      JSON.stringify(props.selectedDataset) !== JSON.stringify(state.selectedDataset) ||
      JSON.stringify(props.datasets) !== JSON.stringify(state.items)
    ) {
      const datasets = KeplerGlSchema.getDatasetToSave(props.keplerInstance)
      return {
        selectedDataset: props.selectedDataset,
        items: datasets
          .filter((x) => {
            return !x.data?.label?.includes('geocoder_dataset')
          })
          .map((dataset) => {
            const find = props.datasets[dataset.data.id]

            if (!find) {
              return {
                save: 'static',
                type: 'static',
                info: { id: dataset.data.id, sid: undefined, label: dataset.data.label },
                data: dataset.data,
                request: {},
                respond: {},
              }
            }
            return { ...find, data: dataset.data }
          }),
      }
    }
    return null
  }

  // eslint-disable-next-line consistent-return
  toggleList = () => {
    if (this.props.disable) {
      return false
    }

    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      opened: !this.state.opened,
    })
  }

  handleClickOutside = (e) => {
    if (this.listRef && !this.listRef.current?.contains(e.target)) {
      this.setState({ opened: false })
    }
  }

  toggleWithQuery = (data) => {
    // eslint-disable-next-line react/no-access-state-in-setstate

    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ withQuery: !this.state.withQuery, opened: false, data: this.props.activeFunction.request })
  }

  toggleDynamicFilters = (data) => {
    // eslint-disable-next-line react/no-access-state-in-setstate

    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      dynamicFilters: !this.state.dynamicFilters,
      opened: false,
      data: this.props.activeFunction.request,
      jsonTree: this.props.activeFunction.request.jsonTree,
    })
  }

  setSqlQuery = (query) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ item: { ...this.state.item, query } })
  }

  render() {
    const datasets = KeplerGlSchema.getDatasetToSave(this.props.keplerInstance)
    return (
      <>
        <div
          ref={this.listRef}
          className={` ${
            this.props.error ? 'bg-red-200' : 'bg-menu'
          } text-gray-400 py-1 col-span-9 rounded-sm     noSelect relative datasetDropList`}
        >
          {this.state.withQuery && (
            <WithQuery
              item={this.state.data}
              uuid={this.props.uuid}
              getQueryData={this.props.getQueryData}
              toggleWithQuery={this.toggleWithQuery}
              cancel={{ text: 'Cancel', handler: this.toggleWithQuery }}
            />
          )}
          {this.state.dynamicFilters && (
            <DynamicFilters
              jsonTree={this.state.jsonTree}
              item={this.state.data}
              uuid={this.props.uuid}
              getFiltersData={(uuid, database, table, filters, filterConjunction, jsonTree) => {
                this.setState({ jsonTree })
                this.props.getFiltersData(uuid, database, table, filters, filterConjunction, jsonTree)
              }}
              toggleDynamicFilters={this.toggleDynamicFilters}
              cancel={{ text: 'Cancel', handler: this.toggleDynamicFilters }}
            />
          )}
          <div>
            <div
              onClick={this.toggleList}
              className='cursor-pointer  selectedArea px-1 w-full flex justify-between items-center'
            >
              {this.state.selectedDataset && (
                <p className='overflow-hidden truncate w-full'>{this.state.selectedDataset.label}</p>
              )}
              <div className='flex flex-col justify-center w-full items-start'>
                {!this.props.type && <p> Select dataset or write a query</p>}
                {!this.state.selectedDataset && this.props.type === 'dataset' && <p> Select dataset</p>}
                {this.props.type === 'query' && (
                  <p
                    title={this.props?.selectedTable?.name || 'With query'}
                    className='overflow-hidden truncate w-full'
                  >
                    {'Query on table '}
                    {this.props?.selectedTable?.name || 'With query'}
                  </p>
                )}
                {this.props.type === 'dynamic' && (
                  <p
                    title={this.props?.selectedTable?.name || 'Select table'}
                    className='overflow-hidden truncate w-full'
                  >
                    {'Dyanmic on table '}
                    {this.props?.selectedTable?.name || 'Select table'}
                  </p>
                )}
              </div>
              <svg
                className='icon  -rotate-180'
                viewBox='0 0 24 24'
                strokeWidth='2'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <polyline points='6 15 12 9 18 15' />
              </svg>
            </div>
          </div>

          {this.state.opened && (
            <>
              <div className='absolute  z-10 mt-3 options w-56   overflow-hidden  '>
                <ScrollArea style={{ maxHeight: 200 }} smoothScrolling>
                  <p
                    onClick={this.toggleWithQuery}
                    className='  dark-gray text-white  tableName hover:bg-gray-300 transition duration-150 cursor-pointer'
                  >
                    With query
                  </p>
                  <p
                    onClick={this.toggleDynamicFilters}
                    className='  dark-gray text-white  tableName hover:bg-gray-300 transition duration-150 cursor-pointer'
                  >
                    Select table
                  </p>
                  {this.state.items
                    .filter((x) => {
                      return (
                        datasets.map((y) => y.data.id).includes(x.info.id) && !['static', 'function'].includes(x.type)
                      )
                    })
                    .map((item) => {
                      return (
                        <Fragment key={item.info.id}>
                          <p
                            onClick={() => {
                              this.props.selectDataset(this.props.uuid, item)
                              this.setState({ opened: false })
                            }}
                            className='  dark-gray text-white  tableName hover:bg-gray-300 transition duration-150 cursor-pointer'
                          >
                            {item.info.label}
                          </p>
                        </Fragment>
                      )
                    })}
                </ScrollArea>
              </div>
            </>
          )}
        </div>
        <Icon
          stroke={2}
          action={() => {
            if (this.props.type === 'query') {
              this.toggleWithQuery()
            } else if (this.props.type === 'dynamic') {
              this.toggleDynamicFilters()
            }
          }}
          className={`${
            this.props.type === 'query' || this.props.type === 'dynamic'
              ? 'text-gray-600 cursor-pointer'
              : 'text-gray-600'
          }     pt-2  ml-2 col-span-1  cursor-pointer  w-3`}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <ellipse cx='12' cy='6' rx='8' ry='3' />
          <path d='M4 6v6a8 3 0 0 0 16 0v-6' />
          <path d='M4 12v6a8 3 0 0 0 16 0v-6' />
        </Icon>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  datasets: state.app.view.datasets,
  keplerInstance: state.keplerGl?.MapLab,
})

const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DatasetsList)
