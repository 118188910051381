import React, { Component } from 'react'
// eslint-disable-next-line absolute-import/no-relative-path
import Badge from './Badge'

export default class Icon extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <div
        className='relative cursor-pointer'
        onClick={() => {
          if (this.props.action) {
            this.props.action()
          }
        }}
      >
        <svg
          className={this.props.className ? this.props.className : `h-6 w-6`}
          width='24'
          height='24'
          viewBox='0 0 24 24'
          strokeWidth={this.props.stroke ? this.props.stroke : 1}
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          {this.props.children}
        </svg>
        {this.props.badge && <Badge>{this.props.number}</Badge>}
      </div>
    )
  }
}
