/* eslint-disable linebreak-style */
import googleService from 'services/googleService'
import mainService from 'services/mainService'

export function datasetDynamic(data) {
  return mainService
    .post(`datasets/dynamic`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function datasetFetch(data) {
  return mainService
    .post(`datasets/fetch`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function datasetQuery(data) {
  return mainService
    .post(`datasets/query`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function datasetSave(data) {
  return mainService.post(`datasets/save`, data)
}
export function datasetToken(data) {
  return mainService.post(`datasets/token`, data)
}

export function datasetUpload(token, filename, geoJson) {
  const bucketName = 'davinci-web-assets'
  const url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=resumable&name=${filename}&upload_id=${token}`
  /// const shareableUrl = `https://storage.googleapis.com/${bucketName}/${filename}`
  const strGeoJson = JSON.stringify(geoJson)
  const blob = new Blob([strGeoJson], { type: 'application/octet-stream' })

  return googleService.put(url, blob)
}

export function datasetDownload(url) {
  return googleService
    .get(url)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function datasetFunction(type, data) {
  return mainService
    .post(`datasets/function/${type}`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
