import Layout from 'pages/Gallery/Layout/Layout'
import Header from 'pages/Gallery/components/Header/Header'
import { sessionImage, sessionRecords } from 'services/session'
import Pagination from 'components/Portions/Pagination'
import { useState, useEffect, useCallback } from 'react'
import { setErrorHandler } from 'store/actions/errorsActions'
import Loading from 'components/Portions/Loading'
import { useDispatch } from 'react-redux'
import RecentlyAdded from 'pages/Gallery/components/RecentlyAdded/RecentlyAdded'
import SectionHeader from 'pages/Gallery/components/Section/SectionHeader'
import SectionSubHeader from 'pages/Gallery/components/Section/SectionSubHeader'
import MapCard from 'pages/Gallery/components/MapCard/MapCard'
import Search from 'pages/Gallery/components/Search/Search'
import styled from 'styled-components'

import debounce from 'lodash/debounce'
import isUndefined from 'lodash/isUndefined'

const GalleryWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;
`
const GalleryContainer = styled.div`
  padding: 20px;
  margin: 10px auto;
  max-width: 1400px;
`
const Gallery = () => {
  const [items, setItems] = useState([])
  const [recentlyAdded, setRecentlyAdded] = useState({})
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [count, setCount] = useState(30)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(undefined)
  const dispatch = useDispatch()

  const getItems = useCallback(
    (inputSearch) => {
      setLoading(true)
      sessionRecords({
        page,
        limit: 16,
        search: isUndefined(inputSearch) ? search || '' : inputSearch,
        user: false /* true if we want to seen only user's maps */,
      })
        .then((respond) => {
          setLoading(false)
          setItems(
            respond.data.map((x, i) => {
              if (i === 0 && page === 1 && (isUndefined(inputSearch) || inputSearch === '')) {
                setRecentlyAdded({ ...x, imageLoading: true })
              }
              getImage(x.uuid)
              return { ...x, imageLoading: true }
            }),
          )
          // setItems(newItems)
          setTotal(respond.meta.total)
          setPages(Math.ceil(respond.meta.total / respond.meta.count))
          setCount(respond.meta.count)
        })
        .catch((error) => {
          setLoading(false)
          dispatch(setErrorHandler(error.response))
        })
    },
    [page, search],
  )

  const getImage = useCallback(
    (uuid, latestAddition = false) => {
      sessionImage(uuid)
        .then((respond) => {
          if (latestAddition) {
            setRecentlyAdded((prevState) => ({
              ...prevState,
              image: respond.data.image,
              imageLoading: false,
            }))
            return
          }
          setItems((prevItems) =>
            prevItems.map((x) => {
              if (x.uuid === uuid) {
                x.image = respond.data.image
                x.imageLoading = false
              }
              return x
            }),
          )
        })
        .catch((error) => {
          const newItems = items.map((x) => {
            if (x.uuid === uuid) {
              x.image = null
              x.imageLoading = false
            }
            return x
          })
          setItems(newItems)
        })
    },
    [items, page, search],
  )

  useEffect(() => {
    if (recentlyAdded?.imageLoading) {
      getImage(recentlyAdded?.uuid, true)
    }
  }, [recentlyAdded?.imageLoading])

  useEffect(() => {
    getItems()
  }, [page])

  const handlePageChage = (newPage) => {
    setPage(newPage)
  }

  const debounceHandleSearch = useCallback(debounce(getItems, 600), [])

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    debounceHandleSearch(e.target.value)
  }

  return (
    <Layout>
      <Header />
      <GalleryWrapper>
        <GalleryContainer>
          <Loading value={loading && !recentlyAdded?.id} bigWidth>
            <RecentlyAdded map={recentlyAdded} />
          </Loading>
          <div className='mt-16'>
            <SectionHeader>Map Gallery</SectionHeader>
            <SectionSubHeader>See the List of All Saved Maps</SectionSubHeader>
            <Search onFetch={getItems} onSearchChange={handleSearchChange} search={search} />
            <Loading value={loading} bigWidth>
              <div>
                <div className='flex flex-wrap -mx-4'>
                  {items.map((map) => (
                    <MapCard key={map.id} map={map} />
                  ))}
                  {items.length === 0 && (
                    <div className='text-2xl flex items-center justify-center w-full h-72'>No Maps available!</div>
                  )}
                </div>
              </div>
            </Loading>
          </div>
          {!loading && items?.length > 0 && (
            <Pagination page={page} total={total} pages={pages} count={count} changepage={handlePageChage} />
          )}
        </GalleryContainer>
      </GalleryWrapper>
    </Layout>
  )
}

export default Gallery
