import CheckBox from 'components/Portions/CheckBox'
import DropList from 'components/Portions/DropList'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import TextArea from 'components/Portions/TextArea'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class UpdateDatabaseModal extends Component {
  constructor() {
    super()
    this.state = {
      item: {},
      errors: {},
      loading: false,
      tab: 'connection',
      drivers: [
        {
          name: 'mysql',
          port: 3306,
          title: 'Mysql - Maria db',
          inputs: ['host', 'port', 'username', 'password', 'name'],
        },
        {
          name: 'mssql',
          port: 1433,
          title: 'Microsoft SQL Server ',
          inputs: ['host', 'port', 'username', 'password', 'name', 'schema'],
        },
        {
          name: 'postgres',
          port: 5432,
          title: 'Postgres - postgis',
          inputs: ['host', 'port', 'username', 'password', 'name', 'schema'],
        },
        {
          name: 'snowflake',
          port: null,
          title: 'Snowflake',
          inputs: ['username', 'password', 'name', 'details', 'schema'],
        },
        { name: 'googleBigQuery', port: null, title: 'Google Big Query', inputs: ['details'] },
      ],
      driver: {},
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.loading !== state.loading ||
      JSON.stringify(props.item) !== JSON.stringify(state.item) ||
      JSON.stringify(props.errors) !== JSON.stringify(state.errors)
    ) {
      return { loading: props.loading, item: props.item, errors: props.errors }
    }
    return false
  }

  componentDidMount() {
    const { drivers } = this.state
    this.setState({ driver: drivers.find((x) => x.name === this.props.item.driver) })
  }

  updateInputs = (driver) => {
    const { drivers } = this.state
    this.setState({ driver: drivers.find((x) => x.name === driver) }, () => {
      this.props.handelChange({ target: { name: 'port', value: this.state.driver.port } })
    })
  }

  render() {
    return (
      <ModalContainer className='w-smallModal  py-3' title='Update Database'>
        <Modal loading={this.state.loading} {...this.props}>
          <Form>
            <div className='flex gap-3 border-b-1 border-gray-400'>
              <div
                onClick={() => {
                  this.setState({ tab: 'connection' })
                }}
                className={`${
                  this.state.tab === 'connection' && 'font-bold text-primarycolor hover:text-hovercolor'
                } pr-2 py-2  cursor-pointer`}
              >
                Connection
              </div>
              <div
                onClick={() => {
                  this.setState({ tab: 'advanced' })
                }}
                className={`${
                  this.state.tab === 'advanced' && 'font-bold text-primarycolor hover:text-hovercolor'
                } pr-2 py-2  cursor-pointer`}
              >
                Advanced
              </div>
            </div>
            {this.state.tab === 'connection' && (
              <div>
                <FormGroup>
                  <Label htmlFor='title'>Title* :</Label>
                  <Input
                    onChange={this.props.handelChange}
                    id='title'
                    name='title'
                    placeholder='Database title'
                    value={this.state.item.title}
                  />
                  <InputError>{this.state.errors.title}</InputError>
                </FormGroup>

                <FormGroup>
                  <Label htmlFor='driver'>Database Driver* :</Label>
                  <DropList
                    options={this.state.drivers.map((x) => {
                      return { value: x.name, text: x.title }
                    })}
                    name='driver'
                    onChange={(e) => {
                      this.updateInputs(e.target.value)
                      this.props.handelChange(e)
                    }}
                    id='driver'
                    placeholder='Database Driver'
                    value={this.state.item.driver}
                  />
                  <InputError>{this.state.errors.driver}</InputError>
                </FormGroup>

                <div className='grid grid-cols-12 w-full gap-2'>
                  {this.state.driver?.inputs?.includes('username') && (
                    <FormGroup className='col-span-6'>
                      <Label htmlFor='username'>Username* :</Label>
                      <Input
                        onChange={this.props.handelChange}
                        id='username'
                        name='username'
                        placeholder='Database username'
                        value={this.state.item.username}
                      />
                      <InputError>{this.state.errors.username}</InputError>
                    </FormGroup>
                  )}
                  {this.state.driver?.inputs?.includes('password') && (
                    <FormGroup className='col-span-6'>
                      <Label htmlFor='password'>Password* :</Label>
                      <Input
                        onChange={this.props.handelChange}
                        id='password'
                        type='password'
                        name='password'
                        placeholder='Database password'
                        value={this.state.item.password}
                      />
                      <InputError>{this.state.errors.password}</InputError>
                    </FormGroup>
                  )}
                </div>
                <div className='grid grid-cols-12 w-full gap-2'>
                  {this.state.driver?.inputs?.includes('host') && (
                    <FormGroup className='col-span-8'>
                      <Label htmlFor='host'>Host* :</Label>
                      <Input
                        onChange={this.props.handelChange}
                        id='host'
                        name='host'
                        placeholder='Database host'
                        value={this.state.item.host}
                      />
                      <InputError>{this.state.errors.host}</InputError>
                    </FormGroup>
                  )}
                  {this.state.driver?.inputs?.includes('port') && (
                    <FormGroup className='col-span-4'>
                      <Label htmlFor='port'>Port* :</Label>
                      <Input
                        onChange={this.props.handelChange}
                        id='port'
                        name='port'
                        placeholder='Database port'
                        value={this.state.item.port}
                      />
                      <InputError>{this.state.errors.port}</InputError>
                    </FormGroup>
                  )}
                </div>
                <div className='grid grid-cols-12 w-full gap-2'>
                  {this.state.driver?.inputs?.includes('name') && (
                    <FormGroup className='col-span-8'>
                      <Label htmlFor='name'>Name :</Label>
                      <Input
                        onChange={this.props.handelChange}
                        id='name'
                        name='name'
                        placeholder='Database name'
                        value={this.state.item.name}
                      />
                      <InputError>{this.state.errors.name}</InputError>
                    </FormGroup>
                  )}
                  {this.state.driver?.inputs?.includes('schema') && (
                    <FormGroup className='col-span-4'>
                      <Label htmlFor='schema'>Schema* :</Label>
                      <Input
                        onChange={this.props.handelChange}
                        id='schema'
                        name='schema'
                        placeholder='Database schema'
                        value={this.state.item.schema}
                      />
                      <InputError>{this.state.errors.schema}</InputError>
                    </FormGroup>
                  )}
                </div>

                {this.state.driver?.inputs?.includes('details') && (
                  <FormGroup>
                    <Label htmlFor='details'>Details (json key)* :</Label>
                    <TextArea
                      name='details'
                      onChange={this.props.handelChange}
                      id='details'
                      Details='details'
                      placeholder='Details'
                      json
                      value={this.state.item.details}
                    />
                    <InputError>{this.state.errors.details}</InputError>
                  </FormGroup>
                )}
                <FormGroup horizontal>
                  <CheckBox
                    name='private'
                    options={['true', 'false']}
                    onChange={this.props.handelChange}
                    checked={this.state.item.private}
                    string
                  />
                  <Label htmlFor='private'>Private database</Label>
                </FormGroup>
              </div>
            )}
            {this.state.tab === 'advanced' && (
              <div>
                <FormGroup>
                  <Label htmlFor='carto'>Geography function :</Label>
                  <Input
                    onChange={this.props.handelChange}
                    id='carto'
                    name='carto'
                    placeholder='Database carto'
                    value={this.state.item.carto}
                  />
                  <InputError>{this.state.errors.carto}</InputError>
                </FormGroup>
                <FormGroup>
                  <div className='  font-bold'>What is the Geography function</div>
                  <div className='  text-black text-xs'>
                    The database usually not supports geography functions. Geography functions operate on or generate
                    Standard SQL GEOGRAPHY values.
                  </div>
                  <div className='  text-black text-xs'>
                    You need to install this function inside your databse driver so you can use the our Geography
                    functions
                  </div>
                </FormGroup>
              </div>
            )}
          </Form>
        </Modal>
      </ModalContainer>
    )
  }
}
