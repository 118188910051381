/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-shadow */
import MapLabFiltersConfig from 'components/FetchData/Modals/FilterModal/FilterQueryBuilder.config'
import DropList from 'components/Portions/DropList'
import FormGroup from 'components/Portions/FormGroup'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { Builder, Utils as QbUtils, Query } from 'react-awesome-query-builder'
import { connect } from 'react-redux'
import { setErrorHandler } from 'store/actions/errorsActions'

const operatorMapping = {
  equal: 'eq',
  not_equal: 'ne',
  greater: 'gt',
  greater_or_equal: 'ge',
  less: 'lt',
  less_or_equal: 'le',
  between: 'between',
  not_between: 'not_between',
  is_empty: 'is_empty',
  is_not_empty: 'is_not_empty',
  today: 'today',
  yesterday: 'yesterday',
}
const typesMapping = {
  boolean: 'boolean',
  decimal: 'number',
  number: 'number',
  float: 'number',
  int: 'number',
  numeric: 'number',
  str: 'text',
  text: 'text',
  date: 'datetime',
  datetime: 'datetime',
  timestamp: 'datetime',
  time: 'time',
}

const types = {
  text: {
    type: 'text',
    fieldSettings: {
      min: 0,
    },
  },
  number: {
    type: 'number',
    fieldSettings: {
      min: 0,
    },
  },
  datetime: {
    type: '!group',
    subfields: {
      Default: {
        type: 'datetime',
      },
      Past: {
        type: 'customDate',
        operators: ['days', 'weeks', 'months', 'quarters', 'years'],
        defaultValue: 1,
        fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        initialEmptyWhere: false,
      },
      This: {
        type: 'customThisDate',
        operators: ['day', 'week', 'month', 'quarter', 'year'],
        valueSources: ['value'],
      },
      Next: {
        type: 'customDate',
        operators: ['days', 'weeks', 'months', 'quarters', 'years'],
        defaultValue: 1,
        fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        initialEmptyWhere: false,
      },
    },
  },
  boolean: {
    type: 'boolean',
    widgets: {
      boolean: {
        widgetProps: {
          labelYes: 'True',
          labelNo: 'False',
        },
      },
    },
  },
  time: {
    type: 'time',
  },
}

// eslint-disable-next-line react/prefer-stateless-function
class DynamicFilters extends Component {
  constructor(props) {
    super(props)

    this.state = {
      item: {},
      errors: {},

      config: {},
      columns: [],

      tree: null,
      filters: [],
      filterConjunction: 'AND',
      jsonTree: {},
      CURRENT_TS: 'currentTimeStamp(days=0)',
    }
  }

  componentDidMount = () => {
    let config = { ...MapLabFiltersConfig, fields: {} }
    if (this.props.item.table) {
      config = this.findColumns(this.props.item.database, this.props.item.table)
    }
    this.setState({
      item: this.props.item,
      filters: this.props.item.filters,
      filterConjunction: this.props.item.filterConjunction || 'AND',
      jsonTree: this.props.item.jsonTree,
      config,
      tree:
        Object.keys(this.props.jsonTree).length >= 1
          ? QbUtils.checkTree(QbUtils.loadTree(this.props.jsonTree), config)
          : QbUtils.checkTree(QbUtils.loadTree({ id: QbUtils.uuid(), type: 'group' }), config),
    })
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value
    let { config } = this.state
    errors[e.target.name] = null
    if (e.target.name === 'table') {
      config = this.findColumns(item.database, item.table)
    }
    this.setState({ config, item, errors })
  }

  findColumns = (database, table) => {
    const config = { ...MapLabFiltersConfig, fields: {} }

    this.props.databases
      .find((x) => x.uuid === database)
      .tables.find((x) => x.uuid === table)
      .columns.filter((x) => Object.keys(typesMapping).includes(x.type))
      .forEach((column) => {
        config.fields[column.name] = { ...types[typesMapping[column.type]], label: column.name }
      })

    return config
  }

  renderBuilder = (props) => (
    <div className='query-builder-container' style={{ padding: '10px' }}>
      <div className='query-builder qb-lite'>
        <Builder {...props} />
      </div>
    </div>
  )

  onChange = (immutableTree) => {
    const jsonTree = QbUtils.getTree(immutableTree)
    this.setState({
      jsonTree,
      tree: immutableTree,
      item: {
        ...this.state.item,
        filters: this.rulesToFilters(jsonTree),
        filterConjunction: jsonTree.properties?.conjunction || 'AND',
      },
    })
  }

  filterOperator = ({ operator, field, value }, type) => {
    switch (type) {
      case 'This': {
        switch (operator) {
          case 'day': {
            return {
              col_name: field,
              symbol: operatorMapping.equal,
              value: this.state.CURRENT_TS,
            }
          }
          case 'week': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentWeek(weeks=0)', this.state.CURRENT_TS],
            }
          }
          case 'month': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentMonth(months=0)', this.state.CURRENT_TS],
            }
          }
          case 'quarter': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentQuarter(quarters=0)', this.state.CURRENT_TS],
            }
          }
          case 'year': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: ['currentYear(years=0)', this.state.CURRENT_TS],
            }
          }
          default: {
            return {}
          }
        }
      }
      case 'Past':
      case 'Next': {
        switch (operator) {
          case 'days': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentTimeStamp(days=${type === 'Past' ? -1 : 1})`
                : `currentTimeStamp(days=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'weeks': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentWeek(weeks=${type === 'Past' ? -1 : 1})`
                : `currentWeek(weeks=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'months': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentMonth(months=${type === 'Past' ? -1 : 1})`
                : `currentMonth(months=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'quarters': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentQuarter(quarters=${type === 'Past' ? -1 : 1})`
                : `currentQuarter(quarters=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          case 'years': {
            const actualValue =
              !value?.length || value[0] === 0
                ? `currentYear(years=${type === 'Past' ? -1 : 1})`
                : `currentYear(years=${type === 'Past' ? -Number(value[0]) : Number(value[0])})`
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: type === 'Past' ? [actualValue, this.state.CURRENT_TS] : [this.state.CURRENT_TS, actualValue],
            }
          }
          default: {
            return {}
          }
        }
      }
      default: {
        switch (operator) {
          case 'not_between':
          case 'between': {
            return {
              col_name: field,
              symbol: operatorMapping[operator],
              value,
            }
          }
          case 'is_empty':
          case 'is_not_empty': {
            return {
              col_name: field,
              symbol: operator === 'is_empty' ? operatorMapping.equal : operatorMapping.not_equal,
              value: null,
            }
          }
          case 'today': {
            return {
              col_name: field,
              symbol: operatorMapping.greater_or_equal,
              value: 'currentDate(days=0)',
            }
          }
          case 'yesterday': {
            return {
              col_name: field,
              symbol: operatorMapping.between,
              value: 'currentDate(days=-1)',
            }
          }
          default: {
            return {
              col_name: field,
              symbol: operatorMapping[operator],
              value: value[0],
            }
          }
        }
      }
    }
  }

  rulesToFilters = (rules) => {
    return Object.values(rules?.children1)
      .flatMap((r) => {
        if (r.type === 'rule') {
          return this.filterOperator(r.properties)
        }
        if (r.type === 'rule_group' && r.children1) {
          return Object.values(r?.children1).map((innerRule) => {
            if (innerRule.properties?.field?.includes('Default')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field })
            }
            if (innerRule.properties?.field?.includes('Past')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field }, 'Past')
            }
            if (innerRule.properties?.field?.includes('This')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field }, 'This')
            }
            if (innerRule.properties?.field?.includes('Next')) {
              return this.filterOperator({ ...innerRule.properties, field: r.properties.field }, 'Next')
            }
            return {}
          })
        }
        return {}
      })
      .filter((f) => f.value !== undefined)
  }

  save = () => {
    const { database, table, filters, filterConjunction } = this.state.item
    this.props.getFiltersData(this.props.uuid, database, table, filters, filterConjunction, this.state.jsonTree)
    this.props.toggleDynamicFilters()
  }

  render() {
    return (
      <ModalContainer className='w-mediumModal  py-3' title='Data Filtering'>
        <Modal
          disable={!this.state.item.database || !this.state.item.table || !this.state.item.filters}
          loading={this.state.modalLoading}
          action={{ handler: this.save, text: 'Save', className: 'rounded-sm databaseButton' }}
          {...this.props}
        >
          <FormGroup vertical>
            <Label htmlFor='database'>Database</Label>
            <DropList
              options={this.props.databases.map((x) => {
                return { value: x.uuid, text: x.title }
              })}
              name='database'
              onChange={(e) => {
                this.handelChange(e)
              }}
              id='database'
              placeholder='Select database'
              value={this.state.item.database}
            />
            <InputError>{this.state.errors.database}</InputError>
          </FormGroup>
          <FormGroup vertical>
            <Label htmlFor='table'>Table</Label>

            <DropList
              options={this.props.databases
                .find((y) => {
                  return y.uuid === this.state?.item?.database
                })
                ?.tables.map((x) => {
                  return { value: x.uuid, text: x.title }
                })}
              name='table'
              onChange={(e) => {
                this.handelChange(e)
              }}
              id='table'
              placeholder='Select table'
              value={this.state.item.table}
            />
            <InputError>{this.state.errors.table}</InputError>
          </FormGroup>

          {this.state.tree && (
            <Query
              {...this.state.config}
              value={this.state.tree}
              onChange={this.onChange}
              renderBuilder={this.renderBuilder}
            />
          )}
        </Modal>
      </ModalContainer>
    )
  }
}
const mapStateToProps = (state) => ({
  databases: state.app.view.databases,
})

const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFilters)
