import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class ModalContainer extends Component {
  render() {
    return (
      <div className='modal-container fixed-position inset-0 bg-black bg-opacity-25 flex justify-center items-center '>
        <div className={` p-3 m-auto bg-white rounded-md ${this.props.className}`}>
          <div className=' flex justify-between items-center text-xl'>
            <div className='flex flex-col items-start justify-center text-black'>
              <p className={`${this.props.start ? 'pl-3' : ''}`}>{this.props.title}</p>
              {this.props.description && <p className='text-sm'>{this.props.description}</p>}
            </div>
            {this.props.close && (
              <div className='icon cursor-pointer' onClick={this.props.close}>
                <svg
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <line x1='18' y1='6' x2='6' y2='18' />
                  <line x1='6' y1='6' x2='18' y2='18' />
                </svg>
              </div>
            )}
          </div>
          {this.props.children}
        </div>
      </div>
    )
  }
}
