import Button from 'components/Portions/Button'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import { Component } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import { userInfo } from 'services/user'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'
import { setUser } from 'store/actions/userActions'

// eslint-disable-next-line react/prefer-stateless-function
class UserInfoForm extends Component {
  constructor() {
    super()
    this.state = {
      item: {},
      errors: {},
      modalLoading: false,
    }
  }

  componentDidMount() {
    this.setState({
      item: this.props.user,
    })
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value

    errors[e.target.name] = null

    this.setState({ item, errors })
  }

  save = () => {
    this.setState({ modalLoading: true })
    userInfo(this.state.item)
      .then((res) => {
        this.props.showMessage({
          messageType: 'SUCCESS',
          message: 'info updated successfully',
        })
        localStorage.setItem('user', JSON.stringify(res.data))
        this.props.setUser(res.data)
        this.props.close()
        this.setState({ modalLoading: false })
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        switch (error.response.status) {
          case 422:
            {
              const temp = {}
              for (let index = 0; index < error.response.data.errors.length; index++) {
                const element = error.response.data.errors[index]
                // eslint-disable-next-line prefer-destructuring
                temp[element[0]] = element[1][0]
              }
              this.setState({ errors: temp })
            }
            break
          default:
            this.props.setErrorHandler(error.response)
            break
        }
      })
  }

  render() {
    return (
      <ScrollArea style={{ height: 300 }}>
        <p className='text-lg'>User Information</p>
        <Form>
          <FormGroup>
            <Label htmlFor='first_name'>First name :</Label>
            <Input
              onChange={this.handelChange}
              id='first_name'
              name='first_name'
              placeholder='user first name'
              value={this.state.item?.first_name}
            />
            <InputError>{this.state.errors?.first_name}</InputError>
          </FormGroup>
          <FormGroup>
            <Label htmlFor='last_name'>Last name :</Label>
            <Input
              onChange={this.handelChange}
              id='last_name'
              name='last_name'
              placeholder='user last name'
              value={this.state.item?.last_name}
            />
            <InputError>{this.state.errors?.last_name}</InputError>
          </FormGroup>
          <FormGroup>
            <Label htmlFor='email'>Email* :</Label>
            <Input
              disable
              onChange={this.handelChange}
              id='email'
              name='email'
              placeholder='user email'
              value={this.state.item?.email}
            />
            <InputError>{this.state.errors?.email}</InputError>
          </FormGroup>

          <div className=' mt-5 flex flex-row-reverse select-none'>
            <Button disable='' loading={this.state.modalLoading} single action={this.save} className=''>
              Save
            </Button>

            <Button
              // className={`${this.props.loading && 'cursor-default'}`}
              action={this.props.close}
              className=''
              secondary
            >
              Cancel
            </Button>
          </div>
        </Form>
      </ScrollArea>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.app.user.user,
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  setUser: (data) => dispatch(setUser(data)),
  showMessage: (data) => dispatch(showMessage(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UserInfoForm)
