/* eslint-disable linebreak-style */
import mainService from 'services/mainService'

export function userRecord(uuid) {
  return mainService
    .get(`users/${uuid}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function userList() {
  return mainService
    .get(`users/list`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function userUpdate(uuid, data) {
  return mainService
    .put(`users/${uuid}/update`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function userDelete(uuid) {
  return mainService
    .delete(`users/${uuid}/delete`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function userCreate(data) {
  return mainService
    .post('users/create', data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function userStatus(uuid, data) {
  return mainService
    .put(`users/${uuid}/status`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
