const ManageDatabases = () => (
  <svg
    viewBox='0 0 24 24'
    strokeWidth='1'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    className='text-white w-6 h-6'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <ellipse cx='12' cy='6' rx='8' ry='3' />
    <path d='M4 6v6a8 3 0 0 0 16 0v-6' />
    <path d='M4 12v6a8 3 0 0 0 16 0v-6' />
  </svg>
)

export default ManageDatabases
