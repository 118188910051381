export function setUser(user) {
  return {
    type: 'SET_USER',
    payload: user,
  }
}

export function setPublicUser(state) {
  return {
    type: 'SET_PUBLIC_USER',
    payload: state,
  }
}
