import Label from 'components/Portions/Label'
import React, { Component } from 'react'

export default class RadioButton extends Component {
  constructor() {
    super()
    this.state = { checked: true, disable: false }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.checked !== nextProps.checked) {
      return {
        checked: nextProps.checked,
      }
    }
    return null
  }

  handleChange = () => {
    if (this.state.disable) {
      return false
    }
    const target = { name: this.props.name }
    target.value = this.props.value
    return this.props.onChange({ target })
  }

  render() {
    const classes = this.props.dark ? ' bg-droplist' : ' bg-gray-100'
    const svgClasses = this.props.dark ? ' text-subPrimary' : ' text-gray-200'
    const smallSize = this.props.smallSize ? '0 0 32 32' : '0 0 32 32'
    const alingCenter = this.props.smallSize ? 'items-center ' : ''
    return (
      <div style={this.props.styles} className={`${alingCenter} flex col-span-1 cursor-pointer`}>
        <span
          onClick={this.props.disabled ? () => {} : this.handleChange}
          className={` ${
            this.state.checked ? ' bg-primarycolor' : classes
          }   h-4 w-4 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
        >
          <svg
            viewBox={smallSize}
            className={`w-2 m-auto mt-0.5 ${this.state.checked ? ' text-white' : svgClasses}`}
            fill='none'
            stroke='currentcolor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='6'
          >
            <path d='M2 20 L12 28 30 4' />
          </svg>
        </span>
        {this.props.label && <Label className='ml-1 text-xs'>{this.props.label}</Label>}
      </div>
    )
  }
}
