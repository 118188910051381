const initialState = {
  mapRef: null,
  code: null,
  datasets: {},
  functions: {},
  resetMap: () => {},
  session: { public: 'false' },
  sideBar: false,
  applicationLoading: true,
  toggleModal: () => {},
  polygons: [],
  databases: [],
}
export const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MAP_REF':
      return { ...state, mapRef: state.mapRef === null ? action.payload : state.mapRef }
    case 'SET_TOGGLE_MODAL':
      return { ...state, toggleModal: action.payload }
    case 'SET_DATABASES':
      return { ...state, databases: action.payload }
    case 'MAP_CODE':
      return { ...state, code: action.payload }
    case 'ADD_DATASET':
      return { ...state, datasets: { ...state.datasets, ...action.payload } }
    case 'ADD_FUNCTION':
      return { ...state, functions: { ...state.functions, ...action.payload } }
    case 'UPDATE_FUNCTION':
      state.functions[action.payload.uuid] = action.payload.value
      return { ...state, functions: { ...state.functions } }
    case 'DELETE_FUNCTION':
      delete state.functions[action.payload]
      return { ...state, functions: { ...state.functions } }
    case 'DELETE_ALL_FUNCTION':
      return { ...state, functions: [] }
    case 'UPDATE_DATASET':
      state.datasets[action.payload.uuid][action.payload.key] = action.payload.value
      return { ...state, datasets: { ...state.datasets } }
    case 'CLEAR_DATASET':
      return { ...state, datasets: [] }
    case 'SET_RESET_MAP':
      return { ...state, resetMap: action.payload }
    case 'SET_SESSION':
      return { ...state, session: action.payload }
    case 'CLEAR_SESSION':
      return { ...state, session: { public: 'false' } }
    case 'CONTROL_SIDEBAR':
      return { ...state, sideBar: action.payload }
    case 'CONTROL_LOADING':
      return { ...state, applicationLoading: action.payload }
    default:
      return state
  }
}
