/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line no-unused-vars
import window from 'global/window'
import { enhanceReduxMiddleware } from 'kepler.gl/middleware'
import { browserHistory } from 'react-router'
// import { taskMiddleware } from 'react-palm/tasks'
// import thunkMiddleware from 'redux-thunk'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import reduceReducers from 'reduce-reducers'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import AppReducer from 'store/reducers/appReducer/appReducer'
import filterByPolygonReducer from 'store/reducers/appReducer/filterByPolygonReducer'
import KeplerReducer from 'store/reducers/keplerReducer/keplerReducer'

const chainedKeplerReducer = reduceReducers([], KeplerReducer, filterByPolygonReducer)

const reducers = combineReducers({
  keplerGl: chainedKeplerReducer,
  routing: routerReducer,
  app: AppReducer,
})

// const middlewares = [taskMiddleware, thunkMiddleware]
export const middlewares = enhanceReduxMiddleware([thunk, routerMiddleware(browserHistory)])

export const enhancers = [applyMiddleware(...middlewares)]

const initialState = {}

// eslint-disable-next-line prefer-const
let composeEnhancers = compose

/**
 * comment out code below to enable Redux Devtools
 */
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionsBlacklist: ['@@kepler.gl/MOUSE_MOVE', '@@kepler.gl/UPDATE_MAP', '@@kepler.gl/LAYER_HOVER'],
  })
}

export default createStore(reducers, initialState, composeEnhancers(...enhancers))
