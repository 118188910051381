import mainService from 'services/mainService'

export function userInfo(data) {
  return mainService
    .put(`user/updateInfo`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function passwordChange(data) {
  return mainService
    .post(`user/changePassword`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
