import styled from 'styled-components'
import { greyBlue } from 'theme/colors'

const SectionSubHeaderH3 = styled.h3`
  font-size: 20px;
  font-weight: 300;
  margin-top: -4px;
  margin-bottom: 16px;
  color: ${greyBlue};
`

const SectionSubHeader = ({ children }) => <SectionSubHeaderH3>{children}</SectionSubHeaderH3>

export default SectionSubHeader
