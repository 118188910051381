const UserIcon = ({ color = 'currentColor' }) => (
  <svg
    className='text-white w-6 h-6'
    viewBox='0 0 24 24'
    strokeWidth='1'
    stroke={color}
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <circle cx='12' cy='12' r='9' />
    <circle cx='12' cy='10' r='3' />
    <path d='M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855' />
  </svg>
)

export default UserIcon
