/* eslint-disable prettier/prettier */
import { onExportFileSuccess, onLoadCloudMapSuccess } from 'actions'
import { CLOUD_PROVIDERS } from 'cloud-providers'
import { replaceGeocoderPanel } from 'components/CustomKepler/CustomGeocoderPanel'
import { replaceKeplerGl } from 'components/CustomKepler/CustomKeplerGl'
import { replaceMapContainer } from 'components/CustomKepler/CustomMapContainer'
import { replaceSidePanel } from 'components/CustomKepler/CustomSidePanel'
import { replaceFeatureActionPanel } from 'components/CustomKepler/editor/CustomFeatureActionPanel'
import { replaceMapControl } from 'components/CustomKepler/map/CustomMapControl'
import { replaceAddMapStyleModal } from 'components/CustomKepler/modals/CustomAddMapStyleModal'
import { replaceFilterManager } from 'components/CustomKepler/side-panel/CustomFilterManager'
import { replaceLayerManager } from 'components/CustomKepler/side-panel/CustomLayerManager'
import { replaceMapManager } from 'components/CustomKepler/side-panel/CustomMapManager'
import { replacePanelHeader } from 'components/CustomKepler/side-panel/CustomPanelHeader'
import { replaceSidebar } from 'components/CustomKepler/side-panel/CustomSideBar'
import { replaceSourceDataCatalog } from 'components/CustomKepler/side-panel/common/CustomSourceDataCatalog'
import ModalManger from 'components/Modals/ModalManger'
import { mapboxStyle } from 'configs/map'
import { SETTINGS } from 'constants/default-settings'
import { messages } from 'constants/localization'
import { theme } from 'kepler.gl/dist/styles/base'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import { setPublicUser, setUser } from 'store/actions/userActions'
import { setMapCode, setMapRef as setMapRefAction } from 'store/actions/viewActions'
import { useVisStateKeplerState } from 'store/hooks'
import styled, { ThemeProvider } from 'styled-components'

const KeplerGl = require('kepler.gl/components').injectComponents([
  replaceKeplerGl(),
  replaceSidePanel(),
  replacePanelHeader(),
  replaceAddMapStyleModal(),
  replaceFilterManager(),
  replaceSidebar(),
  replaceLayerManager(),
  replaceMapManager(),
  replaceSourceDataCatalog(),

  replaceMapContainer(),
  replaceMapControl(),
  replaceFeatureActionPanel(),
  replaceGeocoderPanel(),
])

/* eslint-enable no-unused-vars */
const keplerGlGetState = (state) => state.keplerGl

const GlobalStyle = styled.div`
  font-family: ff-clan-web-pro, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.71429;

  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.labelColor};
  }
`

const KeplerGlWrapper = styled.div`
  transition: 'margin 1s, height 1s';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

const App = ({ viewerMode, setMapRef, setMapCodeAction, setUserAction, setPublicUserAction, match }) => {
  // Inner state
  const [timeRangeEnabled, setTimeRangeEnabled] = React.useState(false)

  // Redux Default Dispatcher
  const dispatch = useDispatch()

  // Redux Selectors
  const visStateFilters = useVisStateKeplerState((state) => state?.filters)
  // const visStateInteractionConfig = useVisStateKeplerState((state) => state?.interactionConfig)

  // Hooks

  React.useEffect(() => {
    if (match.params.code) {
      setMapCodeAction(match.params.code)
    }
  }, [match])
  React.useEffect(() => {
    setPublicUserAction(
      match?.path?.indexOf('public-map') >= 0 ||
        localStorage.getItem('user') === undefined ||
        localStorage.getItem('user') === null,
    )
    setUserAction(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {})
  }, [])

  // TimeRange filter
  React.useEffect(() => {
    if (visStateFilters?.length > 0) {
      const finded = visStateFilters?.find((fil) => fil.type === 'timeRange')
      if (finded?.enlarged && !timeRangeEnabled) {
        setTimeRangeEnabled(true)
      } else if (!finded || (finded && !finded.enlarged && timeRangeEnabled)) {
        setTimeRangeEnabled(false)
      }
    }

    if (visStateFilters?.length === 0 && timeRangeEnabled) {
      setTimeRangeEnabled(false)
    }
  }, [visStateFilters])

  // Geocoder
  // React.useEffect(() => {
  //   if (visStateInteractionConfig?.geocoder?.id === 'geocoder' && !visStateInteractionConfig?.geocoder?.enabled) {
  //     const config = {
  //       ...visStateInteractionConfig?.geocoder,
  //       enabled: true,
  //     }
  //     dispatch(wrapTo('MapLab', interactionConfigChange(config)))
  //   }
  // }, [visStateInteractionConfig])

  // Mapbox
  const getMapboxRef = React.useCallback(
    (mapbox, index) => {
      setMapRef(mapbox)
    },
    [viewerMode, dispatch],
  )

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle ref={(node) => node ?? null}>
        <KeplerGlWrapper>
          <ModalManger />
          <AutoSizer style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
            {({ height, width }) => (
              <>
                <KeplerGl
                  mapboxApiAccessToken={SETTINGS.MAPBOX_TOKEN}
                  mapStyles={mapboxStyle}
                  appName='MapLab.world'
                  id='MapLab'
                  getState={keplerGlGetState} // Specify path to keplerGl state, because it is not mount at the root
                  width={width}
                  height={height}
                  cloudProviders={CLOUD_PROVIDERS}
                  localeMessages={messages}
                  getMapboxRef={getMapboxRef}
                  onExportToCloudSuccess={viewerMode ? null : onExportFileSuccess}
                  onLoadCloudMapSuccess={viewerMode ? null : onLoadCloudMapSuccess}
                />
              </>
            )}
          </AutoSizer>
        </KeplerGlWrapper>
      </GlobalStyle>
    </ThemeProvider>
  )
}
const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setMapRef: (data) => dispatch(setMapRefAction(data)),
  setMapCodeAction: (data) => dispatch(setMapCode(data)),
  setUserAction: (data) => dispatch(setUser(data)),
  setPublicUserAction: (data) => dispatch(setPublicUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
