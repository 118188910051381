import axios from 'axios'
import { SETTINGS } from 'constants/default-settings'

export const baseURL = SETTINGS.BASE_URL

const authService = axios.create({
  baseURL,
  // withCredentials: true,
  headers: {
    // Authorization: "Bearer " + localStorage.getItem("token"),
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export default authService
