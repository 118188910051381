// eslint-disable-next-line import/no-named-as-default
import { DeleteBin, DownloadCloud, Share } from 'components/images'
import { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import ReactTooltip from 'react-tooltip'
import { controlLoading, setMapCode } from 'store/actions/viewActions'
// eslint-disable-next-line react/prefer-stateless-function
class SessionItem extends Component {
  constructor() {
    super()
    this.state = {
      opened: true,
      item: {},
      checked: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.item) !== JSON.stringify(state.item)) {
      return { item: props.item }
    }
    return false
  }

  toggleHandler = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ opened: !this.state.opened })
  }

  handleChecked = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ checked: !this.state.checked })
  }

  openSession = (code) => {
    if (this.props.item.code === this.props.session.code) {
      window.location.reload()
    }
    this.props.resetMap()
    this.props.setMapCode(code)
    browserHistory.push(`/map/${code}`)
    this.props.controlLoading(true)
    this.props.close()
  }

  render() {
    const { item } = this.state
    return (
      <div className={` primary-border-b  grid grid-cols-12    border-t-0   session-item transition duration-150`}>
        <div className='item-title border-r-0 col-span-1  flex justify-start items-center '>
          {
            // eslint-disable-next-line no-nested-ternary
            item.imageLoading ? (
              <img
                src='/assets/images/loading.gif'
                alt='map'
                className='w-4 h-4 items-center border border-gray-400 mx-auto'
              />
            ) : item.image !== null ? (
              <div>
                {ReactTooltip.rebuild()}
                <img
                  data-tip={`<img src=${item.image} class="w-login" />`}
                  data-html
                  data-class='p-2'
                  data-for='largeImage'
                  src={item.image}
                  alt='map'
                  className='px-3 border border-gray-400 my-2'
                />
              </div>
            ) : (
              <img src='/assets/images/no-image.png' alt='map' className='  px-3 border border-gray-400 my-2' />
            )
          }
        </div>
        <div className='item-options col-span-3 flex justify-start items-center '>
          <p>{item.title}</p>
        </div>
        <div className='item-options col-span-2 flex justify-start items-center whitespace-nowrap'>
          <p>{item.created_at}</p>
        </div>
        <div className='item-options px-2 col-span-2 flex justify-start items-center pl-4'>
          <p>{`${item.user?.first_name} ${item.user?.last_name}`}</p>
        </div>
        <div className='item-options col-span-1 flex flex-col justify-center items-start'>
          <p>
            {
              item.datasets.filter((dataset) => {
                return dataset.type === 'static'
              }).length
            }
            {` Static`}
          </p>
          <p>
            {
              item.datasets.filter((dataset) => {
                return dataset.type !== 'static'
              }).length
            }
            {` Dynamic`}
          </p>
        </div>
        <div className='item-options col-span-1 flex justify-start items-center pl-4'>
          <p>{item.public}</p>
        </div>
        <div className='item-options col-span-2 flex justify-center gap-2 items-center'>
          <div
            className='icon cursor-pointer'
            onClick={() => {
              this.props.toggleModal('shareUrlModal', {
                data: false,
                service: 'sessions',
                item,
              })
            }}
          >
            <Share />
          </div>
          <div className='icon' />
          <div
            className='icon text-primarycolor cursor-pointer'
            onClick={() => {
              this.openSession(item.code)
            }}
          >
            <DownloadCloud />
          </div>
          <div
            className='icon cursor-pointer'
            onClick={() => {
              this.props.toggleModal('deleteSessionModal', { data: false, service: 'sessions', uuid: item.uuid })
            }}
          >
            <DeleteBin />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ resetMap: state.app.view.resetMap, session: state.app.view.session })

const mapDispatchToProps = (dispatch) => ({
  setMapCode: (data) => dispatch(setMapCode(data)),
  controlLoading: (data) => dispatch(controlLoading(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionItem)
