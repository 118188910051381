import { SETTINGS } from 'constants/default-settings'
import { EXPORT_MAP_FORMATS } from 'kepler.gl/constants'
import keplerGlReducer, { uiStateUpdaters } from 'kepler.gl/reducers'

const { DEFAULT_EXPORT_MAP } = uiStateUpdaters

const KeplerReducer = keplerGlReducer
  .initialState({
    // In order to provide single file export functionality
    // we are going to set the mapbox access token to be used
    // in the exported file
    uiState: {
      readOnly: false,
      activeSidePanel: 'layer',
      // locale: LOCALE_CODES.fr,
      mapControls: {
        visibleLayers: {
          show: true,
          active: false,
        },
        toggle3d: {
          show: true,
          active: false,
        },
        splitMap: {
          show: true,
        },
        mapLegend: {
          show: true,
          active: false,
        },
        mapDraw: {
          show: true,
          active: false,
        },
        mapLocale: {
          show: true,
          active: false,
        },
        showSavedMap: {
          show: true,
          active: false,
        },
      },
      currentModal: null,
      exportMap: {
        ...DEFAULT_EXPORT_MAP,
        [EXPORT_MAP_FORMATS.HTML]: {
          ...DEFAULT_EXPORT_MAP[[EXPORT_MAP_FORMATS.HTML]],
          exportMapboxAccessToken: SETTINGS.MAPBOX_TOKEN,
        },
      },
    },
    visState: {
      // interactionConfig: {
      //   tooltip: {
      //     id: 'tooltip',
      //     label: 'interactions.tooltip',
      //     enabled: true,
      //     config: {
      //       fieldsToShow: {},
      //       compareMode: false,
      //       compareType: 'absolute',
      //     },
      //   },
      //   brush: { id: 'brush', label: 'interactions.brush', enabled: true, config: { size: 0.5 } },
      //   coordinate: { id: 'coordinate', label: 'interactions.coordinate', enabled: true, position: null },
      //   geocoder: { id: 'geocoder', label: 'interactions.geocoder', enabled: true, position: null },
      // },
      loaders: [], // Add additional loaders.gl loaders here
      loadOptions: {}, // Add additional loaders.gl loader options here
    },
    mapState: {
      zoom: 2,
      latitude: 48.9996,
      longitude: 4.7363,
    },
    mapStyle: {
      styleType: 'maplab-default-map',
      topLayerGroups: { label: true },
      visibleLayerGroups: {
        '3d building': true,
        label: true,
        road: true,
        border: true,
        building: true,
        water: true,
        land: true,
      },
    },
  })
  .plugin({
    HIDE_AND_SHOW_SIDE_PANEL: (state, action) => ({
      ...state,
      uiState: {
        ...state.uiState,
        readOnly: action.payload,
      },
      // mapStyle: {
      //   ...state.mapStyle,
      //   styleType: 'dark',
      // },
    }),
  })

export default KeplerReducer
