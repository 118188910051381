import ScreanContainer from 'components/Layout/ScreanContainer'
import Button from 'components/Portions/Button'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Text from 'components/Portions/Text'
import { Component } from 'react'
import { connect } from 'react-redux'
import { login } from 'services/userAuth'
import { setErrorHandler } from 'store/actions/errorsActions'
import { setUser } from 'store/actions/userActions'
import { controlLoading } from 'store/actions/viewActions'

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      item: {},
      errors: {},
      modalLoading: false,
    }
  }

  componentDidMount() {
    if (localStorage.getItem('user')) {
      window.location = `/maps`
    } else {
      this.props.controlLoading(false)
    }
  }

  handelchange = (e) => {
    const { item, errors } = this.state

    item[e.target.name] = e.target.value
    errors[e.target.name] = null
    this.setState({ item, errors })
  }

  login = () => {
    login({ ...this.state.item })
      .then((res) => {
        this.setState({ modalLoading: false })
        // set user to local storage
        localStorage.setItem('token', res.token)
        localStorage.setItem('user', JSON.stringify(res.user))
        localStorage.setItem('lang', res.lang || 'en')
        // set user to store
        this.props.setUser(res.data)

        window.location = `/maps`
      })
      .catch((error) => {
        this.setState({ modalLoading: false })
        switch (error.response.status) {
          case 400:
            this.setState({ errors: { password: 'Sorry the credentials you are using are invalid' } })
            break
          default:
            this.props.setErrorHandler(error.response)
            break
        }
      })
  }

  render() {
    return (
      <ScreanContainer>
        <div className='flex justify-center items-center flex-col w-login   m-auto '>
          <div className='flex justify-center items-center flex-col flex-1  mb-6'>
            <div className='mb-6'>
              <img src='/assets/images/maplab-logo.png' className='h-14' alt='logo' />
            </div>
            <Text className='text-xl mb-6 text-gray-800'>Sign in to MapLab.world</Text>
            <Text className=' hidden text-sm text-gray-600'>
              Fast and powerful online GIS for data visualization tool
            </Text>
          </div>
          <Form className='w-full' onSubmit={() => {}}>
            <FormGroup>
              <Label htmlFor='username'>Username</Label>

              <Input
                type='username'
                name='username'
                id='username'
                value={this.state.username}
                placeholder='Username'
                className='w-full px-3 py-2 border border-gray-300 rounded-sm focus:border-focused focus:shadow-input focus:outline-none'
                onChange={this.handelchange}
              />
              <InputError>{this.state.errors.username}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='password'>Password</Label>
              <Input
                type='password'
                name='password'
                id='password'
                value={this.state.password}
                placeholder='Password'
                className='w-full px-3 py-2 border border-gray-300 rounded-sm focus:border-focused focus:shadow-input focus:outline-none'
                onChange={this.handelchange}
              />
              <InputError>{this.state.errors.password}</InputError>
            </FormGroup>
            <Button
              loading={this.state.modalLoading}
              type='submit'
              width='w-full'
              className='text-white bg-primary mt-8 text-center w-full mb-3 py-2 px-3 text-sm rounded-sm hover:bg-effect transition duration-200'
              action={this.login}
            >
              Sign in
            </Button>
          </Form>

          <Text className='text-center font-bold text-dim text-xs'>
            {` Copyright &copy; 2023 `}
            <a href='//MapLab.world' className='text-black'>
              MapLab.world
            </a>
            {` All rights reserved. `}
          </Text>
        </div>
      </ScreanContainer>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  controlLoading: (data) => dispatch(controlLoading(data)),
  setUser: (data) => dispatch(setUser(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
