import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import React, { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class CreateOrganizationModal extends Component {
  constructor() {
    super()
    this.state = {
      item: {},
      errors: {},
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.loading !== state.loading ||
      JSON.stringify(props.item) !== JSON.stringify(state.item) ||
      JSON.stringify(props.errors) !== JSON.stringify(state.errors)
    ) {
      return { loading: props.loading, item: props.item, errors: props.errors }
    }
    return false
  }

  componentDidMount() {}

  render() {
    return (
      <ModalContainer className='w-smallModal  py-3' title='Create Organization'>
        <Modal loading={this.state.loading} {...this.props}>
          <Form>
            <FormGroup>
              <Label htmlFor='name'>Name* :</Label>
              <Input
                onChange={this.props.handelChange}
                id='name'
                name='name'
                placeholder='Organization name'
                value={this.state.item?.name}
              />
              <InputError>{this.state.errors?.name}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='description'>Description :</Label>
              <Input
                onChange={this.props.handelChange}
                id='description'
                name='description'
                placeholder='Organization description'
                value={this.state.item.description}
              />
              <InputError>{this.state.errors.description}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='max_users'>Max. users:</Label>
              <Input
                onChange={this.props.handelChange}
                type='number'
                id='max_users'
                name='max_users'
                placeholder='Organization maximum number of users'
                value={this.state.item.max_users}
              />
              <InputError>{this.state.errors.max_users}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='max_maps'>Max. maps:</Label>
              <Input
                onChange={this.props.handelChange}
                type='number'
                id='max_maps'
                name='max_maps'
                placeholder='Organization maximum number of maps'
                value={this.state.item.max_maps}
              />
              <InputError>{this.state.errors.max_maps}</InputError>
            </FormGroup>
            <FormGroup>
              <Label htmlFor='expired_date'>Expiration date:</Label>
              <Input
                onChange={this.props.handelChange}
                type='date'
                id='expired_date'
                name='expired_date'
                value={this.state.item.expired_date}
              />
              <InputError>{this.state.errors.expired_date}</InputError>
            </FormGroup>
          </Form>
        </Modal>
      </ModalContainer>
    )
  }
}
