/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import en from 'kepler.gl/dist/localization/en'
import { flattenMessages } from 'kepler.gl/dist/utils/locale-utils'
import { LOCALE_CODES } from 'translations/locales'

const enFlat = flattenMessages(en)

export const messages = Object.keys(LOCALE_CODES).reduce(
  (acc, key) => ({
    ...acc,
    [key]: key === 'en' ? enFlat : { ...enFlat, ...flattenMessages(require(`./${key}`).default) },
  }),
  {},
)

export { default as FormattedMessage } from 'kepler.gl/dist/localization/formatted-message'
export { LOCALES, LOCALE_CODES } from 'translations/locales'
