import { SourceDataCatalogFactory } from 'kepler.gl/components'
import { SidePanelSection } from 'kepler.gl/dist/components/common/styled-components'
import React from 'react'
import styled from 'styled-components'

const SourceDataCatalogWrapper = styled.div`
  transition: ${(props) => props.theme.transition};
`

CustomSourceDataCatalogFactory.deps = SourceDataCatalogFactory.deps

function CustomSourceDataCatalogFactory(DatasetTitle, DatasetInfo) {
  // eslint-disable-next-line react/prop-types
  const SourceDataCatalog = ({
    datasets,
    showDatasetTable,
    removeDataset,
    onTitleClick,
    showDeleteDataset = false,
  }) => (
    <SourceDataCatalogWrapper className='source-data-catalog'>
      {Object.values(datasets)
        .filter((ds) => ds.type !== 'geojson')
        .map((dataset) => (
          <SidePanelSection key={dataset.id}>
            <DatasetTitle
              showDatasetTable={showDatasetTable}
              showDeleteDataset={showDeleteDataset}
              removeDataset={removeDataset}
              dataset={dataset}
              onTitleClick={onTitleClick}
            />
            {showDatasetTable ? <DatasetInfo dataset={dataset} /> : null}
          </SidePanelSection>
        ))}
    </SourceDataCatalogWrapper>
  )

  return React.memo(SourceDataCatalog)
}

export function replaceSourceDataCatalog() {
  return [SourceDataCatalogFactory, CustomSourceDataCatalogFactory]
}
