/* eslint-disable react/prefer-stateless-function */

import CheckBox from 'components/Portions/CheckBox'
import Form from 'components/Portions/Form'
import FormGroup from 'components/Portions/FormGroup'
import Label from 'components/Portions/Label'
import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { cleanupExportImage, setExportImageSetting } from 'kepler.gl/actions'
import ImagePreview from 'kepler.gl/dist/components/common/image-preview'
import ImageModalContainer from 'kepler.gl/dist/components/modals/image-modal-container'
import { Component } from 'react'
import { connect } from 'react-redux'

class ExportImage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      exportImage: {},
      item: { mapH: 768, mapW: 1024, ratio: 'CUSTOM', resolution: 'ONE_X', legend: false },
      screens: {
        CUSTOM: { mapH: window.screen.height, mapW: window.screen.width, ratio: 'CUSTOM' },
        '16:9': { mapH: 900, mapW: 1200, ratio: '16:9' },
        '4:3': { mapH: 768, mapW: 1024, ratio: '4:3' },
      },
      resolution: { ONE_X: '1x', TWO_X: '2x' },
      errors: {},
      modalLoading: true,
    }
  }

  componentDidMount() {
    this.props.onUpdateImageSetting({ ...this.state.item, ...this.state.screens.CUSTOM })
  }

  static getDerivedStateFromProps = (props, state) => {
    if (JSON.stringify(props.exportImage) !== JSON.stringify(state.exportImage)) {
      return { exportImage: props.exportImage, modalLoading: props.exportImage.processing }
    }
    return false
  }

  save = () => {
    const a = document.createElement('a')
    a.href = `${this.state.exportImage?.imageDataUri}`
    a.download = 'MapLab-world.png'
    a.click()
    a.remove()
  }

  handelChange = (e) => {
    const { item, errors, screens } = this.state
    item[e.target.name] = e.target.value

    errors[e.target.name] = null

    this.props.onUpdateImageSetting({ ...item, ...screens[item.ratio] })
    this.setState({ item, errors })
  }

  render() {
    return (
      <ModalContainer close={this.props.close} className='w-mediumModal px-6 py-3' title='Export Image'>
        <div className='my-4 flex justify-between items-center text-sm'>
          <p>Export an image of your map</p>
        </div>
        <Modal
          disable={this.state.modalLoading}
          height={400}
          action={{ text: 'Export', handler: this.save }}
          cancel={{ text: 'Cancel', handler: this.props.close }}
        >
          <div className='grid grid-cols-12'>
            <Form className='mt-8 col-span-5'>
              <FormGroup className='' horizontal>
                <div className='flex  flex-col items-start justify-start'>
                  <Label htmlFor='dataset'>Ratio</Label>
                  <Text className='text-xxs text-gray-500 my-2'>Choose the ratio for various usages.</Text>

                  <div className=' flex justify-start items-center gap-2 '>
                    {Object.keys(this.state.screens).map((x) => {
                      return (
                        <div
                          onClick={() => {
                            this.handelChange({ target: { name: 'ratio', value: x } })
                          }}
                          key={x}
                          className={`${
                            this.state.item?.ratio === x ? 'border-primarycolor bg-gray-100' : 'border-gray-200'
                          } px-2 py-2 border-2  rounded-sm cursor-pointer `}
                        >
                          <Text>{x === 'CUSTOM' ? 'Original Screen' : x}</Text>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {/* <InputError>{this.state.errors.dataset}</InputError> */}
              </FormGroup>
              <FormGroup className='mt-6 mb-6' horizontal>
                <div className='flex  flex-col items-start justify-start'>
                  <Label htmlFor='dataset'>Resolution</Label>
                  <Text className='text-xxs text-gray-500 my-2'>High resolution is better for prints.</Text>

                  <div className=' flex justify-start items-center gap-2 '>
                    {Object.keys(this.state.resolution).map((x) => {
                      return (
                        <div
                          onClick={() => {
                            this.handelChange({ target: { name: 'resolution', value: x } })
                          }}
                          key={x}
                          className={`${
                            this.state.item?.resolution === x ? 'border-primarycolor bg-gray-100' : 'border-gray-200'
                          } px-4 py-2 border-2  rounded-sm cursor-pointer `}
                        >
                          <Text>{this.state.resolution[x]}</Text>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </FormGroup>
              <FormGroup className='' horizontal>
                <div className='flex  flex-col items-start justify-start'>
                  <Label htmlFor='legend'>Map legend</Label>
                  <div className='flex items-center justify-start gap-2'>
                    <CheckBox name='legend' onChange={this.handelChange} checked={this.state.item.legend} />
                    <Label>Add legend on map</Label>
                  </div>
                </div>
              </FormGroup>
            </Form>
            <div className='col-span-7 justify-center items-center flex'>
              <ImageModalContainer
                cleanupExportImage={this.props.cleanupExportImage}
                onUpdateImageSetting={this.props.onUpdateImageInit}
              >
                <ImagePreview exportImage={this.state.exportImage} width={400} showDimension={false} />
              </ImageModalContainer>
            </div>
          </div>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  keplerInstance: state.keplerGl?.MapLab,
  exportImage: state.keplerGl?.MapLab.uiState.exportImage,
  datasets: state.app.view.datasets,
  session: state.app.view.session,
})
const mapDispatchToProps = (dispatch) => ({
  onUpdateImageSetting: (data) => dispatch(setExportImageSetting(data)),
  onUpdateImageInit: (data) =>
    dispatch(setExportImageSetting({ ...data, mapH: window.screen.height, mapW: window.screen.width })),
  cleanupExportImage: () => dispatch(cleanupExportImage()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportImage)
