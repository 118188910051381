/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prefer-stateless-function */
import Icon from 'components/Portions/Icon'
import Input from 'components/Portions/Input'
import { Minus } from 'kepler.gl/dist/components/common/icons'
import React, { Component } from 'react'
import ScrollArea from 'react-scrollbar'

class SelectDoubleCoordColumn extends Component {
  constructor(props) {
    super(props)
    this.listRef = React.createRef()

    this.state = {
      types: {
        string: { name: 'string', color: '#8cd2cd' },
        str: { name: 'string', color: '#8cd2cd' },
        int: { name: 'integer', color: '#f8c21c' },
        integer: { name: 'integer', color: '#f8c21c' },
        decimal: { name: 'float', color: '#f8c21c' },
        float: { name: 'float', color: '#f8c21c' },
        geometry: { name: 'geo', color: '#6aa0ce' },
        boolean: { name: 'boolean', color: '#8cd2cd' },
        time: { name: 'time', color: '#6aa038' },
        date: { name: 'date', color: '#6aa038' },
        datetime: { name: 'datetime', color: '#6aa038' },
        timestamp: { name: 'timestamp', color: '#6aa038' },
      },

      opened: false,
      columnUuid: null,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.activeFunction) !== JSON.stringify(state.activeFunction)) {
      return { activeFunction: props.activeFunction }
    }
    return null
  }

  handleSearchChange = (e) => {
    this.setState({ query: e.target.value })
  }

  // eslint-disable-next-line consistent-return
  toggleMenu = (columnUuid) => {
    this.setState({ opened: !this.state.opened, columnUuid })
  }

  hexToRGB = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }

  handleClickOutside = (e) => {
    if (this.listRef && !this.listRef.current?.contains(e.target)) {
      this.setState({ opened: false })
    }
  }

  render() {
    return (
      <div ref={this.listRef} className='relative grid grid-cols-12 w-full'>
        <div
          className={`${
            this.props.error ? 'bg-red-200' : 'bg-menu'
          } w-full  h-full px-1 pt-1 hover:bg-tagHoverColor col-span-11`}
        >
          <div className='flex flex-wrap'>
            {this.props.execute.data.map((x) => {
              return (
                <div
                  key={x.id}
                  onClick={() => {
                    this.toggleMenu(x.uuid)
                  }}
                  className=' mb-1 mr-1 hover:text-white  text-gray-400 px-2 py-1 text-xs   bg-tagColor cursor-pointer'
                >
                  {`${x.name} - ${x.columnName || 'Not selected'}`}
                </div>
              )
            })}
          </div>
          {this.state.opened && (
            <div className=' absolute -ml-2 z-10 bg-primary  w-66 '>
              <div className='flex justify-between items-center bg-search py-2  mt-2 text-xxs px-2 mx-2'>
                <Input
                  onChange={this.handleSearchChange}
                  name='search'
                  value={this.state.query}
                  placeholder='Search columns'
                  className='bg-transparent w-full focus:outline-none text-gray-400'
                />
                <Icon className='text-white w-4 h-4 rounded-sm'>
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <circle cx='10' cy='10' r='7' />
                  <line x1='21' y1='21' x2='15' y2='15' />
                </Icon>
              </div>
              <div className=' w-full text-gray-400 my-2 text-xxs'>
                <ScrollArea style={{ maxHeight: 200 }} smoothScrolling>
                  {this.props
                    .getColumns(
                      this.props.activeFunction.request.database,
                      this.props.activeFunction.request.table,
                      this.props.execute.data.map((x) => {
                        return x.columnUuid
                      }),
                      this.props.column.types,
                    )
                    ?.map((column) => {
                      return (
                        <div
                          key={column.id}
                          onClick={() => {
                            this.toggleMenu(null)
                            this.props.selectForColumn(
                              this.props.activeFunctionUuid,
                              this.props.functionkey,
                              this.props.executeIndex,
                              this.state.columnUuid,
                              column,
                            )
                          }}
                          className='px-2  hover:bg-droplist cursor-pointer flex justify-start items-center gap-2'
                        >
                          <div
                            style={{
                              background: this.hexToRGB(this.state.types[column.type].color, 0.2),
                              color: this.state.types[column.type].color,
                              borderColor: this.state.types[column.type].color,
                              borderWidth: 1,
                            }}
                            className=' py-px border w-14 text-center rounded-sm my-1'
                          >
                            {this.state.types[column.type].name}
                          </div>
                          <p>{column.name}</p>
                        </div>
                      )
                    })}
                </ScrollArea>
              </div>
            </div>
          )}
        </div>
        <div className='col-span-1  ml-2 pt-3 text-primarycolor  cursor-pointer'>
          <Minus
            onClick={() => {
              this.props.removeExecute(this.props.activeFunctionUuid, this.props.functionkey, this.props.executeIndex)
            }}
            className='w-3'
          />
        </div>
      </div>
    )
  }
}

export default SelectDoubleCoordColumn
