import Label from 'components/Portions/Label'
import { Check, ErrorX } from 'components/images'

const PasswordChecker = ({ styles, label, checker }) => {
  return (
    <div style={styles} className='items-center flex col-span-1 cursor-pointer'>
      {checker ? <Check dark normalSize active /> : <ErrorX dark normalSize active />}
      {label && <Label className={`${checker ? 'text-green-600' : 'text-red-600'} ml-1 text-xs`}>{label}</Label>}
    </div>
  )
}

export default PasswordChecker
