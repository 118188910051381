import { useEffect, useMemo, useState } from 'react'
// import { FormattedMessage } from 'translations'
import RadioButton from 'components/Portions/RadioButton'
import { MultiSelectFilterFactory } from 'kepler.gl/components'
import ItemSelector from 'kepler.gl/dist/components/common/item-selector/item-selector'
import styled from 'styled-components'

export const PanelLabel = styled.label.attrs({
  className: 'side-panel-panel__label',
})`
  color: ${(props) => props.theme.labelColor};
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 4px;
  text-transform: capitalize;
`

CustomMultiSelectFilterFactory.deps = MultiSelectFilterFactory.deps

function CustomMultiSelectFilterFactory() {
  const MultiSelectFilter = ({ idx, filter, initFilter, setFilter, setReverse, resetSelection }) => {
    const [checked, setChecked] = useState(null)

    useEffect(() => {
      if (filter?.reverse) {
        setChecked('exclude')
        return
      }
      if ((filter?.reverseValue?.length === 0 || !filter?.reverseValue) && filter.value.length === 0) {
        setChecked('all')
        return
      }
      setChecked('include')
    }, [])

    useEffect(() => {
      if (Array.isArray(filter?.value)) {
        if (filter?.value?.length > 0 && checked === 'all' && !filter?.reverse) {
          setChecked('include')
        }
        return
      }
      if (filter?.reverseValue?.length && checked === 'all' && filter?.reverse) {
        setChecked('exclude')
      }
    }, [filter?.reverseValue, filter?.value])

    const handleReverse = (e) => {
      if (filter?.reverseValue?.length === 0 && filter?.value?.length === 0) {
        handleAll({ target: { value: 'all' } })
        return
      }
      setChecked(e?.target?.value)
      setReverse(e?.target?.value !== 'exclude')
    }

    const handleAll = (e) => {
      if (checked === 'all') {
        return
      }
      setChecked(e?.target?.value)
      resetSelection()
    }

    // Drop down available selection options
    const options = useMemo(() => {
      return filter.domain?.filter((item) =>
        filter?.reverse
          ? !filter?.reverseValue?.find((resVal) => resVal === item)
          : !filter?.value?.find((val) => val === item),
      )
    }, [filter?.reverseValue, filter?.value])

    useEffect(() => {
      if (checked === 'exclude') {
        /**
         * By default, Kepler uses include functionality. This means that if you don't have any selection, it will show you the map as it is
         * (everything visible - filter func disabled).
         * If you make at least one selection it will enable the filter functionality & will show you only that one by hiding all the others.
         * If you select all the options, visually, it will be in the exact state of the empty selection (It will show you all the available data on the map)
         * So, If you start doing the opposite (deleting one by one the selected options) you'll see that it will come to the last one (only one item shown on the map),
         * and if you also delete that, it will show you all the map as it is (filtering func disabled). By using the include & exclude functionality,
         * we alternate the arrays between "filter.value" and "filter.reverseValue" keys. At the time that all options are selected (e.g. 10 options) in the "include" mode
         * the "filter.value" array has length 10 and the "filter.reverseValue" has length 0. If we click on the "exclude" mode, we have to alternate the arrays and
         * make "filter.value" 0 & "filter.reverseValue" 10. But by default Kepler only watches the "filter.value" and if it sees that its value is zero, will disable filter
         * functionality and will show you all the data. To avoid that we use the "maplab_dummy" value as selected value in for "filter.value" key. By doing that, the "filter.value"
         * will always have a value without being able to find it on the map (filter func still enabled) -- THIS IS A HACKY WAY, NOT GOOD ONE BUT AT THE MONENT WORKS.
         */
        if (options?.length === 0 && (filter?.value?.length === 0 || filter?.value[0] !== 'maplab_dummy')) {
          setFilter(idx, 'value', ['maplab_dummy'])
        }
        if (options?.length === filter.domain?.length) {
          handleAll({ target: { value: 'all' } })
        }
      }
      if (checked === 'include') {
        // This will happens if users starts deleting one by one the selected options
        // and no selected option is available
        if (options?.length === filter.domain?.length) {
          handleAll({ target: { value: 'all' } })
        }
      }
    }, [options])

    return (
      <div>
        <PanelLabel htmlFor={`filter-${filter.id}`} className='inline-flex items-center w-full'>
          {/* <div className='full-flex'>
            <FormattedMessage id='misc.valuesIn' />
          </div> */}
          <RadioButton
            smallSize
            dark
            name='include'
            value='include'
            label='Include'
            checked={checked === 'include'}
            onChange={handleReverse}
            disabled={!filter?.value || filter?.value?.length === 0}
          />
          <RadioButton
            styles={{ marginLeft: '16px' }}
            smallSize
            dark
            name='exclude'
            value='exclude'
            label='Exclude'
            checked={checked === 'exclude'}
            onChange={handleReverse}
            disabled={!filter?.value || filter?.value?.length === 0}
          />
          <RadioButton
            styles={{ marginLeft: '16px' }}
            smallSize
            dark
            name='all'
            value='all'
            label='All'
            checked={checked === 'all'}
            onChange={handleAll}
          />
        </PanelLabel>
        <ItemSelector
          options={options}
          selectedItems={filter?.reverse ? filter?.reverseValue : filter.value}
          onChange={initFilter}
        />
      </div>
    )
  }
  return MultiSelectFilter
}

export default CustomMultiSelectFilterFactory
