import ManageDatabases from 'components/Management/Database/ManageDatabases'
import ManageOrganisations from 'components/Management/Organisation/ManageOrganisations'
import ManageSessions from 'components/Management/Sessions/ManageSessions'
import Agreement from 'components/Modals/Agreement/Agreement'
import ExportData from 'components/Modals/Export/ExportData'
import ExportImage from 'components/Modals/Export/ExportImage'
import ResetMap from 'components/Modals/ResetMap/ResetMap'
import SaveSession from 'components/Modals/SaveSession/SaveSession'
import Settings from 'components/Modals/Settings/Settings'
import UserGuide from 'components/Modals/UserGuide/UserGuide'
import UserHelp from 'components/Modals/UserHelp/UserHelp'
import { Component } from 'react'
import { connect } from 'react-redux'
import { setToggleModal } from 'store/actions/viewActions'

// eslint-disable-next-line react/prefer-stateless-function
class ModalManger extends Component {
  constructor() {
    super()
    this.state = {
      manageDataBase: false,
      manageOrganisations: false,
      manageSessions: false,
      resetMap: false,
      saveSession: false,
      userGuide: false,
      exportData: false,
      exportImage: false,
      settings: false,
      agreement: false,
      help: false,
    }
  }

  componentDidMount() {
    this.props.setToggleModal(this.toggleModal)
  }

  toggleModal = (modal, data = {}) => {
    console.log(modal)
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ [modal]: !this.state[modal], data })
  }

  render() {
    return (
      <>
        {this.state.settings && (
          <Settings
            close={() => {
              this.toggleModal('settings')
            }}
          />
        )}
        {this.state.exportImage && (
          <ExportImage
            close={() => {
              this.toggleModal('exportImage')
            }}
          />
        )}
        {this.state.exportData && (
          <ExportData
            close={() => {
              this.toggleModal('exportData')
            }}
          />
        )}
        {this.state.userGuide && (
          <UserGuide
            close={() => {
              this.toggleModal('userGuide')
            }}
          />
        )}
        {this.state.userHelp && (
          <UserHelp
            close={() => {
              this.toggleModal('userHelp')
            }}
          />
        )}
        {this.state.manageDataBase && (
          <ManageDatabases
            close={() => {
              this.toggleModal('manageDataBase')
            }}
          />
        )}
        {this.state.resetMap && (
          <ResetMap
            close={() => {
              this.toggleModal('resetMap')
            }}
          />
        )}
        {this.state.manageOrganisations && (
          <ManageOrganisations
            close={() => {
              this.toggleModal('manageOrganisations')
            }}
          />
        )}
        {this.state.manageSessions && (
          <ManageSessions
            close={() => {
              this.toggleModal('manageSessions')
            }}
          />
        )}
        {this.state.saveSession && (
          <SaveSession
            close={() => {
              this.toggleModal('saveSession')
            }}
          />
        )}
        {this.state.agreement && (
          <Agreement
            close={() => {
              this.toggleModal('agreement')
            }}
          />
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  setToggleModal: (data) => dispatch(setToggleModal(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ModalManger)
