/* eslint-disable linebreak-style */
import axios from 'axios'

const gogleService = axios.create({
  headers: {
    'Access-Control-Allow-Origin': ['http://localhost:3000'],
    'Content-Type': 'application/octet-stream',
  },
})

gogleService.interceptors.request.use((config) => {
  /* let loadinBar = document.getElementById("loadinBar");
  let bar = loadinBar.querySelector("span");

  bar.classList.remove("w-3/4");
  bar.classList.add("w-full");

  setTimeout(() => {
    document.body.classList.remove("load");
  }, 500); */

  return config
})

gogleService.interceptors.request.use((response) => {
  /* document.body.classList.add("load");

  let loadinBar = document.getElementById("loadinBar");
  let bar = loadinBar.querySelector("span");

  setTimeout(() => {
    bar.classList.add("w-1/4");
  }, 100);
  setTimeout(() => {
    bar.classList.remove("w-1/4");
    bar.classList.add("w-2/4");
  }, 800);
  setTimeout(() => {
    bar.classList.remove("w-2/4");
    bar.classList.add("w-3/4");
  }, 1500); */

  return response
})

export default gogleService
