import React, { Component } from 'react'

export default class Text extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <div className={`  ${!this.props.className ? 'text-black text-xs' : this.props.className}`}>
        {this.props.children}
      </div>
    )
  }
}
