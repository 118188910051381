/* eslint-disable react/prefer-stateless-function */
import DropList from 'components/Portions/DropList'
import FormGroup from 'components/Portions/FormGroup'
import InputError from 'components/Portions/InputError'
import Label from 'components/Portions/Label'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'
import SqlEditor from 'react-sql-editor'
import { setErrorHandler } from 'store/actions/errorsActions'

class WithQuery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      item: {},
      errors: {},
      modalLoading: false,
    }
  }

  componentDidMount() {
    this.setState({ item: this.props.item })
  }

  setSqlQuery = (query) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ item: { ...this.state.item, query } })
  }

  save = () => {
    const { database, table, query } = this.state.item
    this.props.getQueryData(this.props.uuid, database, table, query)
    this.props.toggleWithQuery()
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value

    errors[e.target.name] = null

    this.setState({ item, errors })
  }

  render() {
    return (
      <ModalContainer className='w-smallModal  py-3' title='SQL Query to Database'>
        <Modal
          disable={!this.state.item.database || !this.state.item.table || !this.state.item.query}
          loading={this.state.modalLoading}
          action={{ handler: this.save, text: 'Save', className: 'rounded-sm databaseButton' }}
          {...this.props}
        >
          <FormGroup vertical>
            <Label htmlFor='database'>Database</Label>
            <DropList
              options={this.props.databases.map((x) => {
                return { value: x.uuid, text: x.title }
              })}
              name='database'
              onChange={(e) => {
                // this.updateInputs(e.target.value)
                this.handelChange(e)
              }}
              id='database'
              placeholder='Select database'
              value={this.state.item.database}
            />
            <InputError>{this.state.errors.database}</InputError>
          </FormGroup>
          <FormGroup vertical>
            <Label htmlFor='table'>Table</Label>

            <DropList
              options={this.props.databases
                .find((y) => {
                  return y.uuid === this.state?.item?.database
                })
                ?.tables.map((x) => {
                  return { value: x.uuid, text: x.title }
                })}
              name='table'
              onChange={(e) => {
                // this.updateInputs(e.target.value)
                this.handelChange(e)
              }}
              id='table'
              placeholder='Select table'
              value={this.state.item.table}
            />
            <InputError>{this.state.errors.table}</InputError>
          </FormGroup>

          <p className='my-1'>Sql Editor</p>
          <SqlEditor
            title='Sql Editor'
            width='auto'
            height='200px'
            isShowHeader
            value={this.state.item.query}
            enableBasicAutocompletion
            debounceChangePeriod={500}
            onChange={(e) => {
              this.setSqlQuery(e)
            }}
            validatorConfig={{
              maxSqlNum: 1,
            }}
          />
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  databases: state.app.view.databases,
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WithQuery)
