import {
  ExportData,
  ExportImage,
  Gallery,
  ManageDatabases,
  ManageOrganisations,
  SaveMaps,
  SavedMaps,
  StudioMap,
} from 'components/images'

export const mapOptions = [
  {
    key: 'manageSessions',
    title: 'Saved maps',
    icon: SavedMaps,
  },
  {
    key: 'saveSession',
    title: 'Save map',
    icon: SaveMaps,
  },
  {
    key: 'exportData',
    title: 'Export data',
    icon: ExportData,
  },
  {
    key: 'exportImage',
    title: 'Export image',
    icon: ExportImage,
  },
  {
    key: 'manageDataBase',
    title: 'Manage databases',
    icon: ManageDatabases,
  },
  {
    key: 'manageOrganisations',
    title: 'Manage organisations',
    icon: ManageOrganisations,
  },
  {
    key: 'maps-gallery',
    title: 'Maps Gallery',
    icon: Gallery,
    link: '/gallery',
  },
]

export const galleryOptions = [
  {
    key: 'manageDataBase',
    title: 'Manage databases',
    icon: ManageDatabases,
  },
  {
    key: 'manageOrganisations',
    title: 'Manage organisations',
    icon: ManageOrganisations,
  },
  {
    key: 'studio',
    title: 'Map studio',
    icon: StudioMap,
    link: '/maps',
  },
]
