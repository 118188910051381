import { combineReducers } from 'redux'
import { errorsReducer } from 'store/reducers/appReducer/errorsReducer'
import { userReducer } from 'store/reducers/appReducer/userReducer'
import { viewReducer } from 'store/reducers/appReducer/viewReducer'

const AppReducer = combineReducers({
  errors: errorsReducer,
  view: viewReducer,
  user: userReducer,
})

export default AppReducer
