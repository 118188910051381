/* eslint-disable react/no-unused-state */
import * as turf from '@turf/turf'
import DatasetsList from 'components/FunctionData/DatasetsList'
import FunctionsList from 'components/FunctionData/FunctionsList'
import DeleteFunction from 'components/FunctionData/Modals/DeleteFunction'
import SelectDoubleCoordColumn from 'components/FunctionData/SelectDoubleCoordColumn'
import SelectSingleColumn from 'components/FunctionData/SelectSingleColumn'
import SelectSingleOption from 'components/FunctionData/SelectSingleOption'
import Icon from 'components/Portions/Icon'
import Input from 'components/Portions/Input'
import InputError from 'components/Portions/InputError'
import Text from 'components/Portions/Text'
import { addDataToMap, removeDataset, wrapTo } from 'kepler.gl/actions'
import { Add, Play, Trash } from 'kepler.gl/dist/components/common/icons'
import { Button } from 'kepler.gl/dist/components/common/styled-components'
import { processCsvData, processGeojson, processRowObject } from 'kepler.gl/processors'
import KeplerGlSchema from 'kepler.gl/schemas'
import { Component } from 'react'
import Slider from 'react-rangeslider'
import { connect } from 'react-redux'
import { datasetFunction } from 'services/dataset'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'
import { addDataset, addFunction, deleteFunction, updateFunction } from 'store/actions/viewActions'
import { v4 as uuidv4 } from 'uuid'

class FunctionData extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteFunction: false,
      selected: null,
      polygonToCellsLoading: false,
      functions: [
        {
          id: 1,
          name: 'Aggregate trips',
          action: 'aggregateTrips',
          beta: true,
          drivers: ['snowflake', 'googleBigQuery'],
          data: {
            pickup: {
              title: 'Pickup',
              requird: true,
              withFunction: false,
              minimum: 1,
              types: ['float', 'decimal', 'integer', 'int'],
              functionType: 'twoColumnWithCoord',
              options: [{ name: 'lat' }, { name: 'lon' }],
            },
            resolution: {
              functionType: 'slider',
              name: 'resolution',
              title: 'Resolution',
              default: 5,
              min: 0,
              max: 15,
              helpIndex: 1,
              helpText: '',
              helpFunction: this.resolutionHelp,
            },
            dropoff: {
              title: 'Dropoff',
              requird: true,
              withFunction: false,
              minimum: 1,
              types: ['float', 'decimal', 'integer', 'int'],
              functionType: 'twoColumnWithCoord',
              options: [{ name: 'lat' }, { name: 'lon' }],
            },
          },
        },
        {
          id: 2,
          name: 'Aggregate and group',
          action: 'aggregateAndGroup',
          beta: true,
          drivers: ['snowflake', 'googleBigQuery'],
          data: {
            hex: {
              title: 'Group by hex',
              requird: true,
              minimum: 1,
              withFunction: false,
              types: ['float', 'decimal', 'integer', 'int'],
              functionType: 'twoColumnWithCoord',
              options: [{ name: 'lat' }, { name: 'lon' }],
            },
            resolution: {
              functionType: 'slider',
              name: 'resolution',
              title: 'Resolution',
              default: 5,
              min: 0,
              max: 15,
              helpIndex: 1,
              helpText: '',
              helpFunction: this.resolutionHelp,
            },
            groupBy: {
              requird: false,
              allowNone: true,
              withFunction: true,
              minimum: 0,
              title: 'group by',
              functionType: 'columnWithList',
              types: ['timestamp', 'datetime', 'date', 'time', 'str', 'int', 'float', 'decimal', 'boolean'],
              options: [{ name: 'column' }],
              typeOptions: {
                timestamp: [
                  ['year', 'timestamp'],
                  ['quarter', 'timestamp'],
                  ['month', 'timestamp'],
                  ['day', 'timestamp'],
                  ['week', 'timestamp'],
                  ['dayofweek', 'timestamp'],
                  ['hour', 'timestamp'],
                  ['minute', 'timestamp'],
                  ['second', 'timestamp'],
                  ['millisecond', 'timestamp'],
                ],
                datetime: [
                  ['year', 'datetime'],
                  ['quarter', 'datetime'],
                  ['month', 'datetime'],
                  ['day', 'datetime'],
                  ['week', 'datetime'],
                  ['dayofweek', 'datetime'],
                  ['hour', 'datetime'],
                  ['minute', 'datetime'],
                  ['second', 'datetime'],
                  ['millisecond', 'datetime'],
                ],
                date: [
                  ['year', 'date'],
                  ['quarter', 'date'],
                  ['month', 'date'],
                  ['day', 'date'],
                  ['week', 'date'],
                  ['dayofweek', 'date'],
                ],
                time: [
                  ['hour', 'time'],
                  ['minute', 'time'],
                  ['second', 'time'],
                  ['millisecond', 'time'],
                ],
                str: [['none', 'str']],
                int: [['none', 'int']],
                float: [
                  ['none', 'float'],
                  ['floor', 'float'],
                  ['round', 'float'],
                  ['ceil', 'float'],
                ],
                decimal: [
                  ['none', 'decimal'],
                  ['floor', 'decimal'],
                  ['round', 'decimal'],
                  ['ceil', 'decimal'],
                ],
                boolean: [['none', 'boolean']],
              },
            },
            aggregateBy: {
              requird: false,
              allowNone: false,
              withFunction: true,
              minimum: 0,
              title: 'aggregated fields',
              functionType: 'columnWithList',
              types: ['timestamp', 'datetime', 'date', 'time', 'str', 'int', 'float', 'decimal', 'boolean'],
              options: [{ name: 'column' }],
              typeOptions: {
                timestamp: [
                  ['count', 'timestamp'],
                  ['count_distinct', 'timestamp'],
                  ['min', 'timestamp'],
                  ['max', 'timestamp'],
                ],
                datetime: [
                  ['count', 'datetime'],
                  ['count_distinct', 'datetime'],
                  ['min', 'datetime'],
                  ['max', 'datetime'],
                ],
                date: [
                  ['count', 'date'],
                  ['count_distinct', 'date'],
                  ['min', 'date'],
                  ['max', 'date'],
                ],
                time: [
                  ['count', 'time'],
                  ['count_distinct', 'time'],
                  ['min', 'time'],
                  ['max', 'time'],
                ],
                str: [
                  ['count', 'str'],
                  ['count_distinct', 'str'],
                  ['min', 'str'],
                  ['max', 'str'],
                ],
                int: [
                  ['count', 'int'],
                  ['count_distinct', 'int'],
                  ['min', 'int'],
                  ['max', 'int'],
                  ['avg', 'int'],
                  ['sum', 'int'],
                ],
                float: [
                  ['count', 'float'],
                  ['count_distinct', 'float'],
                  ['min', 'float'],
                  ['max', 'float'],
                  ['avg', 'float'],
                  ['sum', 'float'],
                ],
                decimal: [
                  ['count', 'decimal'],
                  ['count_distinct', 'decimal'],
                  ['min', 'decimal'],
                  ['max', 'decimal'],
                  ['avg', 'decimal'],
                  ['sum', 'decimal'],
                ],
                boolean: [
                  ['count', 'boolean'],
                  ['count_distinct', 'boolean'],
                  ['min', 'boolean'],
                  ['max', 'boolean'],
                  ['avg', 'boolean'],
                  ['sum', 'boolean'],
                ],
              },
            },
          },
        },
      ],
      items: [],
      datasets: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.functions) !== JSON.stringify(state.items) ||
      JSON.stringify(props.datasets) !== JSON.stringify(state.datasets)
    ) {
      return { items: props.functions, datasets: props.datasets }
    }
    return false
  }

  // v3
  selectFunction = (uuid, selectedFunction) => {
    this.state.items.forEach((x) => {
      if (x.uuid === uuid) {
        // rester error
        x.selectedFunctionError = false
        // TODO stop change columns values if same function type
        x.request = { ...x.request, columns: this.updateColumns(selectedFunction), function: selectedFunction }
        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  resolutionHelp = (res) => {
    const bbox = this.getBbox()

    const polygon = turf.polygon([
      [
        [bbox[0].min_lon, bbox[0].min_lat],
        [bbox[0].max_lon, bbox[0].min_lat],
        [bbox[0].max_lon, bbox[0].max_lat],
        [bbox[0].min_lon, bbox[0].max_lat],
        [bbox[0].min_lon, bbox[0].min_lat],
      ],
    ])

    const area = turf.area(polygon) / 509262593023557

    const zoomData = [
      110, 830, 5870, 41150, 288110, 2016830, 14117870, 98825150, 691776110, 4842432830, 33897029870, 237279209150,
      1660954464110, 11626681248830, 81386768741870, 569707381193150,
    ]

    return `Map will generate max ${this.kFormatter(Math.floor(area * zoomData[res]))} H3 hex`
  }

  kFormatter = (num) => {
    return Math.abs(num) > 999
      ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)} K`
      : Math.sign(num) * Math.abs(num)
  }

  // v3
  selectDataset = (uuid, selectedDataset) => {
    const datasets = KeplerGlSchema.getDatasetToSave(this.props.keplerInstance)
    this.state.items.forEach((x) => {
      if (x.uuid === uuid) {
        // reset error
        x.selectedDatasetError = false

        const findDataSet = datasets.find((y) => y.data.id === selectedDataset.info.id)
        const findDatabase = this.props.databases.find((y) => {
          return y.uuid === selectedDataset.request.database
        })

        if (findDataSet && findDatabase) {
          this.props.updateFunction(x.uuid, {
            ...x,
            respond: {
              color: findDataSet.data.color,
              driver: findDatabase.driver,
              database: selectedDataset.respond.database,
              table: selectedDataset.respond.table,
            },
            request: {
              ...x.request,
              query: selectedDataset.respond.query,
              type: 'dataset',
              filters: {},
              columns: this.updateColumns(x.request.function),
              withQuery: false,
              dataset: selectedDataset.info,
              database: selectedDataset.respond.database.uuid,
              table: selectedDataset.respond.table.uuid,
            },
          })
        } else {
          this.props.showMessage({
            messageType: 'ERROR',
            message: '2000 - Application error please try again',
          })
        }
      }
    })
  }

  // v3
  createFunction = () => {
    const uuid = uuidv4()
    this.props.addFunction({
      [uuid]: {
        uuid,

        respond: {
          active: false,
          runing: false,
          color: [125, 125, 125],
          database: null,
          table: null,
        },
        request: {
          query: null,
          columns: {},
          filters: {},
          withQuery: false,
          database: null,
          table: null,
          jsonTree: {},
          filterConjunction: 'AND',
          dataset: null,
          function: null,
        },
        selectedDatasetError: null,
        selectedFunctionError: null,
        selectedColumnError: null,
      },
    })
  }

  // v3
  updateColumns = (selectedFunction) => {
    const columns = {}
    if (!selectedFunction) {
      return columns
    }
    Object.keys(selectedFunction.data).forEach((s) => {
      if (selectedFunction.data[s].functionType === 'slider') {
        columns[s] = {
          ...selectedFunction.data[s],
          helpText: selectedFunction.data[s].helpFunction(selectedFunction.data[s].default),
          value: selectedFunction.data[s].default,
          forGroup: s,
          name: null,
          error: null,
        }
      } else {
        columns[s] = {
          ...selectedFunction.data[s],
          forGroup: s,
          uuid: uuidv4(),
          title: selectedFunction.data[s].title,
          executes:
            selectedFunction.data[s].minimum === 0
              ? []
              : [
                  {
                    error: null,
                    data: selectedFunction.data[s].options.map((x) => {
                      return {
                        uuid: uuidv4(),
                        error: null,
                        name: x.name,
                        columnFunction: ['none', 'none'],
                        columnName: null,
                        columnUuid: null,
                      }
                    }),
                  },
                ],
        }
      }
    })

    return columns
  }

  // v3
  selectForColumn = (functionUuid, functionkey, executeIndex, columnUuid, column) => {
    console.log(functionUuid, functionkey, executeIndex, columnUuid, column)
    this.state.items.forEach((x) => {
      if (x.uuid === functionUuid) {
        x.request.columns[functionkey].executes[executeIndex].data.forEach((y) => {
          if (y.uuid === columnUuid) {
            y.columnName = column.name
            y.columnUuid = column.uuid
            y.columnType = column.type
            y.columnFunction = ['none', column.type]
          }
        })

        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  // v3
  selectForColumnOption = (functionUuid, functionkey, executeIndex, columnUuid, func) => {
    this.state.items.forEach((x) => {
      if (x.uuid === functionUuid) {
        x.request.columns[functionkey].executes[executeIndex].data.forEach((y) => {
          if (y.uuid === columnUuid) {
            y.columnFunction = func
          }
        })
        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  // v3
  addExecute = (activeFunction, functionUuid, functionkey) => {
    this.state.items.forEach((x) => {
      if (x.uuid === functionUuid) {
        x.request.columns[functionkey].executes.push({
          error: null,
          data: activeFunction.request.function.data[functionkey].options.map((y) => {
            return {
              uuid: uuidv4(),
              error: null,
              name: y.name,
              columnFunction: ['none', 'none'],
              columnType: null,
              columnName: null,
              columnUuid: null,
            }
          }),
        })

        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  // v3
  removeExecute = (functionUuid, functionkey, executeIndex) => {
    this.state.items.forEach((x) => {
      if (x.uuid === functionUuid) {
        if (x.request.columns[functionkey].executes.filter(Boolean).length > x.request.columns[functionkey].minimum) {
          x.request.columns[functionkey].executes = x.request.columns[functionkey].executes.filter((s, i) => {
            return i !== executeIndex
          })

          this.props.updateFunction(x.uuid, x)
        }
      }
    })
  }

  // v2
  selectForOption = (uuid, forColumn, option) => {
    this.state.items.forEach((x) => {
      if (x.uuid === uuid) {
        x.selectedColumnError = false
        x.request.columns[forColumn] = {
          ...x.request.columns[forColumn],
          forColumn,
          option,
        }
        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  // v3
  getColumns = (database, table, id = [], types = []) => {
    const columns = this.props.databases
      .find((x) => x.uuid === database)
      .tables.find((y) => y.uuid === table)
      .columns.filter((s) => {
        return !id.includes(s.uuid)
      })
      .filter((z) => {
        return types.includes(z.type)
      })

    return columns
  }

  getColumnOptions = (functionUuid, functionkey, executeIndex, columnUuid) => {
    let columns = []
    this.state.items.forEach((x) => {
      if (x.uuid === functionUuid) {
        x.request.columns[functionkey].executes[executeIndex].data.forEach((y) => {
          if (y.uuid === columnUuid) {
            columns = x.request.columns[functionkey].typeOptions[y.columnType]
          }
        })
      }
    })
    return columns
  }

  toggleDeleteActiveFunction = (uuid) => {
    this.setState({ deleteFunction: uuid !== undefined, selected: uuid })
  }

  // v3
  deleteActiveFunction = (uuid) => {
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      items: this.state.items.forEach((x) => {
        if (x.uuid === uuid) {
          this.props.deleteFunction(x.uuid)
          if (x.respond.active) {
            this.props.removeDataset(x.uuid)
          }
        }
      }),
    })
  }

  fixLngValue = (value) => {
    if (value > 180) {
      return 180
    }
    if (value < -180) {
      return -180
    }
    return value
  }

  fixLatValue = (value) => {
    if (value > 85) {
      return 85
    }
    if (value < -85) {
      return -85
    }
    return value
  }

  // TO CHECK: START: This is wrong for sure, please replicate what the regular fetch is doing
  getBbox = () => {
    const mapGetBounds = this.props.mapRef.getMap().getBounds()

    const mapBounds = [
      {
        min_lat: this.fixLatValue(parseFloat(mapGetBounds.getSouthWest().lat.toFixed(6))),
        max_lat: this.fixLatValue(parseFloat(mapGetBounds.getNorthEast().lat.toFixed(6))),
        min_lon: this.fixLngValue(parseFloat(mapGetBounds.getSouthWest().lng.toFixed(6))),
        max_lon: this.fixLngValue(parseFloat(mapGetBounds.getNorthEast().lng.toFixed(6))),
      },
    ]
    return mapBounds
  }
  // TO CHECK: END

  // v3
  runActiveFunction = async (uuid) => {
    // TO CHECK: START
    const bboxs = this.getBbox(this.props.keplerInstance.mapState)
    // TO CHECK: END
    this.setState({
      // eslint-disable-next-line react/no-access-state-in-setstate
      items: this.state.items.map((x) => {
        if (x.uuid === uuid) {
          // TO CHECK: START
          x.request.bboxs = bboxs
          // END OF MESSAGE
          this.props.removeDataset(x.uuid)
          let error = false
          if (x.request.dataset === null && !x.request.withQuery) {
            error = true
            x.selectedDatasetError = true
          }

          if (x.request.function === null) {
            error = true
            x.selectedFunctionError = true
          }

          Object.keys(x.request.columns).forEach((y) => {
            if (x.request.columns[y].requird && x.request.columns[y]?.executes?.filter(Boolean).length === 0) {
              x.request.columns[y].error = true
              error = true
            } else {
              x.request.columns[y].error = false
              x.request.columns[y]?.executes?.filter(Boolean)?.forEach((s) => {
                s.data.forEach((e) => {
                  if (e && !e.columnUuid) {
                    s.error = true
                    error = true
                  } else {
                    s.error = false
                    if (
                      x.request.columns[y].withFunction &&
                      e &&
                      !x.request.columns[y].allowNone &&
                      e.columnFunction[0] === 'none'
                    ) {
                      s.error = true
                      error = true
                    } else {
                      s.error = false
                    }
                  }
                })
              })
            }
          })

          if (error === false) {
            x.respond.runing = true

            datasetFunction(x.request.function.action, x)
              .then(async (data) => {
                const dataset = {
                  ...x,
                  type: 'function',
                  save: 'dynamic',
                  raw: data,
                  info: {
                    sid: x.id || null,
                    id: x.uuid,
                    label: `${x.request.dataset?.label || 'runing_query'}-${
                      x.request.function.action
                    }-${this.stringGen()}`,
                  },
                  loading: false,
                }

                await this.props.addDataset({ [x.uuid]: dataset })
                this.drawData(x.uuid)
                x.respond.active = true
                x.respond.runing = false
                this.props.updateFunction(x.uuid, x)
              })
              .catch((respondDrror) => {
                x.respond.runing = false
                this.props.updateFunction(x.uuid, x)
                this.props.setErrorHandler(respondDrror.response)
              })

            this.props.updateFunction(x.uuid, x)
          }
        }

        return x
      }),
    })
  }

  // v3
  stringGen = (length = 6) => {
    let text = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length))
    return text
  }

  // v3
  drawData = async (uuid) => {
    const { savedDatasets } = this.props

    const dataObject = {
      datasets: [this.createDatasetObject(savedDatasets[uuid], 'CSV')],
      options: {
        centerMap: false,
        keepExistingConfig: true,
      },
    }
    this.props.sendDataToMap(dataObject)
  }

  // v3
  processData = (data, type) => {
    switch (type) {
      case 'GEOJSON':
        return processGeojson({
          type: 'FeatureCollection',
          features: data,
        })
      case 'ROW':
        return processRowObject(data)
      case 'CSV':
        return processCsvData(data)
      default:
        break
    }
    return data
  }

  // v3
  createDatasetObject = (savedDataset, dataType, multi = false) => {
    const processData = this.processData(savedDataset.raw, dataType)
    return {
      version: 'v1',
      info: savedDataset.info,
      data: multi
        ? {
            allData: processData.rows,
            fields: processData.fields,
            id: savedDataset.info.id,
            label: savedDataset.info.label,
          }
        : { id: savedDataset.info.id, label: savedDataset.info.label, ...processData },
    }
  }

  // v3
  getQueryData = (uuid, selectedDatabase, selectedTable, query) => {
    const database = this.props.databases.find((x) => x.uuid === selectedDatabase)
    const table = database?.tables?.find((y) => y.uuid === selectedTable)

    this.state.items.forEach((x) => {
      if (x.uuid === uuid) {
        x.respond = {
          ...x.respond,
          driver: database.driver,
          table: { name: table.name, uuid: table.uuid },
          database: { carto: database.carto, name: database.title, uuid: database.uuid },
        }
        x.request = {
          ...x.request,
          withQuery: true,
          columns: this.updateColumns(x.request.function),
          query: query.replace(/\n\s*\n/g, ''),
          type: 'query',
          filters: {},
          table: table.uuid,
          database: database.uuid,
        }
        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  // v3
  getFiltersData = (uuid, selectedDatabase, selectedTable, filters, filterConjunction, jsonTree) => {
    const database = this.props.databases.find((x) => x.uuid === selectedDatabase)
    const table = database?.tables?.find((y) => y.uuid === selectedTable)

    this.state.items.forEach((x) => {
      if (x.uuid === uuid) {
        x.respond = {
          ...x.respond,
          driver: database.driver,
          table: { name: table.name, uuid: table.uuid },
          database: { carto: database.carto, name: database.title, uuid: database.uuid },
        }
        x.request = {
          ...x.request,
          withQuery: true,
          filters,
          columns: this.updateColumns(x.request.function),
          jsonTree,
          filterConjunction,
          query: '',
          type: 'dynamic',
          table: table.uuid,
          database: database.uuid,
        }
        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  // v3
  handleChange = (uuid, column, value) => {
    this.state.items.forEach((x) => {
      if (x.uuid === uuid) {
        x.selectedColumnError = false

        if (x.request.columns[column].helpFunction) {
          if (x.request.columns[column].value !== value) {
            x.request.columns[column].helpText = x.request.columns[column].helpFunction(value)
          }
        }
        x.request.columns[column].value = value
        this.props.updateFunction(x.uuid, x)
      }
    })
  }

  render() {
    return (
      <div className=' h-screen w-full'>
        {this.state.deleteFunction && (
          <DeleteFunction
            action={() => {
              this.deleteActiveFunction(this.state.selected)
              this.toggleDeleteActiveFunction()
            }}
            close={() => {
              this.toggleDeleteActiveFunction()
            }}
          />
        )}
        <div className='border-t-1 border-gray-800  pt-3'>
          {this.state.items.map((activeFunction) => {
            return (
              <div key={activeFunction.uuid} className=' mb-2 w-full relative '>
                <div
                  style={{
                    borderLeft: `3px solid rgba(${activeFunction.respond.color[0]}, ${activeFunction.respond.color[1]}, ${activeFunction.respond.color[2]}, 1)`,
                  }}
                  className='bg-primary p-2 relative w-full grid  grid-flow-col grid-cols-12 '
                >
                  <DatasetsList
                    type={activeFunction.request.type}
                    withQuery={activeFunction.request.withQuery}
                    getQueryData={this.getQueryData}
                    getFiltersData={this.getFiltersData}
                    error={activeFunction.selectedDatasetError}
                    disable={activeFunction.respond.runing === true}
                    uuid={activeFunction.uuid}
                    selectedDataset={activeFunction.request.dataset}
                    selectedTable={activeFunction.respond.table}
                    selectDataset={this.selectDataset}
                    activeFunction={activeFunction}
                  />
                  {activeFunction.respond.runing ? (
                    <div className='h-full py-2 col-span-2 pl-2'>
                      <img src='/assets/images/btnloading.gif' alt='loading' className='w-4  mx-3 ' />
                    </div>
                  ) : (
                    <>
                      <div
                        onClick={() => {
                          this.toggleDeleteActiveFunction(activeFunction.uuid)
                        }}
                        className='h-full py-2 col-span-1 pl-2'
                      >
                        <Trash className='text-gray-600 cursor-pointer' height='16px' />
                      </div>
                      <div
                        onClick={() => {
                          this.runActiveFunction(activeFunction.uuid)
                        }}
                        className='h-full py-2 col-span-1 pl-2'
                      >
                        <Play className='text-primarycolor  cursor-pointer' height='16px' />
                      </div>
                    </>
                  )}
                </div>
                {activeFunction.respond?.database && (
                  <>
                    {!activeFunction.respond?.database?.carto ? (
                      <div className='py-2  bg-subPrimary px-2 w-full grid   pt-2 '>
                        <InputError>
                          The geography function is not defined on your database settings - You can't use this function
                        </InputError>
                      </div>
                    ) : (
                      <div className='py-2  bg-subPrimary px-2 w-full grid   pt-2 '>
                        <div>
                          <Text className='text-gray-600 mb-1'>Function</Text>
                        </div>
                        <FunctionsList
                          error={activeFunction.selectedFunctionError}
                          disable={activeFunction.respond.runing === true}
                          uuid={activeFunction.uuid}
                          selectedFunction={activeFunction.request.function}
                          selectFunction={this.selectFunction}
                          items={this.state.functions}
                        />
                      </div>
                    )}

                    {activeFunction?.request?.table && activeFunction.request.function && (
                      <>
                        {!activeFunction.request.function?.drivers.includes(activeFunction.respond.driver) ? (
                          <div className='text-red-700 bg-red-100 px-2 py-1'>{`Function is not compatable with ${activeFunction.respond.driver}`}</div>
                        ) : (
                          <div className='py-2  bg-subPrimary px-2 w-full grid   pt-2 relative'>
                            {activeFunction.request.function.action ? (
                              <>
                                {Object.keys(activeFunction.request.columns)
                                  .map((column) => {
                                    return { key: column, ...activeFunction.request.columns[column] }
                                  })
                                  .filter((column) => {
                                    return column.functionType === 'twoColumnWithCoord'
                                  })
                                  .map((column, columnIndex) => {
                                    return (
                                      <>
                                        <div className='  grid grid-cols-12'>
                                          <Text className='text-gray-400 capitalize col-span-11'>{column.title}</Text>

                                          <div className='col-span-1  ml-2 py-1 text-primarycolor  cursor-pointer'>
                                            <Add
                                              onClick={() => {
                                                this.addExecute(activeFunction, activeFunction.uuid, column.key)
                                              }}
                                              className='w-3'
                                            />
                                          </div>
                                        </div>
                                        {column.executes.filter(Boolean).map((execute, executeIndex) => {
                                          return (
                                            <div
                                              key={execute.uuid}
                                              className='flex w-full mb-1 justify-start items-center  '
                                            >
                                              <SelectDoubleCoordColumn
                                                executeIndex={executeIndex}
                                                columnUuid={column.uuid}
                                                functionkey={column.key}
                                                column={column}
                                                removeExecute={this.removeExecute}
                                                execute={execute}
                                                activeFunctionUuid={activeFunction.uuid}
                                                activeFunction={activeFunction}
                                                error={execute?.error}
                                                getColumns={this.getColumns}
                                                selectForColumn={this.selectForColumn}
                                              />
                                            </div>
                                          )
                                        })}
                                      </>
                                    )
                                  })}

                                {Object.keys(activeFunction.request.columns)
                                  .map((column) => {
                                    return { key: column, ...activeFunction.request.columns[column] }
                                  })
                                  .filter((column) => {
                                    return column.functionType === 'slider'
                                  })
                                  .map((column) => {
                                    return (
                                      <>
                                        <div>
                                          <div className=''>
                                            <Text className='text-gray-400 capitalize'>{column.title}</Text>
                                          </div>
                                          <div className='flex justify-start items-center gap-2'>
                                            <div className='slider flex-1'>
                                              <Slider
                                                min={column.min}
                                                max={column.max}
                                                value={column.value}
                                                onChange={(value) => {
                                                  this.handleChange(activeFunction.uuid, column.key, value)
                                                }}
                                              />
                                            </div>
                                            <Input
                                              onChange={(e) => {
                                                if (e.target.value > column.max || e.target.value < column.min) {
                                                  return false
                                                }
                                                return this.handleChange(
                                                  activeFunction.uuid,
                                                  column.key,
                                                  parseFloat(e.target.value) || column.value,
                                                )
                                              }}
                                              className='focus:outline-none w-12 h-5 bg-menu text-gray-400 text-xxs px-3 ml-auto'
                                              value={column.value}
                                            />

                                            <div className='text-white'>
                                              <Icon
                                                action={() => {
                                                  this.props.toggleModal('userHelp', { index: column.helpIndex })
                                                }}
                                                className='text-white w-4 h-4 rounded-sm'
                                              >
                                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                                <line x1='12' y1='17' x2='12' y2='17.01' />
                                                <path d='M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4' />
                                              </Icon>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='text-white'>{column.helpText}</div>
                                      </>
                                    )
                                  })}

                                {Object.keys(activeFunction.request.columns)
                                  .map((column) => {
                                    return { key: column, ...activeFunction.request.columns[column] }
                                  })
                                  .filter((column) => {
                                    return column.functionType === 'columnWithList'
                                  })
                                  .map((column, columnIndex) => {
                                    return (
                                      <>
                                        <div className='mt-2 grid grid-cols-12'>
                                          <Text className='text-gray-400 capitalize col-span-11'>{column.title}</Text>

                                          <div className='col-span-1  ml-2 py-1 text-primarycolor  cursor-pointer'>
                                            <Add
                                              onClick={() => {
                                                this.addExecute(activeFunction, activeFunction.uuid, column.key)
                                              }}
                                              className='w-3'
                                            />
                                          </div>
                                        </div>
                                        {column.executes.filter(Boolean).map((execute, executeIndex) => {
                                          return (
                                            <div key={execute.uuid} className='relative grid grid-cols-12 w-full '>
                                              <SelectSingleColumn
                                                executeIndex={executeIndex}
                                                columnUuid={column.uuid}
                                                functionkey={column.key}
                                                column={column}
                                                removeExecute={this.removeExecute}
                                                execute={execute}
                                                activeFunctionUuid={activeFunction.uuid}
                                                activeFunction={activeFunction}
                                                error={execute?.error}
                                                getColumns={this.getColumns}
                                                selectForColumn={this.selectForColumn}
                                              />

                                              <SelectSingleOption
                                                executeIndex={executeIndex}
                                                columnUuid={column.uuid}
                                                functionkey={column.key}
                                                column={column}
                                                removeExecute={this.removeExecute}
                                                execute={execute}
                                                activeFunctionUuid={activeFunction.uuid}
                                                activeFunction={activeFunction}
                                                error={execute?.error}
                                                getColumnOptions={this.getColumnOptions}
                                                selectForColumnOption={this.selectForColumnOption}
                                              />
                                            </div>
                                          )
                                        })}
                                      </>
                                    )
                                  })}
                              </>
                            ) : null}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )
          })}
        </div>
        <Button className='add-funciton-button' onClick={this.createFunction}>
          <Add height='12px' />
          <Text className='text-white'>Add function</Text>
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  // TO CHECK: START

  keplerInstance: state.keplerGl?.MapLab,
  mapRef: state.app.view.mapRef,
  // code: state.app.view.code,
  // TO CHECK: END
  datasets: Object.keys(state.app.view.datasets)
    .map((x) => {
      return state.app.view.datasets[x]
    })
    .filter((x) => {
      return x.type !== 'function'
    }),
  savedDatasets: state.app.view.datasets,
  databases: state.app.view.databases,
  toggleModal: state.app.view.toggleModal,
  functions: Object.keys(state.app.view.functions).map((x) => {
    return state.app.view.functions[x]
  }),
})

const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  addFunction: (data) => dispatch(addFunction(data)),
  deleteFunction: (uuid) => dispatch(deleteFunction(uuid)),
  updateFunction: (uuid, data) => dispatch(updateFunction(uuid, data)),
  showMessage: (data) => dispatch(showMessage(data)),
  sendDataToMap: (data) => dispatch(wrapTo('MapLab', addDataToMap(data))),
  removeDataset: (data) => dispatch(wrapTo('MapLab', removeDataset(data))),
  addDataset: (data) => {
    dispatch(addDataset(data))
    return Promise.resolve()
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FunctionData)
