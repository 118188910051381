import { Base } from 'kepler.gl/dist/components/common/icons'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class FunctionIcon extends Component {
  render() {
    return (
      <Base viewBox='0 0 256 256' {...this.props}>
        <rect fill='none' height='256' width='256' />
        <path
          d='M220,175.3V80.7a8.1,8.1,0,0,0-4.1-7l-84-47.5a7.8,7.8,0,0,0-7.8,0l-84,47.5a8.1,8.1,0,0,0-4.1,7v94.6a8.1,8.1,0,0,0,4.1,7l84,47.5a7.8,7.8,0,0,0,7.8,0l84-47.5A8.1,8.1,0,0,0,220,175.3Z'
          fill='none'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='18'
        />
      </Base>
    )
  }
}
