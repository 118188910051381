const Gallery = () => (
  <svg width='28px' height='28px' className='w-6 h-6' viewBox='0 0 1000 1000' fill='#ddd'>
    <g>
      <g transform='translate(0.000000,410.000000) scale(0.100000,-0.100000)'>
        <path d='M243.6,2843.9c-46.4-27.3-98.3-87.4-114.7-128.4c-24.6-60.1-32.8-792-27.3-2982.1l8.2-2905.7l90.1-79.2c87.4-79.2,90.1-79.2,442.4-79.2h355v286.7v286.7H847.1H696.9V-231V2295H4329h3632.1v-150.2v-150.2h300.4h300.4v314.1c0,210.3-13.7,346.8-38.2,409.6c-81.9,191.2,229.4,177.5-4200.1,177.5C462.1,2895.8,325.5,2893.1,243.6,2843.9z' />
        <path d='M1636.4,1503.1c-32.8-10.9-84.7-57.4-117.4-98.3l-57.3-73.7v-2905.7c0-2665.3,2.7-2913.8,46.4-2984.8c24.6-43.7,73.7-90.1,109.2-106.5c46.4-21.8,1256.2-30,4093.6-30h4030.8l79.2,79.2l79.2,79.2V-1591c0,2747.3-5.5,2946.6-49.2,3001.2c-24.6,32.8-73.7,73.7-106.5,90.1C9673.3,1535.8,1726.5,1541.3,1636.4,1503.1z M9326.5-1596.5v-2526.1l-3640.2,5.5l-3637.5,8.2l-8.2,2520.6l-5.5,2517.9h3645.7h3645.7V-1596.5z' />
        <path d='M3728.2-67.2c-393.2-122.9-584.4-598.1-387.8-972.2c259.4-497,953.1-497,1212.5,0c199.4,379.6,5.5,852-398.7,972.2C3990.4-18,3889.3-18,3728.2-67.2z' />
        <path d='M6355.3-482.3c-27.3-43.7-305.9-475.2-619.9-964c-311.3-488.8-581.7-901.2-598.1-917.6c-21.9-24.6-92.8-21.9-275.8,8.2c-316.8,49.2-994,32.8-1228.9-32.8c-513.4-142-903.9-499.7-1010.4-923l-19.1-73.7h3091.4c2460.5,0,3088.6,8.2,3080.4,35.5c-8.2,16.4-177.5,521.6-379.6,1116.9c-202.1,598-374.1,1086.9-382.3,1086.9c-8.2,0-128.4-158.4-267.6-355c-139.3-193.9-262.2-355-275.8-355s-245.8,319.5-518.9,710c-273.1,390.5-505.2,715.5-518.9,723.7C6418.1-414,6385.4-441.3,6355.3-482.3z' />
      </g>
    </g>
  </svg>
)

export default Gallery
