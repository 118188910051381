const newState = {
  user: {},
  publicUser: true,
}

export const userReducer = (state = newState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload }
    case 'SET_PUBLIC_USER':
      return { ...state, publicUser: action.payload }

    default:
      return state
  }
}
