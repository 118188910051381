import CreateOrganizationModal from 'components/Management/Organisation/Modals/CreateOrganizationModal'
import CreateUserModal from 'components/Management/Organisation/Modals/CreateUserModal'
import DeleteOrganisationModal from 'components/Management/Organisation/Modals/DeleteOrganisationModal'
import DeleteUserModal from 'components/Management/Organisation/Modals/DeleteUserModal'
import UpdateOrganisationModal from 'components/Management/Organisation/Modals/UpdateOrganisationModal'
import UpdateUserModal from 'components/Management/Organisation/Modals/UpdateUserModal'
import OrganisationItem from 'components/Management/Organisation/OrganisationItem'
import Button from 'components/Portions/Button'
import Loading from 'components/Portions/Loading'
import Pagination from 'components/Portions/Pagination'
import ModalContainer from 'components/Utilities/ModalContainer'
import { Component } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import {
  organizationCreate,
  organizationDelete,
  organizationRecord,
  organizationRecords,
  organizationUpdate,
} from 'services/organisation'
import { userCreate, userDelete, userRecord, userStatus, userUpdate } from 'services/users'
import { setErrorHandler, showMessage } from 'store/actions/errorsActions'
import passwordValidation from 'utils/passwordValidation'

// eslint-disable-next-line react/prefer-stateless-function
class ManageOrganisations extends Component {
  constructor() {
    super()
    this.state = {
      items: [],
      item: {},
      selected: null,
      deleteOrganisationModal: false,
      deleteUserModal: false,
      updateOrganisationModal: false,
      updateUserModal: false,
      createOrganizationModal: false,
      createUserModal: false,
      modalLoading: false,
      page: 1,
      pages: 1,
      total: 0,
      count: 30,
      disableSaveButton: false,
    }
  }

  componentDidMount() {
    this.getItems()
  }

  toggleModal = (modal, selected = null) => {
    const modalState = this.state[modal]
    // need to close modal
    if (!selected) {
      this.setState({ item: {}, errors: {}, selected: null, [modal]: !modalState })
      return
    }

    // need to open modal
    if (selected.data) {
      // open model with data
      switch (selected.service) {
        case 'organisations':
          organizationRecord(selected.uuid)
            .then((respond) => {
              this.setState({
                modalLoading: false,
                item: respond.data,
                errors: {},
                selected,
                [modal]: !modalState,
              })
            })
            .catch((error) => {})
          break
        case 'users':
          userRecord(selected.uuid)
            .then((respond) => {
              this.setState({
                modalLoading: false,
                item: respond.data,
                errors: {},
                selected,
                [modal]: !modalState,
                disableSaveButton: false,
              })
            })
            .catch((error) => {})
          break
        default:
          break
      }
    } else {
      // open model without data
      this.setState({
        modalLoading: false,
        item: {},
        errors: {},
        selected,
        [modal]: !modalState,
        disableSaveButton: false,
      })
    }
  }

  getItems = (loading = true) => {
    this.setState({ loading }, () => {
      organizationRecords({ page: this.state.page, count: this.state.count })
        .then((respond) => {
          this.setState({
            loading: false,
            items: respond.data,
            total: respond.meta.total,
            pages: Math.ceil(respond.meta.total / respond.meta.count),
            count: respond.meta.count,
          })
        })
        .catch((error) => {
          this.props.setErrorHandler(error.response)
        })
    })
  }

  handelChange = (e) => {
    const { item, errors } = this.state
    item[e.target.name] = e.target.value

    if (e.target.name === 'password') {
      const { pass, ...restResponse } = passwordValidation(e.target.value)
      if (!pass) {
        errors[e.target.name] = {
          message: 'Password must contain at least:',
          ...restResponse,
        }
      } else {
        errors[e.target.name] = {
          message: ' ',
          ...restResponse,
        }
        setTimeout(() => {
          errors.password = null
          this.setState({ item, errors })
        }, 1800)
      }
      // ONLY ON UPDATE MODE WE CAN LEAVE PASSWORD EMPTY
      if (this.state.updateUserModal && !pass && e.target.value?.length === 0) {
        this.setState({ disableSaveButton: false })
      } else {
        this.setState({ disableSaveButton: !pass })
      }
    } else {
      errors[e.target.name] = null
    }
    this.setState({ item, errors })
  }

  createOrganization = () => {
    this.setState({ modalLoading: true }, () => {
      organizationCreate(this.state.item)
        .then((res) => {
          this.getItems(false)
          this.toggleModal('createOrganizationModal')
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp, modalLoading: false })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  deleteOrganization = () => {
    this.setState({ modalLoading: true }, () => {
      organizationDelete(this.state.selected.uuid)
        .then((respond) => {
          this.setState({ deleteOrganisationModal: false })
          this.getItems(false)
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: `Organization deleted successfully!`,
          })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          this.props.setErrorHandler(error.response)
        })
    })
  }

  updateOrganization = () => {
    this.setState({ modalLoading: true }, () => {
      organizationUpdate(this.state.selected.uuid, this.state.item)
        .then((res) => {
          this.getItems(false)
          this.toggleModal('updateOrganisationModal')
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp, modalLoading: false })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  createUser = () => {
    this.setState({ modalLoading: true }, () => {
      userCreate({ organization_id: this.state.selected.organization_id, ...this.state.item })
        .then((res) => {
          this.getItems(false)
          this.toggleModal('createUserModal')
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp, modalLoading: false })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  deleteUser = () => {
    this.setState({ modalLoading: true }, () => {
      userDelete(this.state.selected.uuid)
        .then((respond) => {
          this.setState({ deleteUserModal: false })
          this.getItems(false)
          this.props.showMessage({
            messageType: 'SUCCESS',
            message: `User deleted successfully!`,
          })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })

          this.props.setErrorHandler(error.response)
        })
    })
  }

  handelUserStatusChange = (e, uuid) => {
    userStatus(uuid, { status: e.target.value })
      .then((res) => {
        this.getItems(false)
      })
      .catch((error) => {
        switch (error.response.status) {
          case 422:
            {
              const temp = {}
              for (let index = 0; index < error.response.data.errors.length; index++) {
                const element = error.response.data.errors[index]

                // eslint-disable-next-line prefer-destructuring
                temp[element[0]] = element[1][0]
              }
              this.setState({ errors: temp, modalLoading: false })
            }
            break
          default:
            // this.context.state.handelError(errors)
            break
        }
      })
  }

  updateUser = () => {
    this.setState({ modalLoading: true }, () => {
      userUpdate(this.state.selected.uuid, this.state.item)
        .then((res) => {
          this.getItems(false)
          this.toggleModal('updateUserModal')
          this.setState({ modalLoading: false })
        })
        .catch((error) => {
          this.setState({ modalLoading: false })
          switch (error.response.status) {
            case 422:
              {
                const temp = {}
                for (let index = 0; index < error.response.data.errors.length; index++) {
                  const element = error.response.data.errors[index]
                  // eslint-disable-next-line prefer-destructuring
                  temp[element[0]] = element[1][0]
                }
                this.setState({ errors: temp, modalLoading: false })
              }
              break
            default:
              this.props.setErrorHandler(error.response)
              break
          }
        })
    })
  }

  changepage = (page) => {
    // save page to state
    this.setState({ page }, () => {
      this.getItems()
    })
    // save page to context
  }

  render() {
    return (
      <ModalContainer close={this.props.close} className='w-largeModal' title='Manage Organizations'>
        {this.state.deleteOrganisationModal && (
          <DeleteOrganisationModal
            action={{ color: 'red', text: 'Yes, Delete', handler: this.deleteOrganization }}
            cancel={{ text: 'No, Keep it', handler: () => this.toggleModal('deleteOrganisationModal') }}
          />
        )}
        {this.state.updateOrganisationModal && (
          <UpdateOrganisationModal
            item={this.state.item}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Yes, Update', handler: this.updateOrganization }}
            cancel={{ text: 'No, Cancel', handler: () => this.toggleModal('updateOrganisationModal') }}
          />
        )}

        {this.state.createOrganizationModal && (
          <CreateOrganizationModal
            item={this.state.item}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Create', handler: this.createOrganization }}
            cancel={{ text: 'Cancel', handler: () => this.toggleModal('createOrganizationModal') }}
          />
        )}
        {this.state.createUserModal && (
          <CreateUserModal
            item={this.state.item}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Create', handler: this.createUser }}
            cancel={{ text: 'Cancel', handler: () => this.toggleModal('createUserModal') }}
            disableSaveButton={this.state.disableSaveButton}
          />
        )}
        {this.state.updateUserModal && (
          <UpdateUserModal
            item={this.state.item}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Update', handler: this.updateUser }}
            cancel={{ text: 'Cancel', handler: () => this.toggleModal('updateUserModal') }}
            disableSaveButton={this.state.disableSaveButton}
          />
        )}
        {this.state.deleteUserModal && (
          <DeleteUserModal
            item={this.state.item}
            errors={this.state.errors}
            handelChange={this.handelChange}
            action={{ text: 'Delete', color: 'red', handler: this.deleteUser }}
            cancel={{ text: 'Cancel', handler: () => this.toggleModal('deleteUserModal') }}
          />
        )}
        {this.props.user.type === 'systemAdministrator' && (
          <div className=' flex justify-between items-center  text-sm'>
            <p className='pr-6 leading-5'>
              Here, you can view and edit your organization's details and manage your team members. Remember to save any
              changes made before leaving the page.
            </p>
            <Button
              action={() => {
                this.toggleModal('createOrganizationModal', {
                  data: false,
                  service: 'organizations',
                  uuid: null,
                })
              }}
              single
              type='button'
              className={` inline-flex items-center justify-center px-5 py-2 border border-transparent font-medium rounded-sm text-white  bg-primarycolor hover:bg-hovercolor focus:outline-none focus:border-primary  focus:shadow-outline-green  transition ease-in-out duration-150  text-xs leading-5 whitespace-nowrap`}
            >
              New organization
            </Button>
          </div>
        )}
        {/* accordiion item header */}
        <Loading value={this.state.loading}>
          <ScrollArea style={{ maxHeight: 350, height: 300, padding: '0 12px 0 0', marginTop: 24 }}>
            {this.state.items.map((item, index) => {
              return (
                <OrganisationItem
                  {...this.props}
                  handelUserStatusChange={this.handelUserStatusChange}
                  key={item.id}
                  item={item}
                  index={index}
                  items={this.state.items}
                  toggleModal={this.toggleModal}
                />
              )
            })}
          </ScrollArea>
          <Pagination
            page={this.state.page}
            total={this.state.total}
            pages={this.state.pages}
            count={this.state.count}
            changepage={this.changepage}
          />
        </Loading>
      </ModalContainer>
    )
  }
}
const mapStateToProps = (state) => ({
  user: state.app.user.user,
})
const mapDispatchToProps = (dispatch) => ({
  setErrorHandler: (data) => dispatch(setErrorHandler(data)),
  showMessage: (data) => dispatch(showMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageOrganisations)
