// eslint-disable-next-line import/no-named-as-default
import CheckBox from 'components/Portions/CheckBox'
import ToolTip from 'components/Portions/ToolTip'
import { Component } from 'react'

// eslint-disable-next-line react/prefer-stateless-function
export default class DatabaseItem extends Component {
  constructor() {
    super()
    this.state = {
      opened: false,
      checked: false,
      checkingDatabase: false,
    }
  }

  toggleHandler = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ opened: !this.state.opened })
  }

  handleChecked = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ checked: !this.state.checked })
  }

  render() {
    const { item, key } = this.props
    return (
      <>
        <div key={key} className={` bg-gray-200 primary-border-b grid grid-cols-10 py-px px-1 `}>
          <div
            className='item-title col-span-9 flex justify-start items-center gap-4 cursor-pointer'
            onClick={this.toggleHandler}
          >
            <div className={`${this.state.opened && 'transform rotate-180 '} transition duration-150`}>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <polyline points='6 15 12 9 18 15' />
              </svg>
            </div>

            <div className='item-details text-base'>
              <div className={`flex items-center justify-start gap-2 `}>
                <p className={`${item.connectivity === 'false' ? ' disconnected' : ''}`}>{item.title}</p>
                {item.connectivity === 'false' && (
                  <ToolTip position='center' enabled className='icon' title='Database server is not responsive'>
                    <svg
                      viewBox='0 0 24 24'
                      strokeWidth='2'
                      className='disconnected'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <path d='M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5' />
                      <path d='M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5' />
                      <line x1='16' y1='21' x2='16' y2='19' />
                      <line x1='19' y1='16' x2='21' y2='16' />
                      <line x1='3' y1='8' x2='5' y2='8' />
                      <line x1='8' y1='3' x2='8' y2='5' />
                    </svg>
                  </ToolTip>
                )}

                {item.private === 'true' && (
                  <ToolTip position='center' enabled className='icon' title='This database is private'>
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      strokeWidth='2'
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                      <rect x='5' y='11' width='14' height='10' rx='2' />
                      <circle cx='12' cy='16' r='1' />
                      <path d='M8 11v-4a4 4 0 0 1 8 0v4' />
                    </svg>
                  </ToolTip>
                )}
              </div>
              <p className='text-xs'>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <span className='mx-1'>{`Driver: ${item.driver}`} </span>
                {item.schema && <span className='mx-1'>{`Schema: ${item.schema}`}</span>}
                <span className='mx-1'>{`Tables: ${item.tables.length}`}</span>
                <span className='mx-1'>
                  {`last update: 
                  ${item.updated_at}`}
                </span>
              </p>
            </div>
          </div>
          <div className='item-options col-span-1 flex justify-around items-center'>
            <div
              className='icon cursor-pointer hover:text-primarycolor transition duration-150'
              onClick={() => {
                item.connectivity === 'true'
                  ? this.props.toggleModal('refreshDatabaseModal', {
                      data: false,
                      service: 'databases',
                      uuid: item.uuid,
                    })
                  : this.setState({ checkingDatabase: true }, () => {
                      this.props.checkDatabase(
                        item.uuid,
                        () => {
                          this.props.toggleModal('refreshDatabaseModal', {
                            data: false,
                            service: 'databases',
                            uuid: item.uuid,
                          })
                        },
                        () => {
                          this.setState({ checkingDatabase: false })
                        },
                      )
                    })
              }}
            >
              {this.state.checkingDatabase ? (
                <img className='w-4' alt='Loading please wait' src='/assets/images/loading.gif' />
              ) : (
                <svg
                  viewBox='0 0 24 24'
                  strokeWidth='1'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <path d='M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4' />
                  <path d='M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4' />
                </svg>
              )}
            </div>
            <div
              className='icon cursor-pointer hover:text-primarycolor transition duration-150'
              onClick={() => {
                this.props.toggleModal('deleteDatabaseModal', { data: false, service: 'databases', uuid: item.uuid })
              }}
            >
              <svg
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <line x1='4' y1='7' x2='20' y2='7' />
                <line x1='10' y1='11' x2='10' y2='17' />
                <line x1='14' y1='11' x2='14' y2='17' />
                <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
              </svg>
            </div>
            <div
              onClick={() => {
                this.props.toggleModal('updateDatabaseModal', { data: true, service: 'databases', uuid: item.uuid })
              }}
              className='icon cursor-pointer hover:text-primarycolor transition duration-150'
            >
              <svg
                viewBox='0 0 24 24'
                strokeWidth='1'
                stroke='currentColor'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
                <circle cx='12' cy='12' r='3' />
              </svg>
            </div>
          </div>
        </div>
        {this.state.opened && item.connectivity
          ? item.tables.map((table) => {
              return (
                <div className={` primary-border-b  grid grid-cols-10 py-px px-1   border-t-0  `} key={table.id}>
                  <div className='item-title col-span-9 flex justify-start items-center gap-4'>
                    <CheckBox
                      name='status'
                      onChange={(e) => {
                        this.props.handelTableStatusChange(e, table.uuid)
                      }}
                      disable={
                        table.status === 'error' ||
                        table.columns.length === 0 ||
                        table.columns.filter((x) => x.geometry === 'true').length === 0
                      }
                      checked={table.status}
                      options={['active', 'inactive']}
                      string
                    />
                    <div className='item-details text-sm' style={{ width: 'calc(100% - 20px)' }}>
                      <div className='flex items-center justify-start gap-2 '>
                        <ToolTip
                          big
                          position='bottom'
                          enabled={table.columns.length !== 0}
                          title={table.columns
                            .filter((x) => x.select === 'true')
                            .map((x) => x.name)
                            .join(' ,')}
                        >
                          <p
                            className={`${
                              table.columns.filter((x) => x.geometry === 'true').length === 0 ||
                              table.status === 'error'
                                ? 'disconnected'
                                : ''
                            } ellipsis`}
                          >
                            {`${table.title} ${
                              // eslint-disable-next-line no-nested-ternary
                              table.columns.length === 0
                                ? `[ No columns ]`
                                : table.columns.filter((x) => x.select === 'true').length === table.columns.length
                                ? `[ All columns ]`
                                : `[ ${table.columns
                                    .filter((x) => x.select === 'true')
                                    .map((x) => x.name)
                                    .join(' ,')} ]`
                            }`}
                          </p>
                        </ToolTip>
                        {table.status !== 'error' && table.columns.filter((x) => x.geometry === 'true').length === 0 && (
                          <div className='w-8'>
                            <ToolTip position='center' enabled className='icon ' title='No geometry columns'>
                              <svg
                                viewBox='0 0 24 24'
                                strokeWidth='2'
                                className='disconnected'
                                stroke='currentColor'
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M3.6 9h5.4m4 0h7.4' />
                                <path d='M5.65 5.623a9 9 0 1 0 12.71 12.745m1.684 -2.328a9 9 0 0 0 -12.094 -12.08' />
                                <path d='M3.6 15h11.4m4 0h1.4' />
                                <path d='M8.335 8.388a18.998 18.998 0 0 0 -.335 3.612c0 4.97 1.79 9 4 9c1.622 0 3.018 -2.172 3.646 -5.294m.354 -3.706c0 -4.97 -1.79 -9 -4 -9c-1.035 0 -1.979 .885 -2.689 2.337' />
                                <path d='M3 3l18 18' />
                              </svg>
                            </ToolTip>
                          </div>
                        )}

                        {table.status === 'error' && (
                          <div className='w-8'>
                            <ToolTip position='center' enabled className='icon ' title={table.description}>
                              <svg
                                viewBox='0 0 24 24'
                                strokeWidth='2'
                                className='disconnected'
                                stroke='currentColor'
                                fill='none'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M3.6 9h5.4m4 0h7.4' />
                                <path d='M5.65 5.623a9 9 0 1 0 12.71 12.745m1.684 -2.328a9 9 0 0 0 -12.094 -12.08' />
                                <path d='M3.6 15h11.4m4 0h1.4' />
                                <path d='M8.335 8.388a18.998 18.998 0 0 0 -.335 3.612c0 4.97 1.79 9 4 9c1.622 0 3.018 -2.172 3.646 -5.294m.354 -3.706c0 -4.97 -1.79 -9 -4 -9c-1.035 0 -1.979 .885 -2.689 2.337' />
                                <path d='M3 3l18 18' />
                              </svg>
                            </ToolTip>
                          </div>
                        )}
                      </div>

                      <p>
                        <span className='text-xs'>{`last update ${table.updated_at}`}</span>
                      </p>
                    </div>
                  </div>
                  <div className='item-options col-span-1 flex justify-around items-center'>
                    {table.status !== 'error' ? (
                      <>
                        <div className='icon cursor-pointer hover:text-primarycolor transition duration-150'>
                          <svg
                            onClick={() => {
                              item.connectivity === 'true'
                                ? this.props.toggleModal('refreshTableModal', {
                                    data: false,
                                    service: 'tables',
                                    uuid: table.uuid,
                                  })
                                : this.props.checkDatabase(item.uuid, () => {
                                    this.props.toggleModal('refreshTableModal', {
                                      data: false,
                                      service: 'tables',
                                      uuid: table.uuid,
                                    })
                                  })
                            }}
                            viewBox='0 0 24 24'
                            strokeWidth='1'
                            stroke='currentColor'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4' />
                            <path d='M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4' />
                          </svg>
                        </div>

                        <div
                          onClick={() => {
                            this.props.toggleModal('updateTableModal', {
                              data: true,
                              service: 'tables',
                              uuid: table.uuid,
                            })
                          }}
                          className='icon cursor-pointer hover:text-primarycolor transition duration-150'
                        >
                          <svg
                            viewBox='0 0 24 24'
                            strokeWidth='1'
                            stroke='currentColor'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z' />
                            <circle cx='12' cy='12' r='3' />
                          </svg>
                        </div>
                        <div className='icon' />
                      </>
                    ) : (
                      <div>
                        <div className='icon cursor-pointer hover:text-primarycolor transition duration-150'>
                          <svg
                            onClick={() => {
                              this.props.toggleModal('deleteTableModal', {
                                data: false,
                                service: 'tables',
                                uuid: table.uuid,
                              })
                            }}
                            viewBox='0 0 24 24'
                            strokeWidth='1'
                            stroke='currentColor'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <line x1='4' y1='7' x2='20' y2='7' />
                            <line x1='10' y1='11' x2='10' y2='17' />
                            <line x1='14' y1='11' x2='14' y2='17' />
                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                          </svg>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })
          : null}
      </>
    )
  }
}
