import FormGroup from 'components/Portions/FormGroup'
import Label from 'components/Portions/Label'
import Text from 'components/Portions/Text'
import Modal from 'components/Utilities/Modal'
import ModalContainer from 'components/Utilities/ModalContainer'
import { SETTINGS } from 'constants/default-settings'
import { Component } from 'react'
import { connect } from 'react-redux'
import { showMessage } from 'store/actions/errorsActions'

// eslint-disable-next-line react/prefer-stateless-function
class ShareUrlModal extends Component {
  constructor() {
    super()
    this.state = {
      loading: false,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.loading) {
      return { loading: props.loading }
    }
    return null
  }

  copyText = (text) => {
    this.props.showMessage({
      messageType: 'SUCCESS',
      message: 'url copied successfully',
    })
    const textArea = document.createElement('textarea')
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.position = 'fixed'

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    document.execCommand('copy')

    document.body.removeChild(textArea)
  }

  render() {
    return (
      <ModalContainer className='w-smallModal px-6 py-3' title='Share Map'>
        <Modal height={340} loading={this.state.loading} {...this.props}>
          <div className='flex flex-col items-center p-4'>
            <img src='/assets/images/share.png' alt='share URL' className='w-18 ' />
            <Text className='my-3  pr-4 text-base'>Copy the Map's URL</Text>

            <FormGroup className='flex w-full  '>
              <Label className='  text-xs flex justify-between w-full'>
                <Text className='my-1'>Share URL</Text>
                <svg
                  onClick={() => {
                    this.copyText(
                      `${SETTINGS.APP_URL}${this.props.public === 'true' ? 'public-map' : 'map'}/${this.props.code}`,
                    )
                  }}
                  className='icon icon-tabler icon-tabler-copy cursor-pointer'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  strokeWidth='1'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <rect x='8' y='8' width='12' height='12' rx='2' />
                  <path d='M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2' />
                </svg>
              </Label>
              <Text className=' px-1 text-xs h-6 w-full  border-1 border-gray-400 rounded-sm'>
                {`${SETTINGS.APP_URL}${this.props.public === 'true' ? 'public-map' : 'map'}/${this.props.code}`}
              </Text>
            </FormGroup>

            <FormGroup className='flex w-full '>
              <Label className='  text-xs flex justify-between w-full'>
                <Text className='my-1'>HTML Embed Code </Text>
                <svg
                  onClick={() => {
                    this.copyText(`<div style='overflow: hidden; padding-top: 56.25%; position: relative; height: 0;'>
                    <iframe
                      src='${SETTINGS.APP_URL}${this.props.public === 'true' ? 'public-map' : 'map'}/${this.props.code}'
                      style='width: 100%; left: 0; position: absolute; top: 0; border:none;'
                      scrolling='no'
                      frameBorder='1'
                      marginHeight='0px' 
                      marginWidth='0px'
                      height='400px'
                      width='600px'
                      allowFullScreen
                    />`)
                  }}
                  className='icon icon-tabler icon-tabler-copy cursor-pointer'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  strokeWidth='1'
                  stroke='currentColor'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                  <rect x='8' y='8' width='12' height='12' rx='2' />
                  <path d='M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2' />
                </svg>
              </Label>

              <Text className='  w-full px-1 text-xs   border-1 border-gray-400 rounded-sm'>
                {`<div style='overflow: hidden; padding-top: 56.25%; position: relative; height: 0;'>
                  <iframe
                    src='${SETTINGS.APP_URL}${this.props.public === 'true' ? 'public-map' : 'map'}/${this.props.code}'
                    style='width: 100%; left: 0; position: absolute; top: 0; border:none;'
                    scrolling='no'
                    frameBorder='1'
                    marginHeight='0px'
                    marginWidth='0px'
                    height='400px'
                    width='600px'
                    allowFullScreen
                  />`}
              </Text>
            </FormGroup>
          </div>
        </Modal>
      </ModalContainer>
    )
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
  showMessage: (data) => dispatch(showMessage(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShareUrlModal)
