import styled from 'styled-components'
import { mainDarkestBlue } from 'theme/colors'

const SectionHeaderH3 = styled.h3`
  font-size: 32px;
  font-weight: 500;
  color: ${mainDarkestBlue};
`

const SectionHeader = ({ children }) => <SectionHeaderH3>{children}</SectionHeaderH3>

export default SectionHeader
