/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prefer-stateless-function */
import { Minus } from 'kepler.gl/dist/components/common/icons'
import React, { Component } from 'react'
import ScrollArea from 'react-scrollbar'

class SelectSingleOption extends Component {
  constructor(props) {
    super(props)
    this.listRef = React.createRef()
    this.state = {
      column: '',
      forColumn: null,
      query: '',

      opened: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.activeFunction) !== JSON.stringify(state.activeFunction)) {
      return { activeFunction: props.activeFunction }
    }
    return null
  }

  handleSearchChange = (e) => {
    this.setState({ query: e.target.value })
  }

  // eslint-disable-next-line consistent-return
  toggleMenu = (columnUuid) => {
    this.setState({ opened: !this.state.opened, columnUuid })
  }

  hexToRGB = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${alpha})`
  }

  handleClickOutside = (e) => {
    if (this.listRef && !this.listRef.current?.contains(e.target)) {
      this.setState({ opened: false })
    }
  }

  render() {
    return (
      <>
        <div ref={this.listRef} className='relative col-span-3'>
          <div
            className={`${
              this.props.error ? 'bg-red-200' : 'bg-menu'
            } w-full  h-full  p-1 hover:bg-tagHoverColor transition-all duration-300 `}
          >
            <div className='  w-full hover:text-white  text-gray-400   py-1 text-xs   bg-tagColor cursor-pointer'>
              {this.props.execute.data.map((x) => {
                return (
                  <div
                    key={x.id}
                    onClick={() => {
                      this.toggleMenu(x.uuid)
                    }}
                    className=' overflow-hidden truncate  hover:text-white  text-gray-400 px-2   text-xs   bg-tagColor cursor-pointer'
                  >
                    {`${x.columnFunction[0] === 'none' ? '---' : x.columnFunction[0] || 'No'}`}
                  </div>
                )
              })}
            </div>
            {this.state.opened && (
              <div className=' absolute -ml-2 z-10 bg-primary  w-24 '>
                <div className=' w-full text-gray-400 my-2 text-xxs'>
                  <ScrollArea style={{ maxHeight: 200 }} smoothScrolling>
                    {this.props
                      .getColumnOptions(
                        this.props.activeFunctionUuid,
                        this.props.functionkey,
                        this.props.executeIndex,
                        this.state.columnUuid,
                      )
                      ?.map((column) => {
                        return (
                          <div
                            key={column && column[0]}
                            onClick={() => {
                              this.toggleMenu(null)
                              this.props.selectForColumnOption(
                                this.props.activeFunctionUuid,
                                this.props.functionkey,
                                this.props.executeIndex,
                                this.state.columnUuid,
                                column,
                              )
                            }}
                            className='px-2  hover:bg-droplist cursor-pointer flex justify-start items-center gap-2'
                          >
                            <p>{column && column[0]}</p>
                          </div>
                        )
                      })}
                  </ScrollArea>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-span-1  ml-2 pt-3 text-primarycolor  cursor-pointer'>
          <Minus
            onClick={() => {
              this.props.removeExecute(this.props.activeFunctionUuid, this.props.functionkey, this.props.executeIndex)
            }}
            className='w-3'
          />
        </div>
      </>
    )
  }
}

export default SelectSingleOption
