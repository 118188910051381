/* eslint-disable linebreak-style */
import mainService from 'services/mainService'

export function sessionRecords(data) {
  let parameters = ''

  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    const value = data[key]
    switch (key) {
      case 'limit':
        parameters = `${parameters}&limit=${value}`
        break
      case 'order':
        parameters = `${parameters}&order=${value.type}.${value.order}`
        break
      case 'page':
        parameters = `${parameters}&page=${value}`
        break
      case 'count':
        parameters = `${parameters}&limit=${value}`
        break
      case 'user':
        parameters = `${parameters}&user=${value}`
        break
      case 'filter':
        if (value.length >= 1) {
          parameters = `${parameters}&filter=${value}`
        }
        break
      case 'search':
        if (value.length >= 1) {
          parameters = `${parameters}&search=${value}`
        }
        break
      default:
        break
    }
  }
  return mainService
    .get(`sessions/records?rand=${new Date().getTime()}${parameters}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function sessionRetrive(code) {
  return mainService
    .get(`sessions/${code}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function sessionList() {
  return mainService
    .get(`sessions/list`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function sessionSave(data) {
  return mainService
    .put(`sessions/save`, data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function sessionDelete(uuid) {
  return mainService
    .delete(`sessions/${uuid}/delete`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
export function sessionImage(uuid) {
  return mainService
    .get(`sessions/${uuid}/image`)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
