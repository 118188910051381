import authService from 'services/authService'

export function login(data) {
  return authService
    .post('/login', data)
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export function logout() {
  return authService
    .post('/logout', {})
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
