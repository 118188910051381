export default {
  property: {
    weight: 'pondération',
    label: 'label',
    fillColor: 'couleur de remplissage',
    color: 'couleur',
    coverage: 'couverture',
    strokeColor: 'couleur du contour',
    radius: 'rayon',
    outline: 'contour',
    stroke: 'contour',
    density: 'densité',
    height: 'hauteur',
    sum: 'somme',
    pointCount: 'Nombre de points',
  },
  placeholder: {
    search: 'Rechercher',
    selectField: 'Sélectionner un champ',
    yAxis: 'Axe Y',
    selectType: 'Sélectionner un type',
    selectValue: 'Sélectionner une valeur',
    enterValue: 'Entrer une valeur',
    empty: 'vide',
  },
  misc: {
    by: '',
    valuesIn: 'Valeurs dans',
    valueEquals: 'Valeurs égales à',
    dataSource: 'Source de données',
    brushRadius: 'Rayon du cercle(km)',
    empty: ' ',
  },
  mapLayers: {
    title: 'Couches cartographiques',
    label: 'Label',
    road: 'Route',
    border: 'Limites',
    building: 'Bâtiments',
    water: 'Eau',
    land: 'Terre',
    '3dBuilding': 'Bâtiment 3D',
  },
  panel: {
    text: {
      label: 'label',
      labelWithId: 'Label {labelId}',
      fontSize: 'Taille de police',
      fontColor: 'couleur de police',
      textAnchor: 'Texte',
      alignment: 'Alignement',
      addMoreLabel: 'Ajouter un autre label',
    },
  },
  sidebar: {
    panels: {
      layer: 'Couches',
      filter: 'Filtres',
      interaction: 'Interactions',
      basemap: 'Fond de carte',
    },
  },
  layer: {
    required: 'Obligatoire*',
    radius: 'Rayon',
    color: 'Couleur',
    fillColor: 'Couleur de remplissage',
    outline: 'Contour',
    weight: 'Pondération',
    propertyBasedOn: '{propriété} basée sur',
    coverage: 'Couverture',
    stroke: 'contour',
    strokeWidth: 'Épaisseur du contour',
    strokeColor: 'Couleur du contour',
    basic: 'Basique',
    trailLength: 'Longueur du trajet',
    trailLengthDescription: 'Durée en secondes pour disparition totale du trajet',
    newLayer: 'Nouvelle couche',
    elevationByDescription: 'Si désactivé, la hauteur est basée sur le nombre de points',
    colorByDescription: 'Si désactivé, la couleur est basée sur le nombre de points',
    aggregateBy: 'Agréger {champ} par',
    '3DModel': 'Modèle 3D',
    '3DModelOptions': 'Options du modèle 3D',
    type: {
      point: 'point',
      arc: 'arc',
      line: 'ligne',
      grid: 'grille',
      hexbin: 'hexbin',
      polygon: 'polygone',
      geojson: 'geojson',
      cluster: 'cluster',
      icon: 'icône',
      heatmap: 'heatmap',
      hexagon: 'hexagone',
      hexagonid: 'H3',
      trip: 'voyage',
      s2: 'S2',
      '3d': '3D',
    },
  },
  layerVisConfigs: {
    angle: 'Angle',
    strokeWidth: 'Épaisseur du contour (Pixels)',
    strokeWidthRange: 'Intervalle d’épaisseur',
    radius: 'Rayon',
    fixedRadius: 'Rayon fixé en mètre',
    fixedRadiusDescription: 'Rayon de la carte en rayon absolu en mètre, exemple 5 à 5 mètres',
    radiusRange: 'Intervalle du rayon ',
    clusterRadius: ' Rayon du cluster en pixels',
    radiusRangePixels: 'Intervalle du rayon en pixels',
    opacity: 'Opacité',
    coverage: 'Couverture',
    outline: 'Bordure/Contour',
    colorRange: 'Gamme de couleurs',
    stroke: 'contour',
    strokeColor: 'Couleur du contour',
    strokeColorRange: 'Gamme de couleurs du contour',
    targetColor: 'Couleur de la cible',
    colorAggregation: 'Couleur d’agrégation',
    heightAggregation: 'Hauteur d’agrégation',
    resolutionRange: 'Plage de résolution',
    sizeScale: ' Échelle de calibre',
    worldUnitSize: 'Unité de taille mondiale',
    elevationScale: 'Échelle d’élévation',
    heightScale: ' Échelle de hauteur',
    coverageRange: 'Intervalle de couverture',
    highPrecisionRendering: 'Interprétation à précision élevée',
    highPrecisionRenderingDescription:
      ' La précision élevée peut entraîner une diminution des performances du système ',
    height: 'Hauteur',
    heightDescription: 'Cliquer sur le bouton en haut à droite de la carte pour changer de vue en 3d',
    fill: 'Remplir',
    enablePolygonHeight: 'Activer la hauteur du polygone',
    showWireframe: 'Afficher en mode Fil de fer',
    weightIntensity: 'Intensité de pondération',
    zoomScale: 'Échelle de zoom ',
    heightRange: 'Intervalle de hauteur',
  },
  'layerManager.addData': 'Ajouter des données',
  'layerManager.addLayer': 'Ajouter une couche',
  'layerManager.layerBlending': 'Fusion de couches',
  // layerManager: {
  //   addData: 'Ajouter des données',
  //   addLayer: 'Ajouter une couche',
  //   layerBlending: 'Fusion de couches'
  // },
  mapManager: {
    mapStyle: 'Style de carte',
    addMapStyle: 'Ajouter un style de carte',
    '3dBuildingColor': 'Couleur de bâtiment en 3D',
  },
  layerConfiguration: {
    defaultDescription: 'Calculer {propriété} basé sur le champ selectionné ',
    howTo: 'Comment',
  },
  filterManager: {
    addFilter: 'Ajouter un filtre',
  },
  datasetTitle: {
    showDataTable: 'Afficher le tableau des données',
    removeDataset: 'Supprimer l’ensemble des données',
  },
  datasetInfo: {
    rowCount: '{rowCount} enregistrements',
  },
  tooltip: {
    hideLayer: 'masquer la couche',
    showLayer: 'afficher la couche',
    hideFeature: 'Masquer la fonction',
    showFeature: 'Afficher la fonction',
    hide: 'masquer',
    show: 'afficher',
    removeLayer: 'Supprimer la couche',
    duplicateLayer: 'Dupliquer la couche',
    layerSettings: 'Paramètres des couches',
    closePanel: 'Fermer le panneau courant',
    switchToDualView: 'Basculer en mode d’affichage double',
    showLegend: 'afficher la légende',
    disable3DMap: 'Désactiver la carte en 3D',
    DrawOnMap: 'Dessiner sur la carte',
    selectLocale: 'Langue',
    hideLayerPanel: 'Masquer le panneau des couches',
    showLayerPanel: 'Afficher le panneau des couches',
    moveToTop: 'Aller en tête des couches de données',
    selectBaseMapStyle: ' Sélectionner le style de la carte de base',
    delete: 'Supprimer',
    timePlayback: 'Lecture en temps',
    cloudStorage: 'Stockage Cloud',
    '3DMap': 'Carte 3D',
    animationByWindow: 'Fenêtre de temps mobile',
    animationByIncremental: 'Fenêtre de temps incrémentale',
    speed: 'vitesse',
    play: 'Lecture',
    pause: 'pause',
    reset: 'remettre à zéro',
  },
  toolbar: {
    exportImage: 'Exporter l’image',
    exportData: 'Exporter les données',
    exportMap: 'Exporter la carte',
    shareMapURL: 'Partager l’URL de la carte',
    saveMap: 'Sauvegarder la carte',
    select: 'Sélectionner',
    polygon: 'Polygone',
    rectangle: 'Rectangle',
    hide: 'Masquer',
    show: 'Afficher',
    en: 'English',
    fi: 'Suomi',
    pt: 'Português',
    sv: 'Svenska',
    fr: 'Française',
  },
  modal: {
    title: {
      deleteDataset: 'Supprimer l’ensemble des données',
      addDataToMap: 'Ajouter des données à la carte',
      exportImage: 'Exporter l’image',
      exportData: 'Exporter les données',
      exportMap: 'Exporter la carte',
      addCustomMapboxStyle: 'Ajouter un style de mapbox personnalisé',
      saveMap: 'Sauvegarder la carte',
      shareURL: 'Partager l’URL',
    },
    button: {
      delete: 'Supprimer',
      download: 'Télécharger',
      export: 'Exporter',
      addStyle: 'Ajouter un style',
      save: 'Sauvegarder',
      defaultCancel: 'Annuler',
      defaultConfirm: 'Confirmer',
    },
    exportImage: {
      ratioTitle: 'ratio',
      ratioDescription: 'Choisir le ratio pour plusieurs utilisations.',
      ratioOriginalScreen: 'écran original',
      ratioCustom: 'Personnalisé',
      ratio4_3: '4:3',
      ratio16_9: '16:9',
      resolutionTitle: 'Résolution',
      resolutionDescription: ' La haute résolution est toujours mieux pour les impressions',
      mapLegendTitle: 'Légende de la carte',
      mapLegendAdd: 'Ajouter une légende sur la carte',
    },
    exportData: {
      datasetTitle: 'Ensemble de données',
      datasetSubtitle: 'Choisir l’ensemble de données que vous voulez exporter',
      allDatasets: 'Tout',
      dataTypeTitle: 'Type de données',
      dataTypeSubtitle: 'Choisir le type de données que vous voulez exporter',
      filterDataTitle: 'Filtrer les données',
      filterDataSubtitle: 'Vous pouvez choisir d’exporter des données originales ou filtrées',
      filteredData: ' Données filtrées. ',
      unfilteredData: ' Données non filtrées ',
      fileCount: '{fileCount} Fichier',
      rowCount: '{rowCount} enregistrements',
    },
    deleteData: {
      warning: 'Vous allez supprimer cet ensemble de données. Ça pourrait affecter {length} les couches ',
    },
    addStyle: {
      publishTitle: '1. Publier votre style sur mapbox ou bien fournir un jeton d’accès',
      publishSubtitle1: 'Vous pouvez créeer votre propre style de carte',
      publishSubtitle2: 'et',
      publishSubtitle3: 'le',
      publishSubtitle4: 'publier.',
      publishSubtitle5: 'Pour utiliser votre style privé, coller votre',
      publishSubtitle6: 'jeton d’accès',
      publishSubtitle7: 'ici. *MapLab.world est une application client, les données restent sur votre navigateur..',
      exampleToken: 'exp. pk.abcdefg.xxxxxx',
      pasteTitle: '2. Collez le style d’URL',
      pasteSubtitle1: 'Qu’est-ce qu’un',
      pasteSubtitle2: 'style d’URL',
      namingTitle: '3. Nommez votre style',
    },
    shareMap: {
      shareUriTitle: 'Partagez l’URL de la carte',
      shareUriSubtitle: ' Créer une URL de la carte pour le partage',
      cloudTitle: ' Stockage Cloud',
      cloudSubtitle: ' Connectez-vous et chargez vos données de carte dans votre stockage cloud personnel',
      shareDisclaimer:
        'MapLab.world enregistrera vos données de carte sur votre stockage cloud personnel, seules les personnes qui disposent de l’URL pourront accèder à votre carte et vos données. Vous pouvez modifier/supprimer les fichiers de données sur votre compte de stockage cloud à tout moment.',
      gotoPage: 'Allez sur votre page {currentProvider} MapLab.world ',
    },
    statusPanel: {
      mapUploading: 'Chargement de la carte',
      error: 'Erreur',
    },
    saveMap: {
      title: 'Stockage Cloud',
      subtitle: 'Connectez-vous pour sauvegarder vos données de carte sur votre stockage cloud personnel ',
    },
    exportMap: {
      formatTitle: 'Format de la carte',
      formatSubtitle: ' Choisissez le format qui vous convient le mieux pour exporter votre carte',
      html: {
        selection: 'Exportez votre carte en fichier html interactif.',
        tokenTitle: ' Jeton d’accès du Mapbox',
        tokenSubtitle: 'Utilisez votre propre jeton d’accès du Mapbox dans l’html (optionnel) ',
        tokenPlaceholder: 'Collez votre jeton d’accès du Mapbox',
        tokenMisuseWarning:
          '*Si vous ne fournissez pas votre propre jeton, la carte risque de ne pas s’afficher à chaque fois qu’on replace la nôtre pour éviter toute utilisation incorrecte ',
        tokenDisclaimer: ' Vous pouvez changer le jeton du Mapbox ultérieurement en suivant ces instructions :',
        tokenUpdate: ' Comment mettre à jour un jeton existant.',
        modeTitle: 'Mode de la carte',
        modeSubtitle1: 'Selectionnez le mode d’application. Plus ',
        modeSubtitle2: 'info',
        modeDescription: 'Autorisez des utilisateur à {mode} la carte',
        read: 'lire',
        edit: 'modifier',
      },
      json: {
        configTitle: 'Configuration de la carte',
        configDisclaimer:
          'La configuration de la carte sera incluse dans le fichier Json. Si vous utilisez MapLab.world sur votre propre application, vous pouvez copier cette configuration et la passer à ',
        selection:
          'Exporter les données actuelles de la carte et les configurer dans un seul fichier Json. Vous pouvez, utlérieurement, ouvrir la même carte en chargeant ce fichier sur MapLab.world ',
        disclaimer:
          '* La configuration de la carte est couplé en ensembles de données chargées. ‘dataId’ est utilisé pour lier des couches, des filtres et des info-bulles à un ensemble de données spécifique. En passant cette configuration à addDataToMap, assurez vous que l’identifiant de l’ensemble de données correspond à dataId/s dans cette configuration. ',
      },
    },
    loadingDialog: {
      loading: 'Chargement…',
    },
    loadData: {
      upload: 'Charger des fichiers',
      storage: 'Charger depuis stockage',
    },
    tripInfo: {
      title: 'Comment activer l’animation voyage',
      description1:
        'Afin d’animer la trajectoire, les données geoJSON doivent contenir ‘LineString’ and sa fonction géométrie, et les coordonnées dans la LineString doivent avoir 4 éléments dont le format est',
      code: ' [longitude, latitude, altitude, timestamp] ',
      description2:
        'le dernier élément étant un horodatage. Les formats valides sont en unix aux formats secondes comme `1564184363` ou en millisecondes comme `1564184363000`.',
      example: 'Exemple:',
    },
    iconInfo: {
      title: 'Comment dessiner des icônes',
      description1:
        'Dans votre csv, créez une colonne, mettez le nom de l’icône dans laquelle vous voulez dessiner. Vous pouvez laisser la cellule vide si vous ne voulez pas que l’icône s’affiche pour quelques points. Quand la colonne est nommée',
      code: 'icône',
      description2: ' MapLab.world va automatiquement créer une couche icône pour vous.',
      example: 'Exemple:',
      icons: 'Icônes',
    },
    storageMapViewer: {
      lastModified: 'Modifié il y’a {lastUpdated}',
      back: 'Retour',
    },
    overwriteMap: {
      title: 'Sauvegarder la carte...',
      alreadyExists: 'existe déjà sur votre {mapSaved}. Souhaitez-vous la remplacer ?',
    },
    loadStorageMap: {
      back: 'Retour',
      goToPage: 'Allez sur votre page MapLab.world {displayName}',
      storageMaps: 'Stockage / Cartes',
      noSavedMaps: 'Pas de cartes enregistrées',
    },
  },
  header: {
    visibleLayers: 'Couches visibles',
    layerLegend: 'Légende de la couche',
  },
  interactions: {
    tooltip: 'Info-bulle',
    brush: 'Brosse',
    coordinate: 'Coordonnées',
    geocoder: ' Géocodeur',
  },
  layerBlending: {
    title: 'Fusion des couches',
    additive: 'additive',
    normal: 'normal',
    subtractive: 'soustractive',
  },
  columns: {
    title: 'Colonnes',
    lat: 'lat',
    lng: 'lon',
    altitude: 'altitude',
    icon: 'icône',
    geojson: 'geojson',
    token: 'jeton',
    arc: {
      lat0: 'lat de la source',
      lng0: 'lng de la source',
      lat1: ' lat de la cible',
      lng1: ' lng de la cible',
    },
    grid: {
      worldUnitSize: 'Taille de grille (km)',
    },
    hexagon: {
      worldUnitSize: 'Rayon de l’hexagone (km)',
    },
    hex_id: 'hex id',
  },
  color: {
    customPalette: 'Palette personnalisée ',
    steps: 'échelons',
    type: 'type',
    reversed: 'inversée',
  },
  scale: {
    colorScale: 'Échelle de couleur',
    sizeScale: ' Échelle de calibre',
    strokeScale: 'Échelle du contour ',
    scale: ' Échelle',
  },
  fileUploader: {
    message: 'Glisser-déposer vos fichiers ici',
    chromeMessage:
      '*Utilisateur Chrome: Limiter la taille du fichier à 250mb, si besoin de charger un fichier plus grand, essayer Safari',
    disclaimer: ' ',
    configUploadMessage:
      'Charger {fileFormatNames} ou une carte enregistrée **Json**. Savoir plus sur [**les formats des fichiers supportés**]',
    browseFiles: 'naviguer vos fichiers ',
    uploading: 'Chargement',
    fileNotSupported: 'Le fichier {errorFiles} n’est pas pris en charge.',
    or: 'ou',
  },
  geocoder: {
    title: 'Entrer une adresse ou des coordonnées, exp 37.79,-122.40',
  },
  fieldSelector: {
    clearAll: 'Tout effacer',
    formatting: 'Mise en forme',
  },
  compare: {
    modeLabel: 'Mode comparaison',
    typeLabel: 'Type de comparaison',
    types: {
      absolute: 'Absolu',
      relative: 'Relatif',
    },
  },
  mapPopover: {
    primary: 'Primaire',
  },
  density: 'densité',
  'Bug Report': 'Rapport d’erreur',
  'User Guide': 'Guide d’utilisateur',
  Save: 'Sauvegarder',
  Share: 'Partager',
}
