/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */

import CustomFilterPanelFactory from 'components/CustomKepler/side-panel/filter-panel/CustomFilterPanel'
import { FilterManagerFactory } from 'kepler.gl/components'
import { Add } from 'kepler.gl/dist/components/common/icons'
import { Button, SidePanelDivider, SidePanelSection } from 'kepler.gl/dist/components/common/styled-components'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { FormattedMessage } from 'translations'

CustomFilterManagerFactory.deps = [FilterManagerFactory.deps[0], CustomFilterPanelFactory]

function CustomFilterManagerFactory(SourceDataCatalog, FilterPanel) {
  const FilterManager = ({ filters = [], datasets, layers, showDatasetTable, visStateActions }) => {
    const { addFilter, enlargeFilter, removeFilter, setFilter, toggleAnimation, toggleFilterFeature } = visStateActions
    const isAnyFilterAnimating = filters.some((f) => f.isAnimating)
    const hadEmptyFilter = filters.some((f) => !f.name)
    const hadDataset = Object.keys(datasets).length

    // render last added filter first
    const reversedIndex = useMemo(() => {
      return new Array(filters.length)
        .fill(0)
        .map((d, i) => i)
        .reverse()
    }, [filters.length])

    const onClickAddFilter = useCallback(() => {
      const defaultDataset = (Object.keys(datasets).length && Object.keys(datasets)[0]) || null
      addFilter(defaultDataset)
      setTimeout(() => {
        setFilter(filters.length, 'reverse', false)
        setFilter(filters.length, 'reverseValue', [])
      }, 0)
    }, [datasets, addFilter, filters.length])

    return (
      <div className='filter-manager'>
        <SourceDataCatalog datasets={datasets} showDatasetTable={showDatasetTable} />
        <SidePanelDivider />
        <SidePanelSection>
          {reversedIndex.map((idx) => (
            <FilterPanel
              key={`${filters[idx].id}-${idx}`}
              idx={idx}
              filters={filters}
              filter={filters[idx]}
              datasets={datasets}
              layers={layers}
              isAnyFilterAnimating={isAnyFilterAnimating}
              removeFilter={() => removeFilter(idx)}
              enlargeFilter={() => enlargeFilter(idx)}
              toggleAnimation={() => toggleAnimation(idx)}
              toggleFilterFeature={() => toggleFilterFeature(idx)}
              setFilter={setFilter}
            />
          ))}
        </SidePanelSection>
        <Button
          style={!hadDataset ? { backgroundColor: '#b9b9b9', cursor: 'default' } : {}}
          className='add-filter-button'
          inactive={hadEmptyFilter || !hadDataset}
          width='105px'
          onClick={onClickAddFilter}
        >
          <Add height='12px' />
          <FormattedMessage id='filterManager.addFilter' />
        </Button>
      </div>
    )
  }

  FilterManager.propTypes = {
    datasets: PropTypes.object,
    layers: PropTypes.arrayOf(PropTypes.any).isRequired,
    filters: PropTypes.arrayOf(PropTypes.any).isRequired,
    showDatasetTable: PropTypes.func.isRequired,
    visStateActions: PropTypes.object.isRequired,

    // fields can be undefined when dataset is not selected
    fields: PropTypes.arrayOf(PropTypes.any),
  }

  return FilterManager
}

export default CustomFilterManagerFactory

export function replaceFilterManager() {
  return [FilterManagerFactory, CustomFilterManagerFactory]
}
