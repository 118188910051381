import React, { Component } from 'react'

class Input extends Component {
  constructor() {
    super()
    this.state = { value: '' }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
      }
    }
    return null
  }

  handleChange = (e) => {
    this.setState({ value: e.target.value })
  }

  render() {
    return (
      <input
        autoComplete='new-password'
        disabled={this.props.disable}
        type={this.props.type || 'text'}
        name={this.props.name}
        min={0}
        id={this.props.id}
        value={this.state.value}
        placeholder={this.props.placeholder}
        className={
          this.props.className
            ? this.props.className
            : `w-full px-2 py-1 text-gray-700 border-1 text-xs border-gray-300 rounded-sm focus:border-focused focus:shadow-input focus:outline-none placeholder-gray-400`
        }
        onChange={this.props.onChange}
        onKeyDown={this.props.onKeyDown}
        onBlur={this.props?.onBlur}
      />
    )
  }
}

export default Input
